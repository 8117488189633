<div class="page">
  <p class="version">Version {{ version }}</p>
    
  <div class="section">
    <h4>Socials</h4>
    <div class="socials">
      <a href="https://discord.gg/TfYheYarDy" target="_blank">
        <img src="/assets/icon/discord.png" alt="Discord invitation link">
      </a>
      <a href="https://x.com/overlienet/" target="_blank">
        <img src="/assets/icon/x.png" alt="Twitter link">
      </a>
    </div>
  </div>
  <div class="section">
    <h4>Credits</h4>
    <p>This application makes use of open-source mapping technologies. We would like to acknowledge and thank the <a href="https://maplibre.org" target="_blank">MapLibre</a> and <a href="https://openstreetmap.org" target="_blank">OpenStreetMap</a> contributors.</p>
    <p>Overlie is developed and maintained by <a href="https://hoppersoft.fr">Hoppersoft</a>.</p>
  </div>
  <div class="section">
    <h4>Terms of use</h4>
    <p>By using Overlie, you agree to our <a href="https://hoppersoft.fr/overlie/terms-of-use">terms of use</a>.</p>
  </div>

  <div class="section">
    <h4>Privacy policy</h4>
    <p>Our <a href="https://hoppersoft.fr/overlie/privacy-policy">privacy policy</a> explains how we collect, use and protect your personal data.</p>
  </div>
  
  <div class="section">
    <h4>Assistance</h4>
    <p>For any question or assistance, please contact us at <a href="mailto:support@overlie.net">support&#64;overlie.net</a>.</p>
    <p>See also <a href="https://hoppersoft.fr/overlie/assistance">frequently asked questions</a>.</p>
  </div>
</div>
