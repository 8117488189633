import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MapService } from 'app/src/app/data/map.service';
import { combineLatest, Subscription, tap } from 'rxjs';
import { CommunityInfos } from 'types/build';

@Component({
  selector: 'app-community-popup',
  templateUrl: './community-popup.component.html',
  styleUrls: ['./community-popup.component.scss'],
})
export class CommunityPopupComponent implements OnInit, OnDestroy {
  private sub?: Subscription;

  communityInfo: CommunityInfos | undefined;

  constructor(private mapService: MapService,
    private router: Router
  ) { }

  ngOnInit() {
    this.sub = combineLatest([this.mapService.selectedTile, this.mapService.mapData]).pipe(tap(x => {
      let tile = x[0];
      let mapData = x[1];

      if (tile && mapData)
      {
        this.communityInfo = this.mapService.getTileCommunity(tile[0]);
      }
    })).subscribe();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  seeCommunity() {
    this.router.navigate([`/explore/community/${this.communityInfo?.id}`]);
  }
}