<div id="layout">
  <h1 id="title">Invite your friends to join your community !</h1>
  <div>Share the QR code or the link to invite people to join your community. Just click or press to copy to your clipboard.</div>
  <div class="flex-container">
    @if (qrCode) {
      <img tooltip="Click to copy QR code to clipboard" id="qrcode" [src]="qrCode" alt="" (click)="copyQRCodeToClipboard()">
    }
    @if (url) {
      <div tooltip="Click to copy url to clipboard" id="url" (click)="copyUrlTextToClipboard()">
        <ion-icon name="link-outline"></ion-icon>
        {{copiedUrl ? "Copied!": "Link"}}</div>
    }
    <app-action-button text="Close" type="tertiary" class="invitation-modal-dismiss" (click)="dismiss()"></app-action-button>
  </div>
</div>

