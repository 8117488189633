import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {

  constructor() { }

  @Input() image: string | Blob | undefined;
  @Input({ required: true }) type: 'user' | 'community' = 'user';
  
  ngOnInit() {
  }
}
