import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  transform(value: number | 'custom' | undefined, ...args: unknown[]): unknown {
    if (!value) return "Error"

    if (value == 'custom') return "Choose amount (€)";

    return (value / 100).toFixed(2) + " €"
  }

}
