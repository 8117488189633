import { Component, Input, OnInit } from '@angular/core';
import { UserService } from 'app/src/app/data/user.service';
import { Badge } from '@overlie/types';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent  implements OnInit {

  constructor(private userService: UserService) { }

  @Input({ required: true }) badgeId!: number;
  badge$?: Observable<Badge>;
  ngOnInit() { 
    this.badge$ = this.userService.getBadge(this.badgeId);
  }
}
