<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu #menu contentId="main-content" type="overlay" swipeGesture="false"
      [ngStyle]="{ display: showMenu === false ? 'none': undefined}">
      <ion-content>
        <div class="menu-layout">
          <ion-list id="tabs">
            <ion-menu-toggle auto-hide="false">
              <ion-item id="user-item" routerDirection="root" routerLink="/profile" lines="none" detail="false"
                routerLinkActive="selected">
                <app-avatar id="avatar" [type]="'user'"
                  [image]="'https://ui-avatars.com/api/?name='+((username$ | async) || '').replace('','+')"></app-avatar>
                <span>
                  <ion-list-header>{{ username$ | async }}</ion-list-header>
                  <div id="stars-count">
                    <ion-note>{{ ((stars$ | async) || 0) | starsQuantity }}</ion-note>
                    <img src="/assets/icon/star.svg" alt="Stars">
                  </div>
                </span>
              </ion-item>
            </ion-menu-toggle>
            @for (tab of menuTabs; track $index) {
            <ion-menu-toggle auto-hide="false">
              <ion-item routerDirection="root" [routerLink]="[tab.url]" lines="none" detail="false"
                routerLinkActive="selected" [disabled]="disableItems">
                <ion-icon slot="start" [ios]="tab.icon + '-outline'"></ion-icon>
                <ion-label>{{ tab.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            }
          </ion-list>
          <ion-menu-toggle auto-hide="false">
            <ion-item class="logout" lines="none" detail="false" (click)="logout()">
              <ion-icon slot="start" [ios]="'log-out-outline'"></ion-icon>
              <ion-label>Logout</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </div>
      </ion-content>
    </ion-menu>

    <ion-content [fullscreen]="fullscreen" id="main-content">
    @if (showMenu)
    {
      @if (showFab){
      <ion-fab-button color="secondary" (click)="this.menu!.toggle()">
        <ion-icon name="menu" color="primary"></ion-icon>
      </ion-fab-button>
      }
    }
    <ion-router-outlet
      [ngStyle]="{'transition': 'background 1s ease-in-out','background': backgroundColor}"></ion-router-outlet>
    </ion-content>
  </ion-split-pane>
</ion-app>