import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CommunityService } from 'app/src/app/data/community.service';
import { CommunityInfos } from '@overlie/types';
import { combineLatest, first } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'app/src/app/utils/confirmation.service';
import { UserService } from 'app/src/app/data/user.service';

type FragmentInfo = {
  value: string,
  label: string,
  access?: "member" | "moderator" | "admin"
};

@Component({
  selector: 'app-community-dashboard',
  templateUrl: './community-dashboard.component.html',
  styleUrls: ['./community-dashboard.component.scss'],
})
export class CommunityDashboardComponent implements OnInit {

  supportedFragments: Record<string, FragmentInfo> = {
    infos: { value: "infos", label: "Infos" },
    members: { value: "members", label: "Members" },
    polls: { value: "polls", label: "Polls", access: "member" },
    chat: { value: "chat", label: "Chat", access: "member" },
    moderation: { value: "moderation", label: "Moderation", access: "moderator" }
  };

  fragmentArray: FragmentInfo[] = Object.values(this.supportedFragments);
  currentPage?: keyof typeof this.supportedFragments;
  isMember: boolean = false;
  isModerator: boolean = false;
  isAdmin: boolean = false;
  isCreator: boolean = false;
  community?: CommunityInfos;
  @Input() communityId?: string;

  constructor(private communityService: CommunityService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private confirmation: ConfirmationService,
    private userService: UserService) { }

  ngOnInit() {
    if (!this.communityId) return;
    combineLatest([this.communityService.getCommunityInfo(this.communityId), this.activatedRoute.fragment, this.activatedRoute.queryParams]).pipe(first()).subscribe(([community, fragment, params]) => {
      this.community = community;
      this.isMember = this.communityService.myCommunities.value?.includes(community.id)
      this.isModerator = this.communityService.isModerator(community);
      this.isAdmin = this.communityService.isAdmin(community);
      this.isCreator = this.communityService.isCreator(community);
      this.currentPage = this.isSupportedFragment(fragment) ? fragment : "infos";
      this.fragmentArray = Object.values(this.supportedFragments).filter(f => this.isSupportedFragment(f.value));
      this.router.navigate([], { fragment: this.currentPage, queryParamsHandling: "merge", skipLocationChange: true });
      let invitation = params["invitation"];
      if (invitation) {
        this.userService.getUserProfile(invitation).pipe(first()).subscribe(async user => {
          let result = await this.confirmation.confirm('Invitation !', `Join community ${community.name}`, `You have been invited by ${user.username} to join the community ${community.name}. Do you accept the invitation ?`);
          if (result) {
            this.communityService.joinCommunity(community.id);
          }
          this.router.navigate([], { queryParams: { invitation: undefined }, fragment: this.currentPage });
        });
      }
    });
  }

  isSupportedFragment(fragment: string | null): fragment is keyof typeof this.supportedFragments {
    if (!fragment) return false;
    let fragmentInfo = this.supportedFragments[fragment as keyof typeof this.supportedFragments];
    return !!fragmentInfo
        && (this.isAdmin || (fragmentInfo.access !== "admin" || this.isAdmin)
        && (fragmentInfo.access !== "moderator" || this.isModerator)
        && (fragmentInfo.access !== "member" || this.isMember));
  }

  changePage(event: CustomEvent) {
    this.currentPage = event.detail.value;
    this.router.navigate([], { fragment: this.currentPage, queryParamsHandling: "merge" });
  }

  updateCommunity(community: CommunityInfos) {
    this.community = community;
  }
}
