<swiper-container #horizontal class="main-slider" slides-per-view="1" direction="horizontal" initial-slide="1">
  
    <swiper-slide #left>
      <app-media-content [content]="contents.left" [autoPlay]="false"/>
    </swiper-slide>
  
  <swiper-slide>
    <swiper-container #vertical class="main-slider" slides-per-view="1" direction="vertical" initial-slide="1">
      <swiper-slide #top>
        <app-media-content [content]="contents.top" [autoPlay]="false"/>
      </swiper-slide>
      <swiper-slide #middle>
        <app-media-content #content [content]="contents.middle" [autoPlay]="true"/>
      </swiper-slide>
  
      <swiper-slide #bottom>
        <app-media-content [content]="contents.bottom" [autoPlay]="false"/>
      </swiper-slide>
      
    </swiper-container>
  </swiper-slide>
    <swiper-slide #right>
      <app-media-content [content]="contents.right" [autoPlay]="false"/>
    </swiper-slide>
  
</swiper-container>