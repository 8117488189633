import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lifetime'
})
export class LifetimePipe implements PipeTransform {

  transform(value: number | undefined, args?: any): any {
    if (!value) {
      return 'Loading';
    }

    let now = Date.now()
    let postAge = now - (value as number)

    return Math.round(((10 * 60 * 60 * 1000) - postAge + (args * 36 * 1000)) / 3600000)
  }

}
