import { ApplicationRef, ComponentFactoryResolver, ComponentRef, ElementRef, Injectable, Injector, NgZone } from '@angular/core';
import { BehaviorSubject, filter, Observable, Subject, Subscription, take, tap } from 'rxjs';
import { CommunityInfos, LineStringFeatureCollection, LineStringFeature, SwipeDirection, TagLink, TileData, TileGeometry, WorldPoint, MapState } from '@overlie/types';
import { environment } from 'app/src/environments/environment';
import { CommunityService } from './community.service';
import { LngLatLike, Map as OverlieMap, Marker, Source, LngLatBounds } from 'maplibre-gl';
import { CommunityPopupComponent } from '../components/communities/community-popup/community-popup.component';
import { OverlieHttpService } from './overliehttp.service';
import { MapUtils } from '../utils/MapUtils';
import { along } from '@turf/turf';
import { InteractionsService } from './interactions.service';
import { StorageService } from './storage.service';
import { ContentThumbnailComponent } from '../components/contents/content-thumbnail/content-thumbnail.component';

export type MapData = {
  communities: Map<string, CommunityInfos>;
  borders: WorldPoint[][][];
  tiles: Map<string, TileData>;
}

@Injectable({
  providedIn: 'root'
})
export class MapService {

  private _lastVisibleTiles: Map<string, void> = new Map();
  private _mapDataSub?: Subscription = undefined;
  private _thumbnailUpdatesInterval: number = 20;
  private _thumbnailsUpdatesCounter:  number = 0;
  private _compCreationFrameBudget: number = 5;
  private _currentBudgetUsed: number = 0;
  private _thumbnailsToDraw: Map<string, void> = new Map();
  private _drawRemainingThumbnailsInterval?: NodeJS.Timeout;
  private _factory;

  constructor(
    private http: OverlieHttpService,
    private interactions: InteractionsService,
    private communityService: CommunityService, 
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
    private zone: NgZone,
    private localStorage: StorageService) { 
      this._factory = this.resolver.resolveComponentFactory(ContentThumbnailComponent);
    }

  mapData: BehaviorSubject<MapData | undefined> = new BehaviorSubject<MapData | undefined>(undefined);
  tilesWithPost: BehaviorSubject<Map<string, null>> = new BehaviorSubject<Map<string, null>>(new Map())
  forceMapSync$: Subject<void> = new Subject();
  focusedCoordinates?: BehaviorSubject<[number, number] | undefined> = new BehaviorSubject<[number, number] | undefined>(undefined);
  focusedArea?: Subject<[WorldPoint, WorldPoint] | undefined> = new Subject<[WorldPoint, WorldPoint] | undefined>();
  selectedTile: BehaviorSubject<TileGeometry | undefined> = new BehaviorSubject<TileGeometry | undefined>(undefined);
  popupRefs: ComponentRef<CommunityPopupComponent>[] = [];
  thumbnailsRefs: Map<string, ComponentRef<ContentThumbnailComponent>> = new Map();
  endFocused: Subject<boolean | undefined> = new Subject<boolean | undefined>();

  tagLinksFeatures: LineStringFeatureCollection = {
    type: "FeatureCollection",
    features: []
  }

  selectedTagLink: BehaviorSubject<TagLink | undefined> = new BehaviorSubject<TagLink | undefined>(undefined);

  public getMapData(forceResync: boolean = false): Observable<MapData> {
    return this.http.get<MapData>(`${environment.apiUrl}/infos/map`).pipe(
      tap((data: MapData) => {
        if (data) {
          this.mapData?.next({
            borders: data.borders,
            tiles: new Map(data.tiles),
            communities: new Map(data.communities)
          });

          this.retrieveTilesWithPost();

          if (forceResync)
          {
            this.forceMapSync$.next();
          }
        }
      })
    );
  }

  retrieveTilesWithPost() {
    if (this._mapDataSub == undefined) {
      this._mapDataSub = this.mapData.pipe(filter(v => v !=  undefined)).subscribe(data => {
        if (!data) return;
  
        let tiles = Array.from(data.tiles.values()).filter(t => t.postDate != -1);
        this.tilesWithPost.value.clear();
        for (let tile of tiles){
          this.tilesWithPost.value.set(MapUtils.worldPointToUniqueString(tile.position), null);
        }
  
        this.tilesWithPost.next(this.tilesWithPost.value);
      });
    }
  }

  public addTileToMap(tile: TileData) {
    if (!this.mapData || !this.mapData.value || !this.mapData.value.tiles)
      return;

    this.mapData.value.tiles.set(MapUtils.worldPointToUniqueString(tile.position), tile);
    this.mapData.next(this.mapData.value);

    if (tile.communityId == "none") {
      this.tryRemoveThumbnail(tile.position);
    }
  }

  public addTilesToMap(tiles: TileData[]) {
    if (!this.mapData || !this.mapData.value || !this.mapData.value.tiles)
      return;
    for (let tile of tiles)
    {
      this.mapData.value.tiles.set(MapUtils.worldPointToUniqueString(tile.position), tile);
    }
    this.mapData.next(this.mapData.value);
  }

  public clearCommunityData(communityId: string) {
    if (this.mapData.value){
      this.mapData.value.communities.delete(communityId);
      let keysToDelete = []
      for (let entry of this.mapData.value?.tiles.entries()){
        if (entry[1].communityId == communityId){
          keysToDelete.push(entry[0]);
        }
      }

      keysToDelete.forEach(key => this.mapData.value?.communities.delete(key));
      this.mapData.next(this.mapData.value);
    }
  }

  public goToCoordinates(coordinates: WorldPoint) {
    this.focusedCoordinates!.next(coordinates)
  }

  public seeContent(coordinates: WorldPoint = [200, 200])
  {
      if (coordinates[0] != 200)
      {
        this.selectNewTile(coordinates);
      }

      this.goToContent()
  }

  public goToContent() {
    if (!this.selectedTile) return;

    this.interactions.sendSignal<boolean>(this.interactions.viewTile, true);
  }

  public focusArea(area: [WorldPoint, WorldPoint])
  {
    this.focusedArea!.next(area)
  }

  public focusCommunity(communityId: string) {
    let md = this.mapData?.getValue();
    if (!md) return;
    this.focusedArea!.next(MapUtils.getCommunityBounds(Array.from(md.tiles.values()), communityId));
  }

  public getCoordinatesFromId(id: string): WorldPoint {
    let coords = id.split(',');
    return [parseFloat(coords[0]), parseFloat(coords[1])];
  }

  public getNearbyIds(id: string): Record<SwipeDirection, string> {
    let coords = this.getCoordinatesFromId(id);
    let top = [coords[0], coords[1] + MapUtils.TILES_OFFSET_LAT];
    let bottom = [coords[0], coords[1] - MapUtils.TILES_OFFSET_LAT];
    let right = [coords[0] + MapUtils.TILES_OFFSET_LONG, coords[1]];
    let left = [coords[0] - MapUtils.TILES_OFFSET_LONG, coords[1]];
    return {
      'top': top.join(','),
      'bottom': bottom.join(','),
      'right': right.join(','),
      'left': left.join(',')
    };
  }

  public addContentThumbnail(wp: WorldPoint, map: OverlieMap, mapContainer: HTMLElement, force: boolean = false) {
    let id = MapUtils.worldPointToUniqueString(wp);
    if (this.thumbnailsRefs.has(id)) return;
    let popupRef = this.loadContentThumbnail(id, map);
    popupRef.instance.setContentId(id);
    mapContainer.appendChild((popupRef.hostView as any).rootNodes[0]);
    popupRef.instance.contentRetrieved.subscribe( _ => {
      this.updateContentThumbnail(popupRef.instance, map);
    })
  }

  private tryRemoveThumbnail(wp: WorldPoint) {
    let id = MapUtils.worldPointToUniqueString(wp);
    let thumb = this.thumbnailsRefs.get(id);
    if (thumb) {
      thumb.destroy();
      this.thumbnailsRefs.delete(id);
    }
  }

  public updateContentThumbnails(map: OverlieMap) {
    if (map.getZoom() < 15) {
      this.clearAllThumbnails();
      this._lastVisibleTiles = new Map()
    }
    else if (this._thumbnailsUpdatesCounter > this._thumbnailUpdatesInterval)
    {
      this._thumbnailsUpdatesCounter = 0;
      this._currentBudgetUsed = 0;
      if (this._drawRemainingThumbnailsInterval == undefined) {
        this._drawRemainingThumbnailsInterval = setInterval(() => this.drawRemainingThumbnails(map), 50)
      }

      let allCoordsInView: WorldPoint[] = this.getAllPostsInView(map.getBounds())

      let tempVisibleThumbnails: Map<string, void> = new Map();
      allCoordsInView.forEach(wp => {
        let legalCoords = MapUtils.makeCoordLegal(wp);
        let id = MapUtils.worldPointToUniqueString(legalCoords);
        if (this.tilesWithPost.value.has(id)) {
          let thumbRef = this.thumbnailsRefs.get(id)
          if (thumbRef) {
            this.updateContentThumbnail(thumbRef.instance, map);
          }
          else if (this._currentBudgetUsed < this._compCreationFrameBudget) {
            this.addContentThumbnail(wp, map, map._container)
            this._thumbnailsToDraw.delete(id);
            this._currentBudgetUsed++;
          }
          else {
            this._thumbnailsToDraw.set(id);
          }
          tempVisibleThumbnails.set(id);
          this._lastVisibleTiles.delete(id);
        }
      })
      
      Array.from(this._lastVisibleTiles.keys()).forEach(key => {
        if (!tempVisibleThumbnails.has(key)) {
          let thumbRef = this.thumbnailsRefs.get(key);
          if (thumbRef) {
            thumbRef.destroy();
            this.thumbnailsRefs.delete(key)
          }
        }
      })

      this._lastVisibleTiles = tempVisibleThumbnails;
    }
    else
    {
      this._thumbnailsUpdatesCounter++;
      this.thumbnailsRefs.forEach(thumbRef => this.updateContentThumbnail(thumbRef.instance, map))
    }
  }

  private drawRemainingThumbnails(map: OverlieMap) {
    this._currentBudgetUsed = 0;
    let keyArray =  Array.from(this._thumbnailsToDraw.keys())
    for(let id of keyArray) {
      if (this._currentBudgetUsed < this._compCreationFrameBudget) {
        let wp = id.split(',').map(x => +x) as WorldPoint;
        this.addContentThumbnail(wp, map, map._container)
        this._thumbnailsToDraw.delete(id);
        this._currentBudgetUsed++;
      }
      else{
        break;
      }
    }
  }

  private getAllPostsInView(bounds: LngLatBounds): WorldPoint[] {
    let res = []
    let safeSouthWest = MapUtils.makeCoordLegal([bounds._sw.lng - (MapUtils.TILES_OFFSET_LONG * 2) , bounds._sw.lat - (MapUtils.TILES_OFFSET_LAT * 2)])
    let safeNorthEast = MapUtils.makeCoordLegal([bounds._ne.lng + (MapUtils.TILES_OFFSET_LONG * 2) , bounds._ne.lat + (MapUtils.TILES_OFFSET_LAT * 2)])
    let nbTilesLng = (safeNorthEast[0] - safeSouthWest[0]) / MapUtils.TILES_OFFSET_LONG;
    let nbTilesLat = (safeNorthEast[1] - safeSouthWest[1]) / MapUtils.TILES_OFFSET_LAT;
    for (let lngFactor = 0; lngFactor < nbTilesLng; lngFactor++) {
      for (let latFactor = 0; latFactor < nbTilesLat; latFactor++) {
        res.push([safeSouthWest[0] + (lngFactor * MapUtils.TILES_OFFSET_LONG), safeSouthWest[1] + (latFactor * MapUtils.TILES_OFFSET_LAT)])
      }
    }

    return res as WorldPoint[];
  }

  private clearAllThumbnails() {
    this.thumbnailsRefs.forEach(t => {
      t.destroy()
    })
    this.thumbnailsRefs.clear();
  }

  private updateContentThumbnail(content: ContentThumbnailComponent, map: OverlieMap) {
    if (content.id) {
      let anchorCoords = content.id.split(',').map(v => +v)
      anchorCoords[1] += (MapUtils.TILES_OFFSET_LAT * 0.85)
      let coords = map.project(anchorCoords as LngLatLike);
      content.setTranslate(coords.x, coords.y)
      content.setScale((2 ** map.getZoom()) / 135000)
      content.setRotation(map.getBearing() * -1);
    }
  }

  private loadContentThumbnail(id: string, mapRef: OverlieMap): ComponentRef<ContentThumbnailComponent> {
    let popupRef = this._factory.create(this.injector);
    popupRef.instance.mapContainer = mapRef.getCanvasContainer();

    let thumbRef = this.thumbnailsRefs.get(id);
    if (thumbRef) {
      thumbRef.destroy();
    }
    this.thumbnailsRefs.set(id, popupRef);

    this.appRef.attachView(popupRef.hostView);

    return popupRef;
  }

  public clearAllThumbnailsData() {
    this.clearAllThumbnails();
    this._currentBudgetUsed = 0;
    clearInterval(this._drawRemainingThumbnailsInterval);
    this._drawRemainingThumbnailsInterval = undefined;
    this._lastVisibleTiles.clear();
    this._thumbnailsToDraw.clear();
  }

  public selectNewTile(wp: WorldPoint) {
    this.selectedTile?.next(MapUtils.worldPointToTileGeometry(wp));
    let thumbnailRef = this.thumbnailsRefs.get(MapUtils.worldPointToUniqueString(MapUtils.makeCoordLegal(wp)))
    if (thumbnailRef) {
      thumbnailRef.instance.handleFocused();
    }
  }

  /**
 * This function checks if the selected tile is empty, i.e. if there is no tile already placed on it.
 * @returns true if the tile is empty, false otherwise
 */
  public isTileEmpty(wp: WorldPoint): boolean {
    let legalWp = MapUtils.makeCoordLegal(wp)
    if (this.mapData.value)
    {
      return !this.mapData.value?.tiles.has(MapUtils.worldPointToUniqueString(legalWp));
    }

    return false;
  }
  
  
  /**
   * This function checks if the selected tile is clickable, i.e. if a user's community owns an adjacent tile and the tile itself is not owned by a user's community.
   * @returns true if the tile is clickable, false otherwise
   */
  public isTileClickable(wp: WorldPoint): boolean {
    let legalWp = MapUtils.makeCoordLegal(wp)
    if (!this.mapData.value) return false;

    let tileCommunity = this.mapData.value?.tiles.get(MapUtils.worldPointToUniqueString(legalWp))?.communityId
    if (tileCommunity && this.communityService.myCommunities?.value.includes(tileCommunity))
    {
      return false;
    }

    let newIterable = [...this.mapData.value.tiles].filter(([_, data]) => this.communityService.myCommunities?.value.includes(data.communityId))
    let communityFeatures: Map<string, TileData> = new Map(newIterable);
    
    return communityFeatures.has(MapUtils.worldPointToUniqueString([legalWp[0] + MapUtils.TILES_OFFSET_LONG, legalWp[1]]))
        || communityFeatures.has(MapUtils.worldPointToUniqueString([legalWp[0] - MapUtils.TILES_OFFSET_LONG, legalWp[1]]))
        || communityFeatures.has(MapUtils.worldPointToUniqueString([legalWp[0], legalWp[1] + MapUtils.TILES_OFFSET_LAT]))
        || communityFeatures.has(MapUtils.worldPointToUniqueString([legalWp[0], legalWp[1] - MapUtils.TILES_OFFSET_LAT]))
  }
  
  /**
   * This function checks if the selected tile can host a post (belongs to a user's community and is free).
   */
  public isTileCanBePosted(wp: WorldPoint): boolean {
    let legalWp = MapUtils.makeCoordLegal(wp)

    if (this.mapData.value)
    {
      let selectedTileData = this.mapData.value.tiles.get(MapUtils.worldPointToUniqueString(legalWp))
      if (selectedTileData)
      {
        return this.communityService.myCommunities.value.includes(selectedTileData.communityId) 
          && (selectedTileData.postDate == -1 
            || MapUtils.calculateProtectionInMS(selectedTileData.postDate) < 0);
      }
    }

    return false;
  }

  public getTileCommunity(wp: WorldPoint): CommunityInfos | undefined
  {
    if (this.mapData.value)
    {
      let legalWp = MapUtils.makeCoordLegal(wp)

      let communityId = this.mapData.value.tiles.get(MapUtils.worldPointToUniqueString(legalWp))?.communityId
      if (communityId)
      {
        return this.mapData.value.communities.get(communityId);
      }
    }

    return undefined
  }

  private initTagLinks(mapRef: OverlieMap) {
    mapRef.addSource('tag-links', {
      'type': 'geojson',
      lineMetrics: true,
      'data': this.tagLinksFeatures
    });

    mapRef.addLayer({
      'id': 'tag-links',
      'type': 'line',
      'source': 'tag-links',
      'paint': {
        // "line-dasharray": [5, 2],
        "line-color": ['get', 'color'],
        "line-width": 2,
        // 'line-gradient': [
        //   'interpolate',
        //   ['linear'],
        //   ['line-progress'],
        //   0,
        //   '#2400FF',
        //   0.33,
        //   '#B100C1',
        //   0.66,
        //   '#C40039',
        //   1,
        //   '#C37500'
        // ]
      },
      'layout': {
      }
    });
    mapRef.moveLayer('tag-links', 'communities-names');
  }

  public async clearTagLinks(mapRef?: OverlieMap) {
    if (!mapRef) return;
    let tagLinks = mapRef.getSource('tag-links');
    if (!tagLinks) return;
    this.tagLinksFeatures.features = [];
    //@ts-ignore
    tagLinks.setData(this.tagLinksFeatures);
  }

  public async traceTagLink(point1: WorldPoint, point2: WorldPoint, mapRef: OverlieMap, color?: string) {
    let tagLinks = mapRef.getSource('tag-links');
    if (!tagLinks) {
      this.initTagLinks(mapRef);
      tagLinks = mapRef.getSource('tag-links')!;
    }
    this.tagLinksFeatures.features.push({
      type: "Feature",
      id: Math.floor(Math.random() * 10000).toString(),
      properties:
      {
        color: color || '#ffffff',
      },
      geometry: {
        type: 'LineString',
        coordinates: [
          [point1[0] + MapUtils.APPROXIMATE_CENTER_OFFSET, point1[1] + MapUtils.APPROXIMATE_CENTER_OFFSET],
          [point2[0] + MapUtils.APPROXIMATE_CENTER_OFFSET, point2[1] + MapUtils.APPROXIMATE_CENTER_OFFSET],
        ]
      }
    });
    //@ts-ignore
    tagLinks.setData(this.tagLinksFeatures);
  }

  /**
   * Speed factor for the animation. Keep it between 0 and 1.
   */
  private speedFactor: number = 0.3;
  private distance: number = 0;
  private terminatedLines: Set<string> = new Set();

  animateLines(source: Source, color?: string) {
    if (this.terminatedLines.size >= this.tagLinksFeatures.features.length) {
      this.distance = 0;
      this.terminatedLines = new Set();
    } else {
      let newFeatures: LineStringFeatureCollection = { features: [], type: "FeatureCollection" };
      for (let feat of this.tagLinksFeatures.features) {
        let point = along(feat.geometry, this.distance);
        if (point.geometry.coordinates == feat.geometry.coordinates[1]) {
          this.terminatedLines.add(feat.id);
        }
        let newFeature: LineStringFeature = {
          type: "Feature",
          id: `${point.geometry.coordinates[0]}-${point.geometry.coordinates[1]}`,
          properties:
          {
            color: color || '#ffffff',
          },
          geometry: {
            type: 'LineString',
            coordinates: [
              feat.geometry.coordinates[0],
              point.geometry.coordinates as [number, number]
            ]
          }
        }
        newFeatures.features.push(newFeature);
      }
      this.distance = this.distance + this.speedFactor;
      //@ts-ignore
      source.setData(newFeatures);
      requestAnimationFrame(() => this.animateLines(source, color));
    }
  }

  public async setMapState(coordinates: WorldPoint, zoom?: number) {
    let state: MapState = { lng: coordinates[0], lat: coordinates[1], zoom: zoom || MapUtils.MAX_SAVED_ZOOM };
    await this.localStorage.setItem('map-state', JSON.stringify(state));
  }
} 