import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Badge } from '@overlie/types';
import { BadgeMessageComponent } from '../components/utils/badge-message/badge-message.component';
import { ModalController } from '@ionic/angular';
import { CommunityInvitationModalComponent } from '../views/map-view/community-overlay/community-invitation-modal/community-invitation-modal.component';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private toast: ToastrService, private modalController: ModalController) { }

  public success(title: string, message: string) {
    this.toast.success(message, title);
  }

  public error(title: string, message: string) {
    if (!message) {
      message = "An error occured";
    }
    this.toast.error(message, title);
  }

  public warning(title: string, message: string) {
    this.toast.warning(message, title);
  }

  public announceBadge(badge: Badge)
  {
    this.toast.info(badge.message, badge.name, {
      toastComponent: BadgeMessageComponent,
      payload: { badge: badge }
    })
  }

  public showInvitationQRCode(url: string) {
    this.modalController.create({
      component: CommunityInvitationModalComponent,
      componentProps: { url: url }
    }).then(modal => modal.present());
  }
}
