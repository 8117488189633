import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CommunityInfos, RankingInfos } from '@overlie/types';
import { CommunityService } from 'app/src/app/data/community.service';
import { BehaviorSubject, map, merge, Observable } from 'rxjs';

@Component({
  selector: 'app-communities-ranking-item',
  templateUrl: './communities-ranking-item.component.html',
  styleUrls: ['./communities-ranking-item.component.scss'],
})
export class CommunitiesRankingItemComponent  implements OnInit, OnChanges {

  private triggerChange$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  @Input() rankingInfos?: RankingInfos;
  @Input() rank?: number;

  communityInfos$?: Observable<CommunityInfos | undefined>

  constructor(private communityService: CommunityService,
    private router: Router,
  ) { }

  ngOnInit()
  {
    this.communityInfos$ = merge(this.communityService.communities.pipe(map(communities => {
      if (this.rankingInfos?.communityId){
        return communities.get(this.rankingInfos?.communityId);
      }

      return undefined;
    })), this.triggerChange$.pipe(map(_ => {
      if (this.rankingInfos?.communityId){
        return this.communityService.communities.value.get(this.rankingInfos?.communityId)
      }

      return undefined;
    })))
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.triggerChange$.next(true);
  }

  itemClick()
  {
    this.router.navigate([`/explore/community/${this.rankingInfos?.communityId}`]);
  }
}
