<div id="listLayout" #listLayout>
  @if (isModerator || isAdmin) {
  <app-poll-creation [communityId]="community?.id"></app-poll-creation>
  }
  @if ((polls | async)?.length) {
  @for(poll of (polls | async); track poll.id)
  {
  <app-poll class="poll" [poll]="poll" [canModerate]="isAdmin || isModerator"></app-poll>
  }
  } @else if ((polls | async)) {
  <p class="empty-polls">No poll for the moment</p>
  }

</div>