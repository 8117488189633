import { Injectable } from '@angular/core';
import { environment } from 'app/src/environments/environment';
import { LocalStorageService } from './local-storage.service';
import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})
export class OverlieSocketService extends Socket {

  constructor(
    private localStorage: LocalStorageService,
) {
    super(environment.wsConfig);
    this.ioSocket['auth'] = { token: localStorage.getItem('uniqueToken')};
}
}
