import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonModal, Platform } from '@ionic/angular';
import { InteractionsService } from 'app/src/app/data/interactions.service';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss'],
})
export class PostsComponent implements OnInit, AfterViewInit {

  _isMobile: boolean | undefined

  @ViewChild('modal') modal?: IonModal;

  modalBreakpoints = [0, 1];

  constructor(private Platform: Platform,
              private InteractionService: InteractionsService,
              private Router: Router) { }

  ngOnInit()
  {
    this._isMobile = this.Platform.is('mobile')
  }

  ngAfterViewInit(): void {
    if (this._isMobile)
    {
      this.modal?.present()
      this.InteractionService.goButtonClicked$.subscribe(_ => this.modal?.dismiss())
      this.modal?.ionModalDidDismiss.subscribe(() =>
      {
        this.Router.navigateByUrl('/explore', {replaceUrl: true})
      })
    }
  }

  pointerMove(e: Event) {
    e.stopPropagation();
  }
}
