import { Injectable } from '@angular/core';
import { AlertController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {

  constructor(private alertController: AlertController) { }

  public async confirm(header: string, subHeader: string, message: string): Promise<boolean> {
    return new Promise<boolean>(
      (resolve, reject) => {
        this.alertController.create({
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => resolve(false)
            },
            {
              text: 'OK',
              role: 'confirm',
              handler: () => resolve(true)
            },
          ],
          header: header,
          subHeader: subHeader,
          message: message,
        }).then(alert => alert.present())
      }
    );
  }
}
