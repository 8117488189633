import { Injectable } from '@angular/core';
import { OverlieHttpService } from './overliehttp.service';
import { environment } from 'app/src/environments/environment';
import { first, Observable } from 'rxjs';
import Stripe from 'stripe';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(private http: OverlieHttpService) { }

  public getProducts(): Observable<Stripe.Product[]>
  {
    return this.http.get<Stripe.Product[]>(`${environment.apiUrl}/payment/products`)
      .pipe(first())
  }

  public getPrices(): Observable<Stripe.Price[]>
  {
    return this.http.get<Stripe.Price[]>(`${environment.apiUrl}/payment/prices`)
      .pipe(first())
  }
}
