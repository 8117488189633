<div id="community-container"
  [ngStyle]="{ 'display' : communityInfo  ? 'flex' : 'none' }"
  (click)="seeCommunity()"
  #communityInfoContainer>

    @if (communityInfo) {

        <div id="img-container">
            <div id="community-img">
                <app-avatar class="avatar" [image]="communityInfo?.image" [backgroundColor]="communityInfo?.color" [type]="'community'"></app-avatar>
            </div>
        </div>

        <div id="community-info-container">
            <div id="community-info-top-container">
                <div id="community-name-container" class="align-center text-ellipsis">
                    <p class="text-ellipsis"> {{communityInfo.name}} </p>
                </div>

                <div id="community-stats">
                    <div id="tile-number-layout" class="stat">
                        <div id="community-color" [ngStyle]="{ 'background': communityInfo.color }"></div>
                        <p id="tile-number">{{ (communityInfo.tileNumber ? communityInfo.tileNumber : 0) | stat}}</p>
                    </div>

                    <div class="stat">
                        <img class="members-icon" src="/assets/icon/community.svg">
                        <span>{{ communityInfo.nbUsers | stat}}</span>
                    </div>

                    <div id="bookmarks" class="stat">
                        <ion-icon [name]="'bookmark-outline'" size="small"></ion-icon>
                        <span>{{ communityInfo.nbBookmarks | stat}}</span>
                    </div>
                </div>
            </div>

            <div id="community-info-bottom-container">
                <div class="text-ellipsis">
                    {{communityInfo.description}}
                </div>
            </div>
        </div>
    }
</div>