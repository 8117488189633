import { Component, OnDestroy, OnInit, ViewChild, NgZone, AfterViewInit } from '@angular/core';
import { IonMenu, Platform } from '@ionic/angular';
import { ActivatedRouteSnapshot, Router, RoutesRecognized } from '@angular/router';
import { UserService } from './data/user.service';
import { Observable, Subscription, combineLatest, filter, first, map, scan, startWith, switchMap, throttleTime } from 'rxjs';
import { CommunityService } from './data/community.service';
import { InteractionsService } from './data/interactions.service';
import { Colors } from './utils/Colors';
import { PushNotificationsService } from './data/push-notifications.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Location } from '@angular/common';
import { MapService } from './data/map.service';
import { StarsAnimationService } from './utils/stars-animation.service';
import { SplashScreen } from '@capacitor/splash-screen';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {

  constructor(public platform: Platform,
    public location: Location,
    private router: Router,
    private userService: UserService,
    private mapService: MapService,
    private communityService: CommunityService,
    private interactions: InteractionsService,
    private starsAnimService: StarsAnimationService,
    private zone: NgZone
  )
  {
    App.addListener('resume', () => {
      mapService.getMapData(true).pipe(first()).subscribe();
    })

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
          const slug = event.url.split("/");
          if (slug && slug[slug.length - 1].startsWith("content")) {
            this.router.navigateByUrl(`/${slug[slug.length - 1]}`);
          }
      });
  });
  }
  
  @ViewChild('menu') menu: IonMenu | undefined;


  public userId$: Observable<string> = this.userService.userProfile.pipe(map(profile => profile.id));
  public username$: Observable<string> = this.userService.userProfile.pipe(map(profile => profile.username));
  public stars$: Observable<number> = this.userService.userProfile.pipe(
    map(profile => profile.stars),
    switchMap(stars => this.starsAnimService.addStarToProfile$.pipe(
      startWith(0),
      scan((acc, _) => acc + 1, stars - 1)
    ))
  );
  public menuTabs = [
    { title: 'Explore', url: '/explore', icon: 'map' },
    { title: 'Communities', url: '/explore/communities', icon: 'people-circle' },
    { title: 'My posts', url: '/explore/posts', icon: 'albums' },
    //{ title: 'Support us', url: '/store', icon: 'heart' },
    { title: 'Settings', url: '/settings', icon: 'cog' },
  ];

  public showFab = false;
  public fullscreen = false;
  public showMenu = true;
  public disableItems = false;

  private readonly defaultBackgroundColor = 'var(--background)';
  backgroundColor: string = this.defaultBackgroundColor;

  userProfileSubscription?: Subscription;
  routerSubscription?: Subscription;
  endFocusSubscription?: Subscription;
  focusedCoordsSubscription?: Subscription;

  ngAfterViewInit(): void {
    SplashScreen.hide();
  }

  ngOnInit() {
    this.interactions.initTimers();
    this.userService.refreshToken().pipe(first()).subscribe();
    this.userProfileSubscription = this.userService.userProfile?.subscribe((userProfile) => {
      if (userProfile.communities && userProfile.bookmarks) this.communityService.setUserCommunities(userProfile.communities, userProfile.bookmarks);
    });
    this.routerSubscription = this.router.events.pipe(filter(event => event instanceof RoutesRecognized)).subscribe((data) => {
      let routeData = this.getDataForSelectedRoute(data as RoutesRecognized)
      this.showMenu = true;
      let showMenuValue = routeData?.showMenu;
      if (routeData){
        this.fullscreen = routeData.fullscreen;
        this.showMenu = showMenuValue !== false;
      } else {
        this.fullscreen = false;
      }
      this.showFab = this.showMenu !== false && this.fullscreen && !this.platform.is('desktop');
    });

    this.interactions.contentBackgroundColor$.subscribe((color) => {
      let rgb: {red: number, green: number, blue: number} = Colors.hexToRGB(color);
      this.backgroundColor = `linear-gradient(180deg, rgba(${rgb.red},${rgb.green},${rgb.blue},0.6) 0%, rgba(${rgb.red},${rgb.green},${rgb.blue},0.2) 100%)`;
    });

    this.focusedCoordsSubscription = this.mapService.focusedCoordinates?.pipe(filter(coords => coords != undefined)).subscribe(_ => this.disableItems = true);
    this.endFocusSubscription = this.mapService.endFocused.pipe(filter(endFocus => endFocus == true)).subscribe(_ => this.disableItems = false);
  }

  ngOnDestroy(): void {
    this.userProfileSubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
    this.endFocusSubscription?.unsubscribe();
    this.focusedCoordsSubscription?.unsubscribe();
  }

  getDataForSelectedRoute(routesRecognized: RoutesRecognized ): any {
    let lastURLNode: string | undefined = routesRecognized.state.url.split("/").at(-1);
    lastURLNode = lastURLNode?.split("?").at(0);

    if (lastURLNode){
      let routeSnapshot: ActivatedRouteSnapshot | null | undefined = routesRecognized.state.root.firstChild;
      while(routeSnapshot?.routeConfig?.path && routeSnapshot.routeConfig.path != lastURLNode){
        routeSnapshot = routeSnapshot?.firstChild
      }
  
      return routeSnapshot?.routeConfig?.data;
    }

    return null;
  }

  logout() {
    this.userService.logout().pipe(first()).subscribe();
  }

  clickOnNavigationMenu() {
    if (this.location.path().includes('content')) {
      this.router.navigate(['/explore'], { replaceUrl: true });
    } else {
      this.menu?.toggle();
    }
  }
}
