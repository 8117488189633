<div id="main-actions-container">
    <ion-fab-button 
        mode="ios"
        id="overlie-add-button"
        class="interactable main-action"
        [ngStyle]="{ 'display' : (this.tileAction$ | async) == TileAction.Put || (this.tileAction$ | async) == TileAction.Post  ? 'block' : 'none' }"
        (click)="putTile()">
        <ion-icon name="add"></ion-icon>
    </ion-fab-button>
    <ion-fab-button
        mode="ios"
        id="overlie-create-button"
        class="interactable main-action"
        [ngStyle]="{ 'display' : (this.tileAction$ | async) == TileAction.CreateCommunity ? 'block' : 'none' }"
        (click)="createCommunity()">
        <p>Create community</p>
    </ion-fab-button>
    <ion-fab-button
        mode="ios" id="overlie-view-button"
        class="interactable main-action"
        [ngStyle]="{ 'display' : (this.tileAction$ | async) == TileAction.View || (this.tileAction$ | async) == TileAction.Post ? 'block' : 'none' }"
        (click)="viewTile()">
    <ion-icon name="view"></ion-icon>
</ion-fab-button>
</div>
<ion-fab-button
    [ngStyle]="{ 'visibility': (waitingForSponsoredCommunity | async) ? 'visible' : 'hidden'}"
    mode="ios" id="overlie-create-sponsored-button"
    class="interactable"
    (click)="createSponsoredCommunity()">
<p>Create your sponsored community now !</p>
</ion-fab-button>
<p>{{ timer$ | async | timer}}</p>
<p>Tile available: {{ tileAvailable$ | async }}</p>
<app-communities-ranking id="ranking" class="interactable" [ngStyle]="{ 'width': isMobile ? '50%' : '20%' }"></app-communities-ranking>