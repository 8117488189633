import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonButton } from '@ionic/angular';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent  implements OnInit, AfterViewInit, AfterViewChecked {

  private _isSynchronized: boolean | undefined
  private _context: CanvasRenderingContext2D | undefined

  _firstPlayButtonActive: boolean | undefined
  _isPlaying: boolean | undefined
  _isMuted: boolean | undefined
  _isFullScreen: boolean | undefined
  _progressBarColor: string | undefined = 'green'

  @ViewChild('player') player: ElementRef | undefined;
  @ViewChild('video') videoPlayer: ElementRef<HTMLVideoElement> | undefined;
  @ViewChild('fullscreenOverlay') fullscreenOverlay: ElementRef | undefined;
  @ViewChild('videoFullscreen') videoPlayerFullscreen: ElementRef<HTMLCanvasElement> | undefined;
  @ViewChild('playIconLayout') firstPlayButton: IonButton | undefined;
  @ViewChild('controls') controls: ElementRef | undefined;
  @ViewChild('progressBar') progressBar: ElementRef | undefined;
  @ViewChild('buttons') buttons: ElementRef | undefined;

  @Input() sourceUrl: string | undefined
  @Input() autoPlay: boolean | undefined
  @Input() preload: string | undefined
  @Input() progressColor: string | undefined
  @Input() contentBrowsingMode: boolean | undefined

  @Output() backButtonClicked: EventEmitter<boolean> | undefined = new EventEmitter<boolean>()

  constructor() { }

  ngOnInit()
  {
    this._isPlaying = this.autoPlay as boolean
    this._firstPlayButtonActive = !this._isPlaying
    this._isMuted = false
    this._isFullScreen = false
    this._isSynchronized = false;
  }

  ngAfterViewInit()
  {
    if (this.videoPlayer)
    {
      this.videoPlayer.nativeElement.style.pointerEvents = "none"
    }
  }

  ngAfterViewChecked(): void {
    if (this._isFullScreen && this.fullscreenOverlay && this.videoPlayerFullscreen && this.videoPlayer && !this._isSynchronized)
    {
      this._isSynchronized = true

      let canvas = this.videoPlayerFullscreen.nativeElement
      let context = this.videoPlayerFullscreen.nativeElement.getContext('2d')

      if (context)
      {
        this._context = context
      }

      let cw = 0
      let ch = 0

      let videoWidth = this.videoPlayer.nativeElement.videoWidth
      let videoHeight = this.videoPlayer.nativeElement.videoHeight

      let fullscreenHeight = this.fullscreenOverlay.nativeElement.offsetHeight 
      let fullscreenWidth = this.fullscreenOverlay.nativeElement.offsetWidth

      if (fullscreenWidth > fullscreenHeight)
      {
        if (videoWidth > videoHeight)
        {
          cw = fullscreenWidth
          ch = (fullscreenWidth / videoWidth) * videoHeight
        }
        else
        {
          ch = fullscreenHeight
          cw = (fullscreenHeight / videoHeight) * videoWidth
        }
      }
      else
      {
        if (videoHeight > videoWidth)
        {
          ch = fullscreenHeight
          cw = (fullscreenHeight / videoHeight) * videoWidth
        }
        else
        {
          cw = fullscreenWidth
          ch = (fullscreenWidth / videoWidth) * videoHeight
        }
      }

      canvas.width = cw;
      canvas.height = ch;

      if (this.videoPlayer && this._context)
      {
        this.updateBigVideo(this.videoPlayer?.nativeElement, this._context, cw, ch, true);
      }

      this.videoPlayer!.nativeElement.addEventListener('play', () =>
      {
        if (this.videoPlayer && this._context)
        {
          this.updateBigVideo(this.videoPlayer?.nativeElement, this._context, cw, ch);
        }
      }, false);
    }
  }

  updateBigVideo(v: HTMLVideoElement, c: CanvasRenderingContext2D, w: number, h: number, force: boolean = false): void
  {
    if (!this._isFullScreen || !this._context) return

    if (!force && (v.paused || v.ended)) return

    this._context.drawImage(v, 0, 0, w, h);
    
    setTimeout(() =>
    {
      if (this.videoPlayer && this._context)
      {
        this.updateBigVideo(this.videoPlayer.nativeElement, this._context, w, h)
      }
    }, 20);
  }

  FirstPlay()
  {
    if (!this.videoPlayer) return

    this.videoPlayer.nativeElement.play()
    this.videoPlayer.nativeElement.style.pointerEvents = "auto"

    this._isPlaying = true
    this._firstPlayButtonActive = false

    if (this.firstPlayButton && this.controls && this.progressBar && this.buttons)
    {
      this.controls.nativeElement.style.opacity = 1
      this.buttons.nativeElement.style.opacity = 1
      this.progressBar.nativeElement.style.opacity = 1

      if (this.contentBrowsingMode)
      {
        this.progressBar.nativeElement.style.setProperty('--overlie-progress-color', this.progressColor as string);
      }
      else
      {
        this.progressBar.nativeElement.style.setProperty('--overlie-progress-color', 'white');
      }
    }
  }

  PlayPause()
  {
    if (!this.videoPlayer) return

    this._isPlaying = !this._isPlaying
    if (this._isPlaying)
    {
      this.videoPlayer.nativeElement.play()
    }
    else
    {
      this.videoPlayer.nativeElement.pause()
    }
  }

  UpdateProgressBar()
  {
    var percentage = Math.floor((100 / this.videoPlayer!.nativeElement.duration) * this.videoPlayer!.nativeElement.currentTime);
  	// Update the progress bar's value
  	this.progressBar!.nativeElement.value = percentage;
  }

  SwitchDisplay()
  {
    if (!this.player) return;

    if (this._isFullScreen)
    {
      document.exitFullscreen()
    }
    else
    {
      this.player.nativeElement.requestFullscreen()
    }
  }

  FullScreenChange(event: Event)
  {
    if (this._isFullScreen)
    {
      this._isSynchronized = false
    }

    this._isFullScreen = !this._isFullScreen
  }

  MuteUnmute()
  {
    this._isMuted = !this._isMuted

    if (this.videoPlayer)
    {
      this.videoPlayer.nativeElement.muted = this._isMuted
    }
  }

  MouseEnter()
  {
    if (this.controls && this.progressBar && this.buttons && this._isPlaying)
    {
      this.progressBar.nativeElement.style.position = "relative"
      this.progressBar.nativeElement.style.opacity = 1
      this.progressBar.nativeElement.style.bottom = "auto"
      this.progressBar.nativeElement.style.top = "0px"

      this.controls.nativeElement.style.height = "auto"
      this.buttons.nativeElement.style.opacity = 1
    }
  }

  MouseLeave()
  {
    if (this.controls && this.buttons && this.progressBar && this._isPlaying)
    {
      this.controls.nativeElement.style.height = 0
      this.buttons.nativeElement.style.opacity = 0

      this.progressBar.nativeElement.style.position = "absolute"
      this.progressBar.nativeElement.style.bottom = "0px"
      this.progressBar.nativeElement.style.top = "auto"
    }
  }

  BackButtonClicked()
  {
    document.exitFullscreen()
    this.backButtonClicked?.emit(true)
  }

  LikePost()
  {
    console.log("Like not yet implemented")
  }
}
