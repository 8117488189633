import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'app/src/app/data/user.service';
import { environment } from 'app/src/environments/environment';
import { CommunityService } from '../../../data/community.service';
import { ContentsService } from 'app/src/app/data/contents.service';
import { PersonalContentMetadata, CommunityInfos } from '@overlie/types';
import { first, Subscription } from 'rxjs';
import { PopoverButtons, PopoverComponent } from '../popover/popover.component';
import { PopoverController } from '@ionic/angular';
import { SponsorComponent } from '../sponsor/sponsor.component';

@Component({
  selector: 'app-user-posts-list',
  templateUrl: './user-posts-list.component.html',
  styleUrls: ['./user-posts-list.component.scss'],
})

export class UserPostsListComponent  implements OnInit, OnDestroy {
  username: string | undefined
  userId: string | undefined
  contentsForUser: PersonalContentMetadata[] | undefined
  communitiesForUser: CommunityInfos[] | undefined
  contentsByCommunity: {community: CommunityInfos, contents: PersonalContentMetadata[]}[] | undefined
  idsCommunity: string[] | undefined

  communitySubscription: Subscription | undefined;
  contentSponsoSubscription: Subscription | undefined;

  @Input() coordinates: string | undefined

  api_url: string = environment.apiUrl;
  appUrl = environment.appUrl;
  @ViewChild('sponsorModal') sponsorModal: SponsorComponent | undefined

  iconSize: number = 20;
  fontSize: number = 15;

  constructor(private userService: UserService,
              private communityService: CommunityService,
              private contentsService: ContentsService,
              private popoverController: PopoverController) { }

  ngOnInit() {
    this.username = this.userService.userProfile.value.username;
    this.userId = this.userService.userProfile.value.id;

    this.fetchContent();

    this.contentSponsoSubscription = this.contentsService.contentSponsorised.subscribe(sponsoInfos =>
    {
      if (this.contentsByCommunity)
      {
        let matchingContent = this.contentsByCommunity.flatMap(obj => obj.contents).find(content => content.id == sponsoInfos.id);
        
        if (matchingContent)
        {
          (matchingContent.starsInvested as number) += sponsoInfos.amount;
          (matchingContent.nbSponsorisations as number)++;
        }
      }

      this.fetchContent();
    });
  }

  fetchContent(): void {
    this.contentsService.getContentsForUser(this.userId as string).pipe(first()).subscribe(contentsForUser =>
    {
      this.contentsForUser = contentsForUser
      
      this.communitySubscription = this.communityService.communities.subscribe(communities =>
      {
        this.communitiesForUser = Array.from(communities.values())
        const initialValue: any = this.communitiesForUser!.map(communityInfo => ({community: communityInfo, contents: []}))
        this.contentsByCommunity = this.contentsForUser!.map(content =>
        {
          let matchingCommunity = this.communitiesForUser?.find(community => community.id == content.communityId)
          return {community: matchingCommunity, content: content}
        }).reduce((accumulator, currentValue) =>
        {
          let result = accumulator.find((obj: { community: CommunityInfos | undefined; }) =>
          {
            if (obj.community && currentValue.community)
            {
              return obj.community.name == currentValue.community.name
            }

            return false
          })
          
          if (result)
          {
            result.contents.push(currentValue.content)
          }

          return accumulator
        }, initialValue);

        this.idsCommunity = this.communitiesForUser?.filter(communitie => communitie.creatorId == this.userId).map(communitie => communitie.id);
      });
    });
  }

  ngOnDestroy(): void {
    this.communitySubscription?.unsubscribe();
    this.contentSponsoSubscription?.unsubscribe();
  }

  async presentPopover(event: Event, contentId: string | undefined) {
    const props: PopoverButtons = [
    {
      id: 0, text: "Sponsor", onClick: () => {
        if (contentId) {
          this.sponsorModal?.Open(true, false, contentId);
        }

        popover.dismiss();
      }
    }];

    const popover = await this.popoverController.create({
      component: PopoverComponent,
      componentProps: {
        buttons: props,
      },
      event,
    });
    
    await popover.present();
  }
}