<ion-accordion-group>
  <ion-accordion>
    <ion-item slot="header" color="light">
      <ion-label>Communities ranking (territory)</ion-label>
    </ion-item>
    <div id="ranking-layout" slot="content">
      <div id="header-layout">
        <p id="rank-header">Rank</p>
        <p id="name-header">Community</p>
        <p id="score-header">Nb tiles</p>
      </div>
      @for(ranking of ranking$ | async; track $index)
      {
        <app-communities-ranking-item [rankingInfos]="ranking" [rank]="currentRange$.value[0] + $index + 1"></app-communities-ranking-item>
      }
      <div id="nav">
        <span id="previous" class="nav" [ngStyle]="{ 'visibility': currentRange$.value[0] == 0 ? 'hidden': 'visible' }" (click)="previous()">&#60;</span>
        <span id="next" class="nav" [ngStyle]="{ 'visibility':  (canGoNext$ | async) == true ? 'visible': 'hidden' }" (click)="next()">&#62;</span>
      </div>
    </div>
  </ion-accordion>
</ion-accordion-group>