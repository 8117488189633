export class Mail
{
    normalized: string;
    subject: string;
    title: string;
    text: string[];

    constructor(normalized: string, subject: string, title: string, text: string[])
    {
        this.normalized = normalized;
        this.subject = subject;
        this.title = title;
        this.text = text;
    }
}