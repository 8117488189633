<div id="main-container" class="fade" #container [ngStyle]="{ transform, display }" 
(wheel)="onWheel($event)" 
(mousedown)="onMouseDown($event)" 
(mouseup)="onMouseUp($event)" 
(mousemove)="onMouseMove($event)"
(touchstart)="onTouchStart($event)"
(touchmove)="onTouchMove($event)"
(touchend)="onTouchEnd($event)"
(mouseenter)="onMouseEnter()"
(mouseleave)="onMouseLeave()">
  @if (content) {
  @switch (content.type) {
  @case ("image") {
  <img class="thumbnail" [src]="thumbnailUrl">
  }
  @case ("video") {
  <video #video class="thumbnail video" [src]="getContentPayloadURL()" playsinline preload="none"></video>
  <img class="thumbnail" [src]="thumbnailUrl">
  }
  @case ("GIF") {
  <ion-img class="thumbnail" [src]="content.gifUrl"></ion-img>
  }
  @case ("text") {
  <div class="thumbnail">
    <div #text class="content-text">{{ content.text }}</div>
  </div>
  }
  @default {
  <p>No content here</p>
  }
  }
  }
  @else {
  <ion-spinner name="crescent"></ion-spinner>
  }
</div>