import { Component, Input, OnInit } from '@angular/core';
import { CommunityInfos, Poll } from '@overlie/types';
import { first, map, merge, Observable, skip } from 'rxjs';
import { CommunityService } from '../../../data/community.service';
import { environment } from 'app/src/environments/environment';

@Component({
  selector: 'app-polls-list',
  templateUrl: './polls-list.component.html',
  styleUrls: ['./polls-list.component.scss'],
})
export class PollsListComponent  implements OnInit {

  polls?: Observable<Poll[]>

  @Input() community?: CommunityInfos;
  @Input({required: true}) isModerator: boolean = false;
  @Input({required: true}) isAdmin: boolean = false;

  constructor(private communityService: CommunityService) { }

  ngOnInit()
  {
    if (!this.community) return;

    this.polls = merge(this.communityService.getCommunityPolls(this.community.id).pipe(first()), this.communityService.pollsByCommunity.pipe(skip(1), map(pollsByCommunity => {
      if (!this.community!.id) return [];
      let polls = pollsByCommunity.get(this.community!.id);
      if (!polls) return [];

      // Sort: from the oldest to the newest if the poll is not expired, then from the newest to the oldest if the poll is expired
      let sortByRelevance = polls.sort((a, b) => {
        const aRemainingTime = a.postDate + environment.pollVoteDelay - Date.now();
        const bRemainingTime = b.postDate + environment.pollVoteDelay - Date.now();

        if (aRemainingTime <= 0 && bRemainingTime > 0) return 1;
        if (bRemainingTime <= 0 && aRemainingTime > 0) return -1;
        if (aRemainingTime <= 0 && bRemainingTime <= 0) return b.postDate - a.postDate;
        return a.postDate - b.postDate;
      });
      return sortByRelevance;
    })))
  }
}
