import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonInput, IonModal, IonTextarea, Platform } from '@ionic/angular';
import { Subscription, catchError, combineLatest, first } from 'rxjs';
import { AcceptedMimeTypes, Media, MediaType, WorldPoint } from '@overlie/types';
import { AlertService } from '../../utils/alert.service';
import { CommunityService } from '../../data/community.service';
import { UserService } from '../../data/user.service';
import { MapService } from '../../data/map.service';
import { Colors } from '../../utils/Colors';
import { ActionSheet, ActionSheetButtonStyle } from '@capacitor/action-sheet';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { environment } from 'app/src/environments/environment';
import { FileService } from '../../utils/file.service';

@Component({
  selector: 'app-create-community-modal',
  templateUrl: './create-community-modal.component.html',
  styleUrls: ['./create-community-modal.component.scss']
})
export class CreateCommunityModalComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private communityService: CommunityService,
    private userService: UserService,
    private mapService: MapService,
    private alert: AlertService,
    private fileService: FileService) { }

  private didDismissSub?: Subscription;
  private willPresent?: Subscription;

  @ViewChild('modal') modal!: IonModal;
  @ViewChild('communityName') communityNameInput!: IonInput;
  @ViewChild('tagInput') tagInput!: IonInput;
  @ViewChild('descInput') descInput!: IonTextarea;
  @ViewChild('colorPicker') colorPicker!: ElementRef<HTMLInputElement>;

  @Input() coordinates?: WorldPoint;
  @Input() sponsoredMode?: boolean;

  modalBreakpoints = [0, 1];

  tags: string[] = [];
  communityName?: string
  colorValue?: string
  currentTag?: string
  uploadedImage?: Media

  tagsLimit = false;

  ngOnInit()
  { 
    this.tags = []
    this.colorValue = Colors.getRandomHexColor();
  }

  ngAfterViewInit()
  {
    this.willPresent = this.modal.ionModalWillPresent.subscribe(() => {
      this.colorValue = Colors.getRandomHexColor();

    });
    this.didDismissSub = this.modal?.didDismiss.subscribe(() => {
      this.clearValues();
    })
  }

  ngOnDestroy() {
    this.didDismissSub?.unsubscribe();
    this.willPresent?.unsubscribe();
  }

  async display(sponsoredMode: boolean = false) {
    await this.modal?.present();
    this.sponsoredMode = sponsoredMode;
  }

  updateColorValue()
  {
    this.colorValue = this.colorPicker?.nativeElement.value ?? '#000000'
  }

  async uploadImage() {
    let image = await this.fileService.uploadMedia(['image/png', 'image/jpg', 'image/jpeg', 'image/gif']);
    if (image) {
      this.uploadedImage = image;
    }
  }

  confirmTag()
  {
    if (!this.tagInput) return;
    if (this.tagInput.value == "") return;

    if (this.tags?.length == environment.maxTags) return;
    if (this.tags?.some(tag => tag.toLowerCase() == this.tagInput.value?.toString().toLowerCase())) return;

    this.tags?.push(this.tagInput.value as string);
    this.tagInput.value = "";
    if (this.tags.length == environment.maxTags) this.tagsLimit = true;
  }

  removeTag(tag: string)
  {
    this.tags = this.tags?.filter(t => t != tag);
    if (this.tags.length < environment.maxTags) this.tagsLimit = false;
  }

  confirmCreation()
  {
    if (!this.mapService.selectedTile) return
    combineLatest([this.userService.userProfile, this.mapService.selectedTile]).pipe(first()).subscribe(async ([profile, selectedTile]) =>
    {
      if (!(this.colorValue && this.communityNameInput && this.tags && this.descInput && selectedTile && this.sponsoredMode != undefined)) return
      let imageString = this.uploadedImage ? await this.fileService.blobToBase64(this.uploadedImage?.blob) : undefined;
      this.communityService.createNewCommunity({
        color: this.colorValue,
        name: this.communityNameInput.value as string,
        tags: this.tags,
        description: this.descInput.value as string,
        firstTilePosition: selectedTile[0],
        image: imageString,
        sponsored: this.sponsoredMode
      }).pipe(first(), catchError((e) =>
      {
        this.alert.error("Error", e.error)
        return [];
      }))
      .subscribe(async res => {
        if (res)
        {
          this.communityService.addNewCommunity({
            id: res.toString(),
            creatorId: profile.id,
            nbUsers: 1,
            nbBookmarks: 0,
            tileNumber: 1,
            bans: [],
            moderators: [],
            admins: [profile.id],
            color: this.colorValue!,
            name: this.communityNameInput!.value as string,
            tags: this.tags!,
            description: this.descInput!.value as string,
            image: imageString,
            sponsored: this.sponsoredMode!
          }, true);
          this.userService.updateUserCommunities(this.userService.userProfile.value.communities.concat([res.toString()]));
          this.alert.success("Community created", "You new community was created !");
          await this.modal?.dismiss();
        }
      })
    })
  }

  clearValues() {
    if (this.communityName) this.communityNameInput.value = "";
    if (this.tagInput) this.tagInput.value = "";
    if (this.descInput) this.descInput.value = "";
    this.tags = [];
    this.tagsLimit = false;
    this.removeMedia();
    this.colorValue = "#000000";
  }

  async cancelCreation()
  {
    await this.modal?.dismiss();
  }

  onTagInput(event: any) {
    const value = event.target!.value;

    let filteredValue = value.replace(/[^a-zA-Z0-9]+/g, '');
    if (this.tagsLimit) filteredValue = '';
    event.target.value = filteredValue;
  }
  

  removeMedia(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.uploadedImage = undefined;
  }
}
