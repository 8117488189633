export interface IUserSchema {
    _id: string,
    _username: string,
    _email: string,
    _normalizedEmail: string,
    _communities: string[],
    _stars: number,
    _bookmarks: string[],
    _badges: number[],
    _tileDelay: number,
    _nextTileDate: number,
    _lastConnectionDate: number,
    _gotDailyReward: boolean,
    _isAdmin: boolean,
    _isSubscribed: boolean,
    _quitCommunityTickets: number,
    _donationsAmount: number;
    _googleId: string | undefined,
    _verificationToken: string | undefined,
    _salt: string | undefined,
    _hash: string | undefined,
    _waitingForSponsoredCommunityCreation: WaitingForSponsoredCommunityCreation | undefined
}

export interface IStripeCustomerDataSchema
{
    _customerId: string;
    _userId: string;
    _subscriptionType: OverlieSubscriptionType;
    _subDate: number;
    _communityId: string | undefined;
    _communitySize: [number, number] | undefined;
}

export interface IUserSeenContentsSchema{
    _userId: string,
    _contentIds: string[]
}

export interface UserInfos extends UserPersonalInfos {
    isAdmin: boolean,
    normalizedEmail: string,
    donationsAmount: number,
    hash?: string,
    salt?: string,
    googleId?: string,
    verificationToken?: string,
    lastConnectionDate: number,
    gotDailyReward: boolean
}

export interface UserPersonalInfos extends UserMinimalInfos {
    email: string;
    isAdmin: boolean;
    stars: number,
    waitingForSponsoredCommunityCreation?: WaitingForSponsoredCommunityCreation
}

export type UserMinimalInfos = {
    id: string,
    username: string,
    communities: string[],
    bookmarks: string[],
    badges: number[],
    tileDelay: number,
    nextTileDate: number,
    isSubscribed: boolean,
    quitCommunityTickets: number,
};

export class WaitingForSponsoredCommunityCreation {
    
    size: [number, number]

    constructor(sizeLong: number, sizeLat: number){
        this.size = [sizeLong, sizeLat];
    }
}

export type OverlieSubscriptionType = 'community' | 'premium';

export class StripeCustomerData {

    customerId: string;
    userId: string;
    subscriptionType: OverlieSubscriptionType;
    subDate: number;
    communityId?: string;
    communitySize?: [number, number];

    constructor(customerId: string, userId: string, subscriptionType: OverlieSubscriptionType, subDate: number, communityId?: string, sizeLong?: number, sizeLat?: number)
    {
        this.customerId = customerId;
        this.userId = userId;
        this.subscriptionType = subscriptionType;
        this.subDate = subDate;

        if (communityId)
        {
            this.communityId = communityId;
        }

        if (sizeLong && sizeLat)
        {
            this.communitySize = [sizeLong, sizeLat];
        }
    }
}

export class UserSeenContents {
    userId: string;
    contentIds: string[];

    constructor(userId: string, contentIds: string[])
    {
        this.userId = userId;
        this.contentIds = contentIds;
    }
}