export module Colors {
    type COLOR_POSITIVE = '#2fff5d';
    type COLOR_NEUTRAL = '#f2f2f2';
    type COLOR_NEGATIVE = '#ff2f2f';
    export type SELECT_LINE_COLOR = COLOR_POSITIVE | COLOR_NEUTRAL | COLOR_NEGATIVE;
    export const SELECT_LINE_COLOR_POSITIVE: COLOR_POSITIVE = '#2fff5d';
    export const SELECT_LINE_COLOR_NEUTRAL: COLOR_NEUTRAL = '#f2f2f2';
    export const SELECT_LINE_COLOR_NEGATIVE: COLOR_NEGATIVE = '#ff2f2f';

    export function hexToRGB(hexColor: string): { red: number, green: number, blue: number } {
        const hex = hexColor.replace(/^#/, '');
        const rgb = parseInt(hex, 16);
        const red = (rgb >> 16) & 0xFF;
        const green = (rgb >> 8) & 0xFF;
        const blue = rgb & 0xFF;
        return { red, green, blue };
    }

    export function isColorDark(hexColor: string): boolean {
        const treshhold = 0.3;
        const {red, green, blue} = hexToRGB(hexColor);
        const luminance = 0.299 * red + 0.587 * green + 0.114 * blue;
        const normalized = luminance / 255;
        return normalized < treshhold;
    }

    export function getRandomHexColor(): string {
        const hex = Math.floor(Math.random() * 0xFFFFFF);
        return "#" + hex.toString(16).padStart(6, '0');
    }

    export function shadeHexColor(color: string, percent: number): string {
        var f=parseInt(color.slice(1),16),t=percent<0?0:255,p=percent<0?percent*-1:percent,R=f>>16,G=f>>8&0x00FF,B=f&0x0000FF;
        return "#"+(0x1000000+(Math.round((t-R)*p)+R)*0x10000+(Math.round((t-G)*p)+G)*0x100+(Math.round((t-B)*p)+B)).toString(16).slice(1);
    }
}