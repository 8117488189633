import { Injectable } from '@angular/core';
import { ActionPerformed, PushNotifications, Token } from '@capacitor/push-notifications';
import { OverlieHttpService } from './overliehttp.service';
import { environment } from 'app/src/environments/environment';
import { Platform } from '@ionic/angular';
import { OnboardingService } from '../views/onboarding/services/onboarding.service';
import { NotificationDataPayload, WorldPoint } from '@overlie/types';
import { Router } from '@angular/router';
import { MapService } from './map.service';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {

  constructor(private overlieHttp: OverlieHttpService,
    private platform: Platform,
    private onboarding: OnboardingService,
    private router: Router,
    private mapService: MapService)
  {
    if ((platform.is("ios") || platform.is("android")) && !platform.is("mobileweb"))
      {
        PushNotifications.requestPermissions().then((result) => {
          if (result.receive === 'granted') {
            console.log("Push permission granted !!")
            PushNotifications.register();
          } else {
            console.log("Push permission denied !!")
          }})
    
        PushNotifications.addListener('registration', (token: Token) => {
          overlieHttp.post<any>(`${environment.apiUrl}/push/subscribe`, { token: token.value })
        });

        PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
          let notifData = notification.notification.data as NotificationDataPayload;
          if (notifData) {
            if (notifData.redirectUrl) {
              router.navigateByUrl(notifData.redirectUrl.replace(environment.appUrl, ""));
            }
            else if (notifData.redirectCoordinates) {
              let coordinates = notifData.redirectCoordinates.split(",").map(x => +x);
              mapService.goToCoordinates(coordinates as WorldPoint);
            }
          }
        })
      }
  }
}
