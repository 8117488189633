<ion-modal id="sponsor" #sponsor>
  <ng-template id="outer-layout">
    <div id="layout">
      <button id="cancel-button" (click)="Close()">
        <ion-icon name="close-outline"></ion-icon>
      </button>
      <h1 class="titre-modal">Sponsor my post</h1>
      <p>Invest an amount of Overbucks to increase your content’s lifetime and visibility.</p>
      <app-inputfield id="inputfield" #inputField></app-inputfield>
      <div class="sponsor-infos">
        <p class="text">+ <b>{{ currentInvestedValue | async | oBToLifetime }}</b> hours lifetime</p>
        @if (isSponsoring)
        {
          <div class="infos-stars-container">
            <p class="text">+ <b>{{ currentInvestedValue | async | oBToReward }}</b></p>
            <svg id="star-logo" alt="Star icon" [attr.width]="iconSize" [attr.height]="iconSize" viewBox="0 0 95 90" xmlns="http://www.w3.org/2000/svg">
              <path d="M26.7715 21.2528L40.0131 3.73185C40.945 2.45524 42.0817 1.51411 43.4231 0.908469C44.7653 0.302824 46.16 0 47.6072 0C49.0544 0 50.4289 0.302824 51.7307 0.908469C53.0318 1.51411 54.1483 2.45524 55.0802 3.73185L68.2007 21.2528L88.524 28.1105C90.6544 28.9363 92.2663 30.196 93.3598 31.8895C94.4533 33.5839 95 35.3815 95 37.2823C95 38.2089 94.8474 39.1504 94.5421 40.1069C94.2368 41.0641 93.787 41.9694 93.1926 42.8226L80.0625 60.7161L80.547 80.0988C80.547 82.7754 79.5719 85.0947 77.6216 87.0568C75.6712 89.0189 73.3603 90 70.6888 90C70.4716 90 69.6979 89.9101 68.3678 89.7302L47.4861 83.5052L26.8466 89.7302C26.4428 89.8915 26.0531 89.9564 25.6776 89.925C25.3029 89.8944 24.9593 89.879 24.6467 89.879C21.8517 89.879 19.4491 88.9383 17.439 87.0568C15.4297 85.1754 14.4655 82.8157 14.5462 79.9778L15.0308 60.7161L1.80738 42.7016C1.21299 41.8419 0.763169 40.9504 0.457901 40.027C0.152634 39.1044 0 38.1815 0 37.2581C0 35.3024 0.579847 33.4609 1.73954 31.7335C2.90004 30.006 4.54106 28.7677 6.66259 28.0185L26.7715 21.2528Z" fill="currentColor"/>
            </svg>
            <p class="text">for each view</p>
          </div>
        }
      </div>
      <div class="action-buttons">
        <app-action-button class="action-button" text="Sponsor" type="primary" (click)="Confirm()"></app-action-button>
        @if (currentInvestedValue | async) {
          <app-action-button class="action-button" text="Cancel sponsoring" type="tertiary" (click)="Cancel()"></app-action-button>
        }
      </div>
    </div>
  </ng-template>
</ion-modal>