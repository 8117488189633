import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../data/user.service';
import { map, Observable } from 'rxjs';
import { UserMinimalInfos } from 'types/build';
import { ActivatedRoute, Router } from '@angular/router';
import { IonModal, Platform } from '@ionic/angular';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, AfterViewInit {

  modalBreakpoints = [0, 0.5, 1];
  userId?: string;
  isMobile: boolean = false
  showModal: boolean = false;
  user$?: Observable<UserMinimalInfos>;
  isSelf: boolean = false;

  @ViewChild('modal') modal?: IonModal;

  constructor(private userService: UserService, 
    private route: ActivatedRoute,
    private platform: Platform,
    private router: Router) { }

  ngOnInit() {
    this.userId = this.route.snapshot.paramMap.get('userId')!;
    if (!this.userId) { 
      this.userId = this.userService.userProfile.value.id;
      this.isSelf = true;
    }
    this.isMobile = this.platform.is('mobile');
    let isSelfProfile = this.route.snapshot.data['self'];
    this.showModal = !isSelfProfile && this.isMobile;

    this.user$ = this.userService.getUserProfile(this.userId);
  }

  ngAfterViewInit(): void {
    if (this.showModal)
    {
      this.modal?.present()
      this.modal?.ionModalDidDismiss.subscribe(() =>
      {
        this.router.navigateByUrl('/explore', {replaceUrl: true})
      })
    }
  }
}
