<div id="listLayout" #listLayout>
  @if (isModerator || isAdmin) {
    <app-poll-creation [communityId]="community?.id"></app-poll-creation>
  }
  @if (polls.length > 0) {
    @for(poll of polls; track $index)
    {
      <app-poll class="poll" [poll]="poll" [canModerate]="isAdmin || isModerator"></app-poll>
    }
  }   
  @else if (polls) {
    <p class="empty-polls">No poll for the moment</p>
  }
</div>