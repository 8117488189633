import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonMenu, Platform } from '@ionic/angular';
import { CommunityService } from 'app/src/app/data/community.service';
import { InteractionsService } from 'app/src/app/data/interactions.service';
import { MapService } from 'app/src/app/data/map.service';
import { UserService } from 'app/src/app/data/user.service';
import { AlertService } from 'app/src/app/utils/alert.service';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, first, map, merge, of, tap } from 'rxjs';
import { CommunityInfos } from 'types/build';

@Component({
  selector: 'app-map-overlay',
  templateUrl: './map-overlay.component.html',
  styleUrls: ['./map-overlay.component.scss'],
})
export class MapOverlayComponent  implements OnInit, OnDestroy {
  private sub?: Subscription;
  
  @ViewChild('leaderboardMenu') leaderboardMenu!: IonMenu;

  isMobile: boolean = false;
  timer$: Observable<number | null> = this.interactions.timer$;
  tileBoostTimer$: Observable<number | null> = this.interactions.tileBoostTimer$;
  tileAvailable$: Observable<boolean> = this.interactions.tileAvailable$;
  tileBoostAvailable$: Observable<boolean> = this.interactions.tileBoostAvailable$;
  tileBoostRemainingTiles$: Observable<number> = this.interactions.tileBoostRemainingTiles$;
  waitingForSponsoredCommunity?: Observable<boolean>;
  canPut: boolean = false;
  canCreateCom: boolean = false;
  canPost: boolean = false
  canView: boolean = false;
  communityInfo: BehaviorSubject<CommunityInfos | undefined> = new BehaviorSubject<CommunityInfos | undefined>(undefined);

  constructor(private interactions: InteractionsService,
    private mapService: MapService,
    private userService: UserService,
    private communityService: CommunityService,
    private router: Router,
    private alertService: AlertService,
    private platform: Platform) { }

  ngOnInit() {
    this.isMobile = this.platform.is('mobile')
    this.waitingForSponsoredCommunity = merge(of(this.userService.userProfile.value.waitingForSponsoredCommunityCreation != undefined),
    this.communityService.sponsoredCommunityCreated.pipe(map(_ => false)))
    this.sub = combineLatest([this.mapService.selectedTile, this.mapService.mapData]).pipe(tap(x => {
      let tile = x[0];
      let mapData = x[1];
      if (tile && mapData)
      {
        this.canPut = this.mapService.isTileClickable(tile[0]);
        this.canCreateCom = this.mapService.isTileEmpty(tile[0]);
        this.canView = !this.mapService.isTileEmpty(tile[0]);
        this.canPost = this.mapService.isTileCanBePosted(tile[0]);
        this.communityInfo.next(this.mapService.getTileCommunity(tile[0]));
      }
    })).subscribe();
  }

  seeCommunity() {
    this.router.navigate([`/explore/community/${this.communityInfo?.value?.id}`]);
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  putTile() {
    this.interactions.sendSignal<boolean>(this.interactions.addTileButtonClicked, true);
  }

  createCommunity()
  {
    this.interactions.sendSignal<boolean>(this.interactions.createCommunityButtonClicked, true);
  }

  createSponsoredCommunity()
  {
    this.interactions.sendSignal<boolean>(this.interactions.createSponsoredCommunityButtonClicked, true);
  }

  viewTile() {
    this.mapService.seeContent();
  }

  activateTileBoost(){
    if (this.userService.userProfile.value.tileBoostRemainingTiles == 0){
      this.userService.activateTileBooster().pipe(first()).subscribe();
    } else {
      this.alertService.success('Tile boost already activated', 'You still have ' + this.userService.userProfile.value.tileBoostRemainingTiles + ' tiles left to put');
    }
  }

  async openLeaderboard(){
    await this.leaderboardMenu.toggle();
  }
}
