import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ContentsService } from 'app/src/app/data/contents.service';
import { UserService } from 'app/src/app/data/user.service';
import { clear } from 'console';
import gsap from 'gsap';
import { first } from 'rxjs';

@Component({
  selector: 'app-overbucks',
  templateUrl: './overbucks.component.html',
  styleUrls: ['./overbucks.component.scss'],
})
export class OverbucksComponent implements OnInit, OnDestroy {

  @Input() amount: number | undefined;
  @Input() iconSize: number | undefined;
  @Input() fontSize: number | undefined;
  @Input() id: string | undefined;
  @Input() contentId: string | undefined;
  @Input() disabled: boolean | undefined;

  @ViewChild('starButton') starButton!: ElementRef<HTMLElement>;

  uniqueId: string | undefined;
  starInterval: NodeJS.Timeout | undefined; 
  likeAmount: number = 2;
  longPress: boolean = false;

  constructor(private ContentsService: ContentsService,
    private userService: UserService
  ) { }

  ngOnInit() {}

  ngOnDestroy() {
    clearInterval(this.starInterval as NodeJS.Timeout);
  }

  like(amount: number, event: MouseEvent) {
    if (this.id && this.userService.userProfile.value.stars >= 1)
    {
      this.ContentsService.sponsorContent(this.id as string, amount, false).pipe(first()).subscribe((ok) => {
      if (ok) {
        this.animateStarDonation(amount, event);
      } 
      });
    }
  }

  simpleClick(event: MouseEvent) {
    if (this.longPress) return;
    this.like(1, event);
  }

  pressStart(event: MouseEvent) {
    this.starInterval = setInterval(() => {
      this.longPress = true;
      this.like(this.likeAmount, event);
      this.likeAmount = Math.min(this.likeAmount + 2, 10);
    }, 500);
  }

  pressStop() {
    clearInterval(this.starInterval as NodeJS.Timeout);
    this.likeAmount = 2; 
    setTimeout(() => {
      this.longPress = false;
    }, 50);
  }

  async animateStarDonation(amount: number, event: MouseEvent): Promise<void> {
    const fragment = document.createDocumentFragment();
    const amountText = document.createElement('span');
    amountText.textContent = `+${amount}`;
    amountText.classList.add('animated-star-amount');
    let target = (event.target as HTMLElement);
    const y = target.getBoundingClientRect().top - 20;
    const x = target.getBoundingClientRect().left + target.getBoundingClientRect().width / 2 - 8;
    amountText.style.top = `${y}px`;
    amountText.style.left = `${x}px`;

    fragment.appendChild(amountText);
    gsap.to(amountText, {
      duration: 2,
      y: -30,
      opacity: 0,
      ease: 'power3.out',
      onComplete: () => {
        amountText.remove();
      }
    });
    // If the target is the path element of the svg, get the parent
    if (target.tagName === 'path') {
      target = target.parentElement as HTMLElement;
    }
    gsap.to(target, {
      scale: 1.2,
      duration: 0.1,
      color: "#F9DB78",
      yoyo: true,
      rotateZ: gsap.utils.random(-10, 10),
      repeat: 1,
      onComplete: () => {
        gsap.set(target, { clearProps: 'all' });
      }
    });

    document.body.appendChild(fragment);
  }
}
