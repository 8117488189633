import Stripe from "stripe";

export class ProductInfos
{
    product: Stripe.Product;
    price: Stripe.Price;

    public constructor(product: Stripe.Product, price: Stripe.Price)
    {
        this.product = product;
        this.price = price;
    }
}