export class NotificationPush
{
    userId: string;
    title: string;
    message: string;

    constructor(userId: string, title: string, message: string)
    {
        this.userId = userId; 
        this.title = title; 
        this.message = message; 
    }
}