export type ConnectionStatus = 'OK' | 'WaitingFor' | 'KO';

export type ConnectionInfos = {
    status: ConnectionStatus,
    token: string
}

export type GoogleOAuthInfos = {
    id: string;
    token: string;
    email: string;
}

export interface IUserUniqueTokenInfosSchema {
    _id: string,
    _userId: string,
}

export type UserUniqueTokenInfos = {
    token: string;
    userId: string;
}