@if (_isMobile)
{
  <ion-modal id="modal" #modal [initialBreakpoint]="modalBreakpoints[1]" [breakpoints]="modalBreakpoints">
    <ng-template>
      <div id="layout">
        <h1 id="title">My Posts</h1>
        <app-user-posts-list id="user-posts" (touchmove)="pointerMove($event)"></app-user-posts-list>
      </div>
    </ng-template>
  </ion-modal>
}
@else
{
  <ion-content id="main-container" class="ion-padding">
    <h1 id="title">My Posts</h1>
    <app-user-posts-list id="user-posts"></app-user-posts-list>
  </ion-content>
}

