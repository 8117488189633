import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommunityService } from 'app/src/app/data/community.service';
import { catchError, first, map, Observable, Subscription, tap } from 'rxjs';
import { CommunityInfos, TagLink } from '@overlie/types';
import { IonInput } from '@ionic/angular';
import { MapService } from 'app/src/app/data/map.service';
import { FileService } from 'app/src/app/utils/file.service';
import { AlertService } from 'app/src/app/utils/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'app/src/app/data/user.service';
import { environment } from 'app/src/environments/environment';
import { ConfirmationService } from 'app/src/app/utils/confirmation.service';

@Component({
  selector: 'app-community-profile',
  templateUrl: './community-profile.component.html',
  styleUrls: ['./community-profile.component.scss'],
})
export class CommunityProfileComponent implements OnInit, OnDestroy {

  constructor(
    private communityService: CommunityService,
    private mapService: MapService,
    private fileService: FileService,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private confirmation: ConfirmationService
  ) { }

  @Input() canEdit: boolean = false;
  @Input() community?: CommunityInfos;
  @ViewChild('name') nameInput!: ElementRef<HTMLElement>;
  @ViewChild('description') descriptionInput!: ElementRef<HTMLElement>;
  @ViewChild('tagInput') tagInput!: IonInput;

  editNameMode: boolean = false;
  editDescriptionMode: boolean = false;
  editTagsMode: boolean = false;
  savedTags: string[] = [];
  tagsLimit = false;
  selectedTag: Observable<TagLink | undefined> = this.mapService.selectedTagLink;

  communitiesSubscription?: Subscription;
  isMember$: Observable<boolean> = this.communityService.myCommunities$.pipe(map(communities => {
    if (!this.community) return false;
    return communities.includes(this.community.id) ?? false
  }));
  isBookmarked$: Observable<boolean> = this.communityService.myBookmarks$.pipe(map(bookmarks => {
    if (!this.community) return false;
    return bookmarks.includes(this.community.id) ?? false
  }));

  ngOnInit() {
    this.savedTags = this.community?.tags.slice() ?? []

    if (this.community) {
      this.communitiesSubscription = this.communityService.communities.subscribe(communities => {
        if (communities.has(this.community!.id)) {
          this.community = communities.get(this.community!.id);
        }
      });
    }
  }

  ngOnDestroy() {
    this.communitiesSubscription?.unsubscribe();
  }


  showRelatedCommunities(tag: string, event: MouseEvent) {
    event.stopPropagation();
    if (this.editTagsMode) return;
    this.selectedTag?.pipe(
      first(),
      tap(selectedTagValue => {
        if (selectedTagValue && selectedTagValue.tag == tag) {
          this.mapService.clearTagLinks();
          this.mapService.selectedTagLink.next(undefined);
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { tag: null, origin: null },
            preserveFragment: true,
            queryParamsHandling: 'merge'
          });
        } else {
          if (!this.community) return;
          this.mapService.selectedTagLink.next({ originCommunityId: this.community.id, tag });
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { tag, origin: this.community.id },
            preserveFragment: true,
            queryParamsHandling: 'merge'
          });
        }
      })
    ).subscribe();
  }

  async editAvatar() {
    let media = await this.fileService.uploadMedia(['image/*']);
    if (!media) return;
    if (media.blob.size > 50000000) {
      this.alert.error("Oups...", "Image is too large (max 5MB)");
      return;
    }
    let image = await this.fileService.blobToBase64(media.blob);
    if (!image) return;
    if (this.community) {
      this.communityService.editCommunity(this.community.id, { image }).pipe(first()).subscribe((c) => {
        if (!c) return;
        this.community = c;
        this.alert.success("Success", "Community avatar updated");
      });
    }
  }


  enterEditNameMode() {
    if (!this.canEdit) return;
    this.editNameMode = true;
    setTimeout(() => this.nameInput.nativeElement.focus(), 0);
  }

  cancelEditName() {
    this.editNameMode = false;
    this.nameInput.nativeElement.textContent = this.community?.name || null;
  }

  async editName() {
    this.editNameMode = false;
    if (this.nameInput.nativeElement.textContent == this.community?.name ||
      this.nameInput.nativeElement.textContent == null ||
      this.nameInput.nativeElement.textContent == "" ||
      typeof this.nameInput.nativeElement.textContent == "number") {
      return;
    }
    if (this.community) {
      this.communityService.editCommunity(this.community.id, { name: this.nameInput.nativeElement.textContent }).pipe(first(), catchError(e => {
        this.alert.error("Oups...", e.error);
        this.nameInput.nativeElement.textContent = this.community?.name || null;
        return [];
      })).subscribe((c) => {
        if (!c) return;
        this.community = c;
        this.alert.success("Success", "Community name updated");
      });
    }
  }

  enterEditDescriptionMode() {
    if (!this.canEdit) return;
    this.editDescriptionMode = true
    setTimeout(() => this.descriptionInput.nativeElement.focus(), 0);
  }

  cancelEditDescription() {
    this.editDescriptionMode = false;
    this.descriptionInput.nativeElement.textContent = this.community?.description || null;
  }

  async editDescription() {
    this.editDescriptionMode = false;
    if (this.descriptionInput.nativeElement.textContent == this.community?.description || this.descriptionInput.nativeElement.textContent == null) {
      return;
    }
    if (this.community) {
      this.communityService.editCommunity(this.community.id, { description: this.descriptionInput.nativeElement.textContent }).pipe(first(), catchError(e => {
        this.alert.error("Oups...", e.error);
        this.descriptionInput.nativeElement.textContent = this.community?.description || null;
        return [];
      })).subscribe((c) => {
        if (!c) return;
        this.community = c;
        this.alert.success("Success", "Community description updated");
      });
    }
  }

  enterEditTagsMode() {
    if (!this.canEdit || !this.community || this.editTagsMode) return;
    this.editTagsMode = true;
    this.savedTags = this.community.tags.slice();
    this.tagsLimit = this.community!.tags.length == environment.maxTags;
    this.mapService.selectedTagLink.next(undefined);
  }

  cancelEditTags() {
    if (!this.community) return;
    this.editTagsMode = false;
    this.community.tags = this.savedTags;
    this.tagsLimit = this.community!.tags.length == environment.maxTags;
  }

  deleteTag(tag: string) {
    if (!this.community) return;
    this.community.tags = this.community.tags.filter(t => t != tag);
    this.tagsLimit = this.community.tags.length == environment.maxTags;
  }

  addTag() {
    if (!this.tagInput || !this.community) return;
    if (this.tagInput.value == "") return;

    if (this.community.tags?.length == environment.maxTags) return;
    if (this.community.tags?.find(tag => tag == this.tagInput?.value) != null) return;

    this.community.tags?.push(this.tagInput.value as string);
    this.tagInput.value = "";
    if (this.community.tags.length == environment.maxTags) this.tagsLimit = true;
  }

  onTagInput(event: any) {
    const value = event.target!.value;

    let filteredValue = value.replace(/[^a-zA-Z0-9]+/g, '');
    if (this.tagsLimit) filteredValue = '';
    event.target.value = filteredValue;
  }

  async editTags() {
    this.editTagsMode = false;
    if (!this.community || this.community?.tags == this.savedTags) return;
    this.communityService.editCommunity(this.community.id, { tags: this.community.tags }).pipe(first(), catchError(e => {
      this.alert.error("Oups...", e.error);
      this.community!.tags = this.savedTags;
      return [];
    })).subscribe((c) => {
      if (!c) return;
      this.community = c;
      this.alert.success("Success", "Community tags updated");
    });
  }

  joinCommunity() {
    if (!this.community) return;
    this.communityService.joinCommunity(this.community.id);
  }

  async leaveCommunity() {
    if (!this.community) return;
    let res = await this.confirmation.confirm("Leave community", "Are you sure you want to leave this community?", '')
    if (res) this.communityService.leaveCommunity(this.community.id);
  }

  bookmarkCommunity() {
    if (!this.community) return;
    this.communityService.addBookmark(this.community.id);
  }

  unbookmarkCommunity() {
    if (!this.community) return;
    this.communityService.remBookmark(this.community.id);
  }

  async inviteToCommunity() {
    if (!this.community) return;
    let user = this.userService.userProfile.getValue();
    if (!user.id) return;
    let url = `${environment.appUrl}/explore/community/${this.community.id}?invitation=${user.id}`;
    this.alert.showInvitationQRCode(url);
  }
}
