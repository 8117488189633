import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonContent, IonModal, Platform } from '@ionic/angular';
import { CommunityService } from '../../../data/community.service';
import { CommunityInfos, TileData } from 'types/build';
import { first, map, merge, Observable, skip, Subscription } from 'rxjs';
import { MapService } from 'app/src/app/data/map.service';
import { Colors } from 'app/src/app/utils/Colors';

@Component({
  selector: 'app-community-overlay',
  templateUrl: './community-overlay.component.html',
  styleUrls: ['./community-overlay.component.scss'],
})
export class CommunityOverlayComponent implements OnInit, AfterViewInit, OnDestroy {

  modalBreakpoints = [0, 0.5, 1];
  communityId?: string;
  communityInfos?: Observable<CommunityInfos | void>;
  isMobile?: boolean;

  mapDataSubscription?: Subscription;

  @ViewChild("mainContainer") content?: ElementRef<HTMLElement>;
  @ViewChild('modal') modal?: IonModal;
  
  constructor(private route: ActivatedRoute,
    private platform: Platform,
    private mapService: MapService,
    private communityService: CommunityService,
    private router: Router) { }

  ngOnInit() {
    if (!this.route.snapshot.paramMap.get('communityId')) return;

    this.communityId = this.route.snapshot.paramMap.get('communityId')!;
    this.isMobile = this.platform.is('mobile');
    this.communityInfos = merge(this.communityService.getCommunityInfo(this.communityId).pipe(first()),
      this.communityService.communities.pipe(skip(1), map(communities => {
        if (!this.communityId) return;
        return communities.get(this.communityId)
      })));
    this.mapDataSubscription = this.mapService.mapData?.subscribe((md) => {
      if (md && this.communityId) {
        this.mapService.focusCommunity(this.communityId);
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.isMobile)
    {
      this.modal?.present()
      this.modal?.ionModalDidDismiss.subscribe(() =>
      {
        this.router.navigateByUrl('/explore', {replaceUrl: true})
      })
    }
    this.communityInfos?.pipe(first()).subscribe((c) => {
      if (!c) return;
      let color = Colors.shadeHexColor(c.color, -0.8);
      this.content?.nativeElement.style.setProperty('background', `linear-gradient(180deg, ${color}a3 0%, var(--ion-background-color) 100%)`);
    });
  }

  ngOnDestroy(): void {
    this.mapDataSubscription?.unsubscribe();
  }
}
