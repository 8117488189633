import { AfterViewInit, Component, ComponentRef, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MapService } from '../../data/map.service';
import { ContentsService } from '../../data/contents.service';
import { ContentGroup, SwipeDirection } from '@overlie/types';
import { filter, Subscription } from 'rxjs';
import { SwipeSlideComponent } from './swipe-slide/swipe-slide.component';
import { InteractionsService } from '../../data/interactions.service';
import { CommunityService } from '../../data/community.service';
import { Platform } from '@ionic/angular';
import { StorageService } from '../../data/storage.service';

@Component({
  selector: 'app-content',
  templateUrl: './content-view.component.html',
  styleUrls: ['./content-view.component.scss']
})
export class ContentViewComponent implements OnInit, AfterViewInit, OnDestroy {
  public id!: string;
  contentGroup: ContentGroup = {
    middle: null,
    top: null,
    bottom: null,
    left: null,
    right: null,
  };
  @ViewChild('renderPlaceholder', { read: ViewContainerRef }) renderPlaceholder!: ViewContainerRef;

  availableDirections: SwipeDirection[] = [];
  directionsWithNewContent: SwipeDirection[] = [];
  directionsWithSponsoredContent: SwipeDirection[] = [];
  swipeComponent?: ComponentRef<SwipeSlideComponent>;
  swipeSubscription?: Subscription;
  contentSubscription?: Subscription;
  queryParamsSubscription?: Subscription;

  showButtons = this.platform.is('desktop');

  private readonly defaultBackgroundColor = 'var(--background)';
  communityColor: string = this.defaultBackgroundColor;

  constructor(
    private localStorage: StorageService,
    private route: ActivatedRoute,
    private router: Router,
    private mapService: MapService, 
    private contentService: ContentsService, 
    private interactionService: InteractionsService,
    private communityService: CommunityService,
    private platform: Platform) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.contentGroup.middle = this.contentService.currentContent;
      this.createSwipeComponent();
      this.getNearbyContents();
    });
  }

  ngOnDestroy() {
    this.swipeSubscription?.unsubscribe();
    this.contentSubscription?.unsubscribe();
    this.queryParamsSubscription?.unsubscribe();
    this.contentService.currentContent = null;
    this.contentService.previousContent = null;
    this.contentService.swipeDirection = null;
    this.swipeComponent?.destroy();
    this.interactionService.sendSignal(this.interactionService.contentBackgroundColor, this.defaultBackgroundColor);
  }

  getNearbyContents() {
    this.contentSubscription = this.contentService.getContentWithNearby(this.id).subscribe(content => {
      let contentGroup: ContentGroup = {
        middle: null,
        top: null,
        bottom: null,
        left: null,
        right: null
      };
      if (this.contentService.swipeDirection) {
        let oppositeDirection = this.getOppositeDirection(this.contentService.swipeDirection);
        contentGroup[oppositeDirection] = this.contentService.previousContent;
      }

      this.contentGroup = content ? {
        middle: content,
        top: content.nearbyContents.top,
        bottom: content.nearbyContents.bottom,
        left: content.nearbyContents.left,
        right: content.nearbyContents.right,
      } : contentGroup;

      this.availableDirections = Object.keys(this.contentGroup).filter(key => key !== "middle" && this.contentGroup[key as SwipeDirection] !== null) as SwipeDirection[];
      this.directionsWithNewContent = Object.keys(this.contentGroup).filter(key => key !== "middle")
         .filter(async key => {
            if (this.contentGroup[key as SwipeDirection]?.id ?? false)
            {
              let storageItem = await this.localStorage.getItem(this.contentGroup[key as SwipeDirection]?.id!)
              return storageItem == null || storageItem !== this.contentGroup[key as SwipeDirection]?.contentId
            }

            return false;
         }) as SwipeDirection[];

        this.directionsWithSponsoredContent = Object.keys(this.contentGroup).filter(key => key !== "middle")
         .filter(async key => {
            if (this.contentGroup[key as SwipeDirection]?.id ?? false)
            {
              let storageItem = await this.localStorage.getItem(this.contentGroup[key as SwipeDirection]?.id!)
              return (storageItem == null || storageItem !== this.contentGroup[key as SwipeDirection]?.contentId) && (this.contentGroup[key as SwipeDirection]?.starsInvested ?? 0 > 0);
            }

            return false;
         }) as SwipeDirection[];

      this.swipeComponent!.instance.contents = this.contentGroup;
      if (!content) return;
      if (content.communityId) {
        this.communityService.fetchCommunity(content.communityId).subscribe(community => {
          this.interactionService.sendSignal(this.interactionService.contentBackgroundColor, community?.color || this.defaultBackgroundColor);
        });
      }

      if (content.id && content.contentId)
      {
        this.localStorage.setItem(content.id, content.contentId)
      }
    });
  }

  createSwipeComponent() {
    this.swipeComponent?.destroy();
    this.swipeComponent = this.renderPlaceholder.createComponent<SwipeSlideComponent>(SwipeSlideComponent);
    this.swipeComponent.instance.contents = this.contentGroup!;
    
    setTimeout(() => {
      this.swipeSubscription = this.swipeComponent?.instance.swipeDirection.pipe(filter(v => v !== null)).subscribe(direction => {
        this.handleSwipe(direction!);
      });
    }, 50)
  }

  handleSwipe(direction: SwipeDirection) {
    if (direction) {
      this.contentService.swipeDirection = direction;
      this.contentService.previousContent = this.contentGroup.middle;
      this.contentService.currentContent = this.contentGroup[direction];
      this.router.navigate(['/content'], { queryParams: { id: this.contentGroup[direction]?.id || this.mapService.getNearbyIds(this.id)[direction] } });
    }
  }

  onButtonClicked(event: { direction: SwipeDirection }) {
    this.swipeComponent?.instance.onButtonClicked(event);
  }

  getOppositeDirection(direction: SwipeDirection): SwipeDirection {
    switch (direction) {
      case 'top': return 'bottom';
      case 'bottom': return 'top';
      case 'left': return 'right';
      case 'right': return 'left';
    }
  }

}
