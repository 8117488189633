<div>
  <ion-segment [scrollable]="true" [value]="currentPage" (ionChange)="changePage($event)">
    @for (fragment of fragmentArray; track $index) {
    <ion-segment-button [value]="fragment.value">
      <ion-label>{{ fragment.label }}</ion-label>
    </ion-segment-button>
    }

  </ion-segment>
  <div class="fragment-content">
    @switch (currentPage) {
    @case ("infos") {
      <app-community-profile [type]="'community'" [community]="community" [canEdit]="isAdmin && isCreator"></app-community-profile>
    }
    @case('members') {
    <app-users-list [community]="community" [isCreator]="isCreator" [isAdmin]="isAdmin" [isModerator]="isModerator"
      (communityUpdated)="updateCommunity($event)"></app-users-list>
    }
    @case("polls") {
    <app-polls-list [community]="community" [isAdmin]="isAdmin" [isModerator]="isModerator"></app-polls-list>
    }
    @case('chat') {
    <p>Members only</p>
    }
    @case ('moderation') {
    <p>Moderators only</p>
    }
    }
  </div>
</div>