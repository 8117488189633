import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'app/src/environments/environment';

@Pipe({
  name: 'oBToLifetime'
})
export class OBToLifetimePipe implements PipeTransform {

  transform(value: number | null, ...args: unknown[]): number {
    if (value)
    {
      return (value * environment.starValueInMS) / (3600 * 1000);
    }

    return 0;
  }

}
