export interface IBadgeSchema {
    _id: number,
    _name: string,
    _description: string,
    _message: string,
}

export class Badge {
    id: number;
    name: string;
    description: string;
    message: string;

    public constructor(id: number, name: string, description: string, message: string) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.message = message;
    }

    public toJSON() {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            message: this.message,
        }
    }

    public static fromJSON(json: { [key: string]: any }): Badge {
        return new Badge(
            json['id'],
            json['name'],
            json['description'],
            json['message']);
    }
}