import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { CapacitorHttp } from '@capacitor/core';
import { environment } from 'app/src/environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class OverlieHttpService {

  isNative: boolean;

  constructor(private angularHttpClient: HttpClient, private platform: Platform, private localStorage: LocalStorageService) { 
    this.isNative = this.platform.is("mobile") && !this.platform.is("mobileweb");
  }

  get<Type>(url: string): Observable<Type>
  {
    if (this.isNative)
    {
      let result: Subject<Type> = new Subject<Type>();
      CapacitorHttp.get({ 
        url: url,
        headers: { 'token': this.localStorage.getItem('uniqueToken') ?? '' },
        webFetchExtra: { credentials: "include" },
      }).then(response =>
        {
          if (response.status == 200)
          {
            result.next(response.data);
          }
          else
          {
            result.error('HttpRequestFail')
          }
        })
      return result;
    }
    else
    {
      return this.angularHttpClient.get<Type>(url, { ...environment.httpOptions, headers: new HttpHeaders({ 'token': this.localStorage.getItem('uniqueToken') ?? '' })})
    }
  }

  post<Type>(url: string, body: any): Observable<Type | null>
  {
    if (this.isNative)
    {
      console.log(JSON.stringify(body))
      let result: Subject<Type | null> = new Subject<Type | null>();
      fetch(url, {
        method: "POST",
        headers: {
          'token': this.localStorage.getItem('uniqueToken') ?? '' ,
        },
        body: body
      }).then(response => {
        if (response.status == 200)
        {
          result.next(null);
        }
        else
        {
          result.error(response)
        }
      })
      return result;
    }
    else
    {
      return this.angularHttpClient.post<Type>(url, body, { ...environment.httpOptions, headers: new HttpHeaders({ 'token': this.localStorage.getItem('uniqueToken') ?? '' })})
    }
  }

  postJson<Type>(url: string, body: any): Observable<Type | null>
  {
    if (this.isNative)
      {
        let result: Subject<Type | null> = new Subject<Type | null>();
        const options = {
          url: url,
          headers: {
            'Content-Type': 'application/json',
            'token': this.localStorage.getItem('uniqueToken') ?? ''
          },
          data: JSON.stringify(body)
        };
        
        CapacitorHttp.post(options).then(response => {
          if (response.status == 200)
          {
            result.next(response.data);
          }
          else
          {
            result.error(response)
          }
        })
        return result;
      }
      else
      {
        return this.angularHttpClient.post<Type>(url, body, { ...environment.httpOptions, headers: new HttpHeaders({ 'token': this.localStorage.getItem('userToken') ?? '' })})
      }
  }

  put<Type>(url: string, body: any): Observable<Type | null>
  {
    if (this.isNative)
    {
      let result: Subject<Type | null> = new Subject<Type | null>();
      fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(body)
      }).then(response => {
        if (response.status == 200)
        {
          result.next(null);
        }
        else
        {
          result.error('HttpRequestFail')
        }
      })
      return result;
    }
    else
    {
      return this.angularHttpClient.put<Type>(url, body, environment.httpOptions)
    }
  }
}
