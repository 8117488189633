import { Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Subscription, buffer, filter, first } from 'rxjs';
import { CommunityInfos, ContentMetadata, ContentType, MediaType } from '@overlie/types';
import { ContentsService } from 'app/src/app/data/contents.service';
import { CommunityService } from 'app/src/app/data/community.service';
import { VideoComponent } from '../video/video.component';
import { Platform, PopoverController } from '@ionic/angular'
import { UserService } from 'app/src/app/data/user.service';
import { environment } from 'app/src/environments/environment';
import { ConfirmationService } from 'app/src/app/utils/confirmation.service';
import { MapService } from 'app/src/app/data/map.service';
import { MapUtils } from 'app/src/app/utils/MapUtils';
import { ActivatedRoute } from '@angular/router';
import { PopoverButtons, PopoverComponent } from '../popover/popover.component';

@Component({
  selector: 'app-media-content',
  templateUrl: './media-content.component.html',
  styleUrls: ['./media-content.component.scss']
})
export class MediaContentComponent implements OnInit, OnChanges {
  contentSubscription: Subscription | undefined;

  @ViewChild("layout") layout!: ElementRef;
  @ViewChild("video") video!: VideoComponent;

  @Input({ required: true }) content!: ContentMetadata | null;
  @Input() focused: boolean = false;
  @Input() autoPlay?: boolean;

  @HostListener("click", ["$event"])
  async onClick($event: MouseEvent) {
    this.handleLinkClick($event);
  }

  @HostListener("touchstart", ["$event"])
  async onTouchStart($event: TouchEvent) {
    this.handleLinkClick($event);
  }

  iconSize: number = 35;
  fontSize: number = 16;
  community?: CommunityInfos;
  authorUserame?: string;

  showJoinCommunityButton: boolean = true;

  payloadUrl?: string;
  url: string = "";

  constructor(private contentService: ContentsService,
    private communityService: CommunityService,
    private userService: UserService,
    private confirm: ConfirmationService,
    private platform: Platform,
    private mapService: MapService,
    private route: ActivatedRoute,
    private popoverController: PopoverController) { 
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    let urlSuffix = window.location.href.split("/");
    this.url = `${environment.appUrl}/${urlSuffix[urlSuffix.length - 1]}`;

    if (changes['focused']?.currentValue) {
      this.route.queryParams.pipe(first()).subscribe(queryParams => this.mapService.setMapState(MapUtils.worldPointFromUrl(queryParams['id'])));
    }

    this.content = changes['content'].currentValue;
    if (this.content) {
      if (this.content.type && this.content.id && (this.content.type === ContentType.Image || this.content.type === ContentType.Video)) {
        this.payloadUrl = this.contentService.getContentPayloadURL(this.content.id);
      }
      if (this.content.userId) {
        this.userService.getUserProfile(this.content.userId).pipe(first()).subscribe(user => {
          this.authorUserame = user?.username;
        })
      }
      if (this.content.communityId) {
        this.communityService.getCommunityInfo(this.content.communityId).pipe(first()).subscribe(community => {
          this.community = community;
        });
      }
      this.contentSubscription?.unsubscribe();
      this.contentSubscription = this.contentService.contentSponsorised.pipe(filter(obj => obj.id == this.content?.id)).subscribe(obj => {
        if (this.content?.starsDonated !== undefined) {
          this.content.starsDonated += obj.amount
        }
      });

      this.userService.getSelfProfile().pipe(first()).subscribe(profile => {
        if (this.content?.communityId && profile?.communities.includes(this.content.communityId)) {
          this.showJoinCommunityButton = false;
        }
        if (this.content?.userId) {
          this.userService.getUserProfile(this.content.userId).pipe(first()).subscribe(user => {
            this.authorUserame = user?.username;
          })
        }
        if (this.content?.communityId) {
          this.communityService.getCommunityInfo(this.content.communityId).pipe(first()).subscribe(community => {
            this.community = community;
          });
        }
      });
    }
  }

  joinCommunity(): void {
    if (this.content?.communityId) {
      this.communityService.joinCommunity(this.content?.communityId);
      this.showJoinCommunityButton = false;
    }
  }

  async leaveCommunity(): Promise<void> {
    if (this.community) {
      let message: string = this.community.nbUsers == 1
        ? `It seems like you're the only one left in this community. It is at risk of being deleted if you leave it. Are you sure you want to leave ${this.community.name} ?`
        : `Are you sure you want to leave ${this.community.name} ?`;

      let res = await this.confirm.confirm("Leave community", "", message);
      if (res) {
        this.communityService.leaveCommunity(this.community.id);
        this.showJoinCommunityButton = true;
      }
    }
  }

  async reportContent(): Promise<void> {
    if (this.content) {
      let reportReason = await this.confirm.select("Report content", "", "Why do you want to report this content ?", [{
        text: "Inappropriate content",
        value: "Inappropriate content",
      },
      {
        text: "Spam",
        value: "Spam"
      },
      {
        text: "Harassment",
        value: "Harassment"
      }, 
      {
        text: "Other",
        value: "Other"
      }
      ]);
      if (reportReason) {
        this.contentService.reportContent(this.content.id!, reportReason);
      }
    }
  }

  async reportUser(): Promise<void> {
    if (this.content) {
      let reportReason = await this.confirm.select("Report user", "", "Why do you want to report this user ?", [{
        text: "Inappropriate name",
        value: "Inappropriate name"
      },
      {
        text: "Spam",
        value: "Spam"
      },
      {
        text: "Harassment",
        value: "Harassment"
      },
      {
        text: "Other",
        value: "Other"
      }
      ]);
      if (reportReason) {
        this.userService.reportUser(this.content.userId!, reportReason);
      }
    }
  }

  async blockUser(){
    if (this.content && this.content.userId){
      this.userService.blockUser(this.content.userId);
    }
  }

  async presentPopover(event: Event) {
    const props: PopoverButtons = [
      {
        id: 0, text: "Report content", color: "danger", onClick: () => {
          popover.dismiss();
          this.reportContent()
        }
      },
      {
        id: 1, text: "Report user", onClick: () => {
          popover.dismiss();
          this.reportUser()  
        }
      }
    ];
    if (this.content && this.content.userId && this.content.userId != this.userService.userProfile.value.id){
      props.push({
        id: 2, text: "Block user", onClick: async () => {
          popover.dismiss();
          if (await this.confirm.confirm("Are you sure ?", "", "You won't be able to see any content from this user anymore.")){
            this.blockUser();
          }
        }
      })
    }
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      componentProps: {
        buttons: props,
      },
      event,
    });
    await popover.present();
  }

  getCaptionContainerStyle() {
    if (this.platform.is('desktop')) {
      return {
        gap: "1em",
      }
    } else {
      return {
        gridColumn: "1/4",
        flexDirection: "column",
        gap: "0",
      }
    }
  }

  getTitleContainerStyle() {
    if (!this.platform.is('desktop')) {
      return {
        gridColumn: "1/4",
        alignItems: "center",
        paddingLeft: "5rem",
        paddingRight: "1rem",
      }
    }

    return
  }

  shareBtnStyle() {
    if (this.platform.is('desktop')) {
      return {
        display: "flex",
        justifyContent: "center",
      };
    }
    return;
  }

  getMetadatasStyle() {
    if (this.platform.is('desktop')) {
      return {
        display: 'flex',
        alignItems: 'flex-start',
        alignSelf: 'flex-end',
        gridRow: '2 / 3',
        gridColumn: '3 / 4',
        paddingLeft: '1.5em',
      }
    }
    else {
      return {
        display: 'flex',
        alignSelf: 'flex-end',
        gridRow: "2 / 4",
        gridColumn: "3 / 4",
      }
    }
  }

  getContentStyle() {
    if (this.platform.is('desktop')) {
      return {
        width: 'auto',
        height: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
        zIndex: 1,
      }
    }
    else {
      return {
        position: 'absolute',
        width: '100%',
        height: 'auto',
      }
    }
  }

  getContentContainerStyle() {
    if (!this.platform.is("desktop")) {
      return {
        height: 'auto',
        gridRow: "1 / 4",
        gridColumn: "1 / 4",
        borderRadius: "0",
      }
    }

    return
  }

  async handleLinkClick(event: MouseEvent | TouchEvent) {
    if (event.target instanceof HTMLAnchorElement) {
      if (!event.target.href.startsWith(environment.appUrl)) {
        event.preventDefault();
        if (await this.confirm.confirm("You are leaving Overlie", "",
          "You're going to open a link outside of Overlie, are you sure you want to continue ?")) {
          window.open(event.target.href, "_blank");
        }
      }
      else if (this.platform.is("desktop")) {
        window.open(event.target.href, "_self")
      }
    }
  }
}