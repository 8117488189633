<div id="player" #player (mouseenter)="MouseEnter()" (mouseleave)="MouseLeave()" (fullscreenchange)="FullScreenChange($event)">
  <video id='video'
    #video [autoplay]="autoPlay"
    [preload]="preload"
    (click)="PlayPause()" 
    (timeupdate)="UpdateProgressBar()"
    (dblclick)="LikePost()">
    <source [src]="sourceUrl" type="video/mp4">
  </video>
  @if (_isFullScreen)
  {
    <div id="fullscreen-overlay" #fullscreenOverlay>
      <ion-button id="back-button" (click)="BackButtonClicked()">
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button>
      <ion-button id="volume-button" (click)="MuteUnmute()">
          <ion-icon [name]="_isMuted ? 'volume-mute' : 'volume-high'"></ion-icon>
      </ion-button>
      <canvas id='video-fullscreen'
        #videoFullscreen 
        (click)="PlayPause()" 
        (timeupdate)="UpdateProgressBar()"
        (dblclick)="LikePost()">
      </canvas>
    </div>
  }
  @if (this._firstPlayButtonActive)
  {
    <ion-button #playIconLayout id="play-button" (click)="FirstPlay()">
      <ion-icon id="main-play-icon" name="play-circle-outline"></ion-icon>
    </ion-button>
  }
  <div id="controls" #controls>
    <progress id='progress-bar' min='0' max='100' value='1' #progressBar>0% played</progress>
    @if (!_isFullScreen)
    {
      <div id="buttons" #buttons>
        <ion-button id="playPause" (click)="PlayPause()">
            <ion-icon [name]="_isPlaying ? 'pause' : 'play'"></ion-icon>
        </ion-button>
        <ion-button id="audio" (click)="MuteUnmute()">
            <ion-icon [name]="_isMuted ? 'volume-mute' : 'volume-high'"></ion-icon>
        </ion-button>
        <ion-button id="fullscreen" (click)="SwitchDisplay()">
          <ion-icon name="square-outline"></ion-icon>
        </ion-button>
      </div>
    }
  </div>
</div>

