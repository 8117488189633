import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-lifetimelabel',
  templateUrl: './lifetimelabel.component.html',
  styleUrls: ['./lifetimelabel.component.scss'],
})
export class LifetimelabelComponent  implements OnInit {

  @Input() postDate: number | undefined
  @Input() overbucks: number | undefined
  @Input() iconSize: number | undefined
  @Input() fontSize: number | undefined

  constructor() { }

  ngOnInit() {}

}
