<div id="main-container">
  <div id="poll-general-infos">
    <input #colorPicker id="color-picker"  type="color" [value]="colorValue" (input)="updateColorValue()">
    <ion-textarea #titleInput id="title-input" class="input" maxlength="100" placeholder="Enter poll title" [autoGrow]="true" [counter]="false" (keyup.enter)="confirmChoice()" ></ion-textarea>
  </div>
  
  @for(choice of choices; track $index)
  {
    <div class="choice">
      <div class="choice-name">{{ choice }}</div>
      <ion-button class="removeChoice" (click)="removeChoice($index)">
        <img src="assets/icon/close.svg" alt="Remove choice">
      </ion-button>
    </div>
  }
  @if (choices && choices.length < 4)
  {
    <ion-input #choiceTitle id="choice-input" class="input" maxlength="30" [placeholder]="'Answer ' + (choices.length + 1)" [counter]="true" (keyup.enter)="confirmChoice()" ></ion-input>
  }
  <app-simple-button id="confirm" [disabled]="choices.length < 2 || !titleInput.value || titleInput.value.length === 0"  text="Confirm" (clicked)="createPoll()"></app-simple-button>
</div>
