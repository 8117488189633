import { Injectable } from '@angular/core';
import { LocalNotificationDescriptor, LocalNotifications } from '@capacitor/local-notifications'
import { OnboardingService } from '../views/onboarding/services/onboarding.service';
import { first } from 'rxjs';
import { environment } from 'app/src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocalNotificationsService {

  private scheduledTileNotifs: LocalNotificationDescriptor[] = [];
  private scheduledTileBoostNotifs: LocalNotificationDescriptor[] = [];

  constructor(private onboarding: OnboardingService)
  {
  }

  async scheduleTileNotification()
  {
    if ((await LocalNotifications.checkPermissions()).display == 'granted')
    {
      if (this.scheduledTileNotifs.length > 0) LocalNotifications.cancel({ notifications: this.scheduledTileNotifs })
      let res = await LocalNotifications.schedule({ notifications: [
        {
          id: 0,
          title: "Tile available !",
          body: "Go put a tile now !",
          schedule: { repeats: false, at: new Date(Date.now() + environment.tileNotifDelay ) }
        }
      ]})
      this.scheduledTileNotifs = res.notifications ?? [];
    }
  }

  async scheduleTileBoostNotification()
  {
    if ((await LocalNotifications.checkPermissions()).display == 'granted')
    {
      if (this.scheduledTileBoostNotifs.length > 0) LocalNotifications.cancel({ notifications: this.scheduledTileBoostNotifs })
      let res = await LocalNotifications.schedule({ notifications: [
        {
          id: 0,
          title: "Tile boost available !",
          body: "You can now put tiles without any delay !",
          schedule: { repeats: false, at: new Date(Date.now() + environment.tileBoostDelay ) }
        }
      ]})
      this.scheduledTileBoostNotifs = res.notifications ?? [];
    }
  }
}
