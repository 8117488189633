<div id="main">
    <div class="layout">
        <div id="title-container" [ngStyle]="getTitleContainerStyle()">
            <div class="title-container-elements" [ngStyle]="getTitleContainerElementStyle()">
                <ion-icon name="people-outline" size="large"></ion-icon>
                @if(communityName) {
                <p id="community-name">{{communityName}}</p>

                @if (showJoinCommunityButton) {
                <app-action-button id="JoinCommunityButton" class="action-button" text="Join" type="tertiary"
                    (click)="JoinCommunity()"> </app-action-button>
                } @else {
                <app-action-button id="LeaveCommunitButton" class="action-button" text="Leave" type="tertiary"
                    (click)="LeaveCommunity()"></app-action-button>
                }
                }
            </div>
        </div>
        <div id="content-container" [ngStyle]="getContentContainerStyle()">

            @if (content) {
            @switch (content.type) {
            @case ("image") {
            <img id="content-background" [src]="payloadUrl">
            <img [src]="payloadUrl" [ngStyle]="getContentStyle()">
            }
            @case ("video") {
            <video id="content-background" [src]="payloadUrl" [autoplay]="autoPlay" preload="none"></video>
            <app-video #video [sourceUrl]="payloadUrl" [autoPlay]="autoPlay" preload="none" [ngStyle]="getContentStyle()"></app-video>
            }
            @case ("GIF") {
            <img id="content-background" [src]="content.gifUrl">
            <ion-img [src]="content.gifUrl" [ngStyle]="getContentStyle()"></ion-img>
            }
            @case ("text") {
            <p>{{content.text}}</p>
            }
            @default {
            <p>No content here</p>
            }
            }
            }
            @else {
            <ion-spinner name="crescent"></ion-spinner>
            }
        </div>

        @if (content && content.type) {
        <div id="metadatas" [ngStyle]="getMetadatasStyle()">
            <div class="gap-m">
                <app-overbucks [amount]="content.starsDonated" [iconSize]="iconSize" [fontSize]="fontSize"
                    [id]="content.id" [contentId]="content.contentId"></app-overbucks>
                <app-lifetimelabel [iconSize]="iconSize" [fontSize]="fontSize" [postDate]="content.postDate"
                    [overbucks]="(content.starsDonated || 0) + (content.starsInvested! || 0)"></app-lifetimelabel>
                <app-share-button [authorUsername]="userNameAuthor" [description]="content.text" [url]="url" [ngStyle]="shareBtnStyle()"
                    [iconSize]="iconSize"></app-share-button>
            </div>
        </div>
        }

        @if (content && content.type !== 'text') {
        <div id="caption-container" [ngStyle]="getCaptionContainerStyle()">
            <div id="username-container">
                <ion-icon name="person-circle-outline" size="large"></ion-icon>
                <p id="caption">{{userNameAuthor}}</p>
            </div>
            <p id="description">{{content.text}}</p>
        </div>
        }
    </div>
</div>