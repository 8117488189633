import { Injectable } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { Badge } from '@overlie/types';
import { BadgeMessageComponent } from '../components/utils/toast/badge-message/badge-message.component';
import { ModalController } from '@ionic/angular';
import { CommunityInvitationModalComponent } from '../views/map-view/community-overlay/community-invitation-modal/community-invitation-modal.component';
import { map, Observable, tap } from 'rxjs';
import { StarsMessageComponent } from '../components/utils/toast/stars-message/stars-message.component';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private toast: ToastrService, private modalController: ModalController) { }

  private _dailyRewardToast: ActiveToast<StarsMessageComponent> | undefined;
  
  public success(title: string, message: string) {
    this.toast.success(message, title);
  }

  public error(title: string, message: string) {
    if (!message) {
      message = "An error occured";
    }
    this.toast.error(message, title);
  }

  public warning(title: string, message: string) {
    this.toast.warning(message, title);
  }

  public clickable(title: string, message: string): Observable<void> {
    const toast = this.toast.info(message, title, {
      tapToDismiss: true,
      disableTimeOut: true,
    });
    return toast.onTap;
  }

  public announceBadge(badge: Badge) {
    this.toast.info(badge.message, badge.name, {
      toastComponent: BadgeMessageComponent,
      payload: { badge: badge }
    })
  }

  public dailyReward(amount: number): Observable<{ x: number, y: number }> | void {
    if (this._dailyRewardToast) return;
    this._dailyRewardToast = this.toast.info("Tap to receive your daily reward!", "Daily Reward", {
      toastComponent: StarsMessageComponent,
      payload: { amount },
      tapToDismiss: false,
      disableTimeOut: true,
    }) as ActiveToast<StarsMessageComponent>;

    return this._dailyRewardToast.onAction.pipe(
      map((e: MouseEvent) => { return { x: e.clientX, y: e.clientY } }),
      tap(() => this._dailyRewardToast = undefined)
    );
  }

  public showInvitationQRCode(url: string) {
    this.modalController.create({
      component: CommunityInvitationModalComponent,
      componentProps: { url: url }
    }).then(modal => modal.present());
  }
}
