import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable, combineLatest, map, tap } from 'rxjs';
import { UserService } from '../data/user.service';

export const AuthGuard: CanActivateFn = (route, state): Observable<boolean> => {
  let userService = inject(UserService);
  let router = inject(Router);
  return combineLatest([userService.isAuthenticated(), userService.isUserOnboarded()]).pipe(tap(([isAuthenticated, isOnboarded]: [boolean, boolean]) => {
    if (!isAuthenticated) {
      router.navigate(['/signin']);
    }
    if (!isOnboarded) {
      router.navigate(['/onboarding']);
    }
  }),
    map(([isAuthenticated, isOnboarded]: [boolean, boolean]) => isAuthenticated && isOnboarded));
};

export const AdminGuard: CanActivateFn = (route, state): Observable<boolean> => {
  let userService = inject(UserService);
  let router = inject(Router);
  return userService.isAdmin().pipe(tap((isAdmin) => {
    if (!isAdmin) {
      router.navigate(['/explore']);
    }
  }));
};

export const UnauthGuard: CanActivateFn = (route, state): Observable<boolean> => {
  let userService = inject(UserService);
  let router = inject(Router);
  return userService.isAuthenticated().pipe(tap((isAuthenticated) => {
    if (isAuthenticated) {
      router.navigate(['/explore']);
    }
  }), map((isAuthenticated) => !isAuthenticated));
};

export const NotOnboardedGuard: CanActivateFn = (route, state): Observable<boolean> => {
  let userService = inject(UserService);
  let router = inject(Router);
  return combineLatest([userService.isAuthenticated(), userService.isUserOnboarded()]).pipe(
    tap(([isAuthenticated, isOnboarded]) => {
      if (!isAuthenticated) {
        router.navigate(['/signin']);
      }
      if (isOnboarded) {
        router.navigate(['/explore']);
      }
    }),
    map(([isAuthenticated, isOnboarded]: [boolean, boolean]) => isAuthenticated && !isOnboarded));
};
