import { Component, input, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Poll, PollChoice } from '@overlie/types';
import { combineLatest, first, map, Observable, startWith, Subscription, takeWhile } from 'rxjs';
import { UserService } from '../../../data/user.service';
import { environment } from 'app/src/environments/environment';
import { PollChoiceComponent } from '../poll-choice/poll-choice.component';
import { CommunityService } from 'app/src/app/data/community.service';

@Component({
  selector: 'app-poll',
  templateUrl: './poll.component.html',
  styleUrls: ['./poll.component.scss'],
})
export class PollComponent implements OnInit, OnDestroy {

  @Input() poll?: Poll;
  @Input() canModerate: boolean = false;
  
  @ViewChildren('choice') pollChoicesComps?: QueryList<PollChoiceComponent>

  expanded?: boolean;
  sortedChoices?: PollChoice[];
  voteDelayExpired?: boolean;
  selectedIndex?: number = -1;
  remainingTime?: number;
  answered$?: Observable<boolean>;
  answered?: boolean;
  answeredSub?: Subscription;

  constructor(private userService: UserService, private communityService: CommunityService) { }

  ngOnInit()
  {
    if (!this.poll) return;

    this.answered$ = combineLatest([this.userService.userPollChoices.pipe(first()), this.userService.newPollChoice.pipe(startWith(null))]).pipe(map(([userPollChoices, newPollChoice]) =>
      {
        if (!this.poll) return false;

        if (newPollChoice == null)
        {
          let matchingChoice = userPollChoices.find(c => c.pollId == this.poll?.id);
          if (!matchingChoice) return false;

          this.selectedIndex = matchingChoice.index
          return true;
        }

        if (newPollChoice.pollId != this.poll.id) return false;

        let choice = this.poll.choices.find(c => c.index == newPollChoice.index);
        if (!choice) return false;

        choice.score += 1;
        this.selectedIndex = choice.index;
        this.poll.nbVoters += 1;
        this.sortedChoices = this.poll.choices.sort((c1, c2) => c2.score - c1.score);

        return true
      }), takeWhile(res => !res, true))

    this.sortedChoices = this.poll.choices.sort((c1, c2) => c2.score - c1.score);
    this.remainingTime = this.poll.postDate + environment.pollVoteDelay - Date.now();  
    this.voteDelayExpired = this.remainingTime < 0;

    this.answeredSub = this.answered$.subscribe(answer => this.answered = answer);
  }

  ngOnDestroy(): void {
    this.answeredSub?.unsubscribe();
  }

  expandCollapse()
  {
    this.expanded = !this.expanded;
  }

  deletePoll(event: Event){
    event.stopPropagation();
    if (!this.poll) return;
    this.communityService.deletePoll(this.poll.communityId, this.poll.id);
  }
}
