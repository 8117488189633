import { CommunityInfos, FillFeature, HeightFeature, ServerToClientMessage, SponsorFeature, TileData, TileGeometry, WorldPoint } from "@overlie/types";
import { environment } from "app/src/environments/environment";
import * as turf from '@turf/turf';

export module MapUtils {
  export const TILES_OFFSET_LONG = 0.0008993203637235325;
  export const TILES_OFFSET_LAT = 0.0008993203637217562;
  export const APPROXIMATE_CENTER_OFFSET: number = 0.00044966018186226905;
  export const DIAG_DISTANCE: number = 0.001271849;
  export const TOLERANCE = 1e-6;

  export function arePointsEquals(worldPoint1: WorldPoint, worldPoint2: WorldPoint) {
    return Math.abs(worldPoint1[0] - worldPoint2[0]) < TOLERANCE
      && Math.abs(worldPoint1[1] - worldPoint2[1]) < TOLERANCE;
  }

  export function areCoordEquals(n1: number, n2: number) {
    return Math.abs(n1 - n2) < TOLERANCE;
  }

  export function distance(wp1: WorldPoint, wp2: WorldPoint)
  {
    return turf.distance(wp1, wp2);
  }

  export function areNeighbors(wp1: WorldPoint, wp2: WorldPoint)
  {
    return areCoordEquals(wp1[0], wp2[0]) && Math.abs(Math.abs(wp1[1] - wp2[1]) - TILES_OFFSET_LAT) < TOLERANCE
    || areCoordEquals(wp1[1], wp2[1]) && Math.abs(Math.abs(wp1[0] - wp2[0]) - TILES_OFFSET_LONG) < TOLERANCE;
  }

  export function areNeighborsDiag(wp1: WorldPoint, wp2: WorldPoint)
  {
    return Math.abs(distance(wp1, wp2) - DIAG_DISTANCE) < TOLERANCE;
  }

  export function worldPointToUniqueString(wp: WorldPoint): string
  {
    return `${wp[0]},${wp[1]}`;
  }

  export function buildNewHeightFeature(datas: Omit<ServerToClientMessage.TileAddMessage, 'type'>, community: CommunityInfos): HeightFeature {
    let color = 'none'
    if (community) {
      color = community.color;
    }
    return {
      type: "Feature",
      properties: {
        height: calculateTileHeight(datas.postDate, datas.starsDonated, datas.starsInvested),
        base_height: 0,
        color: color,
        communityId: community.id,
        sponsored: datas.starsInvested > 0
      },
      geometry: {
        coordinates: [[
          datas.position,
          [datas.position[0], datas.position[1] + TILES_OFFSET_LAT],
          [datas.position[0] + TILES_OFFSET_LONG, datas.position[1] + TILES_OFFSET_LAT],
          [datas.position[0] + TILES_OFFSET_LONG, datas.position[1]],
          datas.position
        ]],
        type: "Polygon"
      },
      id: datas.position
    }
  }

  export function buildNewFillFeature(datas: Omit<ServerToClientMessage.TileAddMessage, 'type'>, community: CommunityInfos): FillFeature {
    let color = 'none'
    let position = datas.position
    if (community) {
      color = community.color;
    }
    return {
      type: "Feature",
      properties: {
        color: color,
        height: 0,
        base_height: 0,
        communityId: community.id,
        sponsored: datas.starsInvested > 0
      },
      geometry: {
        coordinates: [
          position,
          [position[0], position[1] + TILES_OFFSET_LAT],
          [position[0] + TILES_OFFSET_LONG, position[1] + TILES_OFFSET_LAT],
          [position[0] + TILES_OFFSET_LONG, position[1]],
          position
        ],
        type: "LineString"
      },
      id: position
    }
  }

  export function buildNewSponsorFeature(heightFeature: HeightFeature): SponsorFeature
  {
    let baseGeometry = heightFeature.geometry.coordinates[0];
    let point0, point1, point2, point3, point4, point5, point6, point7, point8, point9: WorldPoint;

    let diviseur = 20;

    point0 = [baseGeometry[0][0], baseGeometry[0][1]]
    point1 = [baseGeometry[1][0], baseGeometry[1][1]]
    point2 = [baseGeometry[2][0], baseGeometry[2][1]]
    point3 = [baseGeometry[3][0], baseGeometry[3][1]]
    point4 = [baseGeometry[0][0] + (TILES_OFFSET_LONG / diviseur), baseGeometry[0][1] + (TILES_OFFSET_LAT / diviseur)]
    point5 = [baseGeometry[1][0] + (TILES_OFFSET_LONG / diviseur), baseGeometry[1][1] - (TILES_OFFSET_LAT / diviseur)]
    point6 = [baseGeometry[2][0] - (TILES_OFFSET_LONG / diviseur), baseGeometry[2][1] - (TILES_OFFSET_LAT / diviseur)]
    point7 = [baseGeometry[3][0] - (TILES_OFFSET_LONG / diviseur), baseGeometry[3][1] + (TILES_OFFSET_LAT / diviseur)]
    
    return {
      type: "Feature",
      properties: {
        color: '',
        height: heightFeature.properties.height + 0.2,
        base_height: 0,
        communityId: '',
        sponsored: true
      },
      geometry: {
        coordinates: [[
          point0 as WorldPoint,
          point1 as WorldPoint,
          point2 as WorldPoint,
          point3 as WorldPoint,
          point0 as WorldPoint
        ],
        [
          point4 as WorldPoint,
          point5 as WorldPoint,
          point6 as WorldPoint,
          point7 as WorldPoint,
          point4 as WorldPoint,
        ]],
        type: "Polygon"
      },
      id: heightFeature.id
    }
  }

  export function makeCoordLegal(worldPoint: WorldPoint): WorldPoint {
    let resWorldPoint: WorldPoint = [-200, -200]

    let factorX = worldPoint[0] / TILES_OFFSET_LONG
    let roundedFactorX = Math.round(factorX)
    resWorldPoint[0] = roundedFactorX * TILES_OFFSET_LONG

    let factorY = worldPoint[1] / TILES_OFFSET_LAT
    let roundedFactorY = Math.round(factorY)
    resWorldPoint[1] = roundedFactorY * TILES_OFFSET_LAT

    return resWorldPoint;
  }

  export function worldPointToTileGeometry(position: WorldPoint): TileGeometry {
    return [
      position,
      [position[0], position[1] + TILES_OFFSET_LAT],
      [position[0] + TILES_OFFSET_LONG, position[1] + TILES_OFFSET_LAT],
      [position[0] + TILES_OFFSET_LONG, position[1]],
      position
    ]
  }

  export function calculateTileHeight(postDate: number, starsDonated: number, starsInvested: number): number {
    return lifetimeToTileHeight(calculateLifetimeInHours(postDate, starsDonated, starsInvested))
  }

  export function lifetimeToTileHeight(lifetime: number) {
    return 0.5 * (lifetime ** 1.3)
  }

  export function calculateLifetimeInMS(postDate: number, starsDonated: number, starsInvested: number): number {
    return environment.defaultLifetimeInMS - (Date.now() - postDate) + ((starsDonated + starsInvested) * environment.starValueInMS)
  }

  export function calculateLifetimeInHours(postDate: number, starsDonated: number, starsInvested: number): number {
    return calculateLifetimeInMS(postDate, starsDonated, starsInvested) / (3600 * 1000)
  }

  export function getTileBounds(tile: TileGeometry): [WorldPoint, WorldPoint] {
    return [tile[0], tile[2]];
  }

  export function getTilesBounds(tiles: WorldPoint[]): [WorldPoint, WorldPoint] {
    let lats = tiles.map(tile => tile[0]);
    let longs = tiles.map(tile => tile[1]);
    let minX = Math.min(...lats);
    let minY = Math.min(...longs);
    let maxX = Math.max(...lats);
    let maxY = Math.max(...longs);
    return [[minX, minY], [maxX, maxY]];
  }

  export function getCommunitiesBounds(tilesData: TileData[], communities: CommunityInfos[]): [WorldPoint, WorldPoint] {
    let tiles = tilesData.filter(tile => communities.find(c => c.id == tile.communityId) != null).map(tile => tile.position);
    return MapUtils.getTilesBounds(tiles);
  }

  export function getCommunityBounds(tilesData: TileData[], communityId: string): [WorldPoint, WorldPoint] {
    let tiles = tilesData.filter(tile => tile.communityId == communityId).map(tile => tile.position);
    if (tiles.length == 1) {
      return [[ tiles[0][0], tiles[0][1] ],
              [ tiles[0][0] + TILES_OFFSET_LONG, tiles[0][1] + TILES_OFFSET_LAT ]];
    }
    return MapUtils.getTilesBounds(tiles);
  }

  export function getRandomCordinatesFromCommunity(tilesData: TileData[], communityId: string): WorldPoint {
    let tiles = tilesData.filter(tile => tile.communityId == communityId).map(tile => tile.position);
    let randIndex = Math.floor(Math.random() * tiles.length);
    return tiles[randIndex];
  }

  export function addOffset(point: WorldPoint): WorldPoint {
    return [point[0] + APPROXIMATE_CENTER_OFFSET, point[1] + APPROXIMATE_CENTER_OFFSET];
  }

  export function substractOffset(point: WorldPoint): WorldPoint {
    return [point[0] - APPROXIMATE_CENTER_OFFSET, point[1] - APPROXIMATE_CENTER_OFFSET];
  }
}