import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { CommunityService } from 'app/src/app/data/community.service';
import { InteractionsService } from 'app/src/app/data/interactions.service';
import { MapService } from 'app/src/app/data/map.service';
import { UserService } from 'app/src/app/data/user.service';
import { Observable, combineLatest, map, merge, of } from 'rxjs';

export enum TileAction {
  None = 0,
  Put = 1,
  Post = 2,
  View = 3,
  CreateCommunity = 4,
}

@Component({
  selector: 'app-map-overlay',
  templateUrl: './map-overlay.component.html',
  styleUrls: ['./map-overlay.component.scss'],
})
export class MapOverlayComponent  implements OnInit {
  isMobile: boolean = false;
  TileAction = TileAction;
  tileAction$?: Observable<TileAction>;
  timer$: Observable<number | null> = this.interactions.timer$;
  tileAvailable$: Observable<boolean> = this.interactions.tileAvailable$;
  waitingForSponsoredCommunity?: Observable<boolean>;
  constructor(private interactions: InteractionsService,
    private mapService: MapService,
    private userService: UserService,
    private communityService: CommunityService,
    private platform: Platform) { }

  ngOnInit() {
    this.isMobile = this.platform.is('mobile')
    this.waitingForSponsoredCommunity = merge(of(this.userService.userProfile.value.waitingForSponsoredCommunityCreation != undefined),
    this.communityService.sponsoredCommunityCreated.pipe(map(_ => false)))
    this.tileAction$ = combineLatest([this.mapService.selectedTile, this.mapService.mapData]).pipe(map(x => {
      let tile = x[0];
      let mapData = x[1];
      if (tile && mapData)
      {
        if (this.mapService.isTileEmpty(tile[0]))
        {
          if (this.mapService.isTileClickable(tile[0]))
          {
            return TileAction.Put;
          }
          else
          {
            return TileAction.CreateCommunity;
          }
        }
        else
        {
          if (this.mapService.isTileCanBePosted(tile[0]))
          {
            return TileAction.Post;
          }
          else
          {
            return TileAction.View;
          }
        }
      }

      return TileAction.None;
    }));
  }

  putTile() {
    this.interactions.sendSignal<boolean>(this.interactions.addTileButtonClicked, true);
  }

  createCommunity()
  {
    this.interactions.sendSignal<boolean>(this.interactions.createCommunityButtonClicked, true);
  }

  createSponsoredCommunity()
  {
    this.interactions.sendSignal<boolean>(this.interactions.createSponsoredCommunityButtonClicked, true);
  }

  viewTile() {
    this.mapService.seeContent();
  }
}
