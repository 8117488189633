<div id="profile-layout">
  <div id="profile-header">
    <div id="avatar">
      <!-- For the moment only community have custom avatar -->
      @if (user) {
      <app-avatar class="avatar user-avatar" [image]="'https://ui-avatars.com/api/?name='+user.username.replace('','+')" [type]="'user'"></app-avatar>
      }
    </div>
    <div id="profile-name">
      <h1 #name [ngClass]="{ 'editable': canEdit, 'edit-text': editNameMode }"
        [attr.contenteditable]="canEdit && editNameMode" (click)="enterEditNameMode()">{{ user?.username }}</h1>
      @if (canEdit && editNameMode) {
      <div class="confirm-cancel">
        <ion-button class="action-button" fill="clear" (click)="cancelEditName()">
          <ion-icon name="close-outline"></ion-icon>
        </ion-button>
        <ion-button class="action-button" fill="clear" (click)="editName()">
          <ion-icon name="checkmark-outline"></ion-icon>
        </ion-button>
      </div>
      }
    </div>
    @if (user) {
    <div id="badges">
      @for (badge of user.badges; track badge) {
      <app-badge [badgeId]="badge"></app-badge>
      }
    </div>
    }
  </div>
  @if (user && user.communities.length > 0) {
  <div id="user-communities">
    <h4>Communities</h4>

    @for (community of (userCommunities | async); track $index) {
    <div class="community-container" (click)="seeCommunity(community.id)">
      <app-avatar class="community-avatar" type="community" [image]="community.image"></app-avatar>
      <div>
        <div class="community-name">{{ community.name }}</div>
        <div class="community-role">{{ communityRoles[community.id] }}
          @switch (communityRoles[community.id]!) {
          @case ('Creator') {
          <img src="assets/icon/crown.svg" class="user-icon" alt="Creator" />
          }
          @case ('Administrator') {
          <ion-icon name="shield" class="user-icon" alt="Administrator"></ion-icon>
          }
          @case ('Moderator') {
          <ion-icon name="shield-half" class="user-icon" alt="Moderator"></ion-icon>
          }
          }
        </div>
      </div>
    </div>
    }
  </div>
  }
</div>