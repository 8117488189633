import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'app/src/environments/environment';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class ItemComponent  implements OnInit {

  apiURL?: string;
  buyColor?: string;
  
  @Input() productId?: string;
  @Input() name?: string;
  @Input() price?: number | 'custom';
  @Input() type?: string;

  constructor() { }

  ngOnInit()
  {
    this.apiURL = environment.apiUrl;
    this.buyColor = this.type == 'stars' ? '#58B2CE' : "#DCB253";
  }

}
