<div id="main">
    <div class="layout">
        <div id="title-container">
            <div class="fade title-container-elements">
                @if(community && community.name && focused) {
                <div id="community-name-container">
                    <app-avatar id="community-avatar" [type]="'community'" [image]="community.image" [backgroundColor]="community.color"></app-avatar>
                    <p id="community-name" class="text-shadow">{{community.name}}</p>
                </div>

                @if (showJoinCommunityButton && focused) {
                <app-action-button class="action-button" text="Join" type="tertiary" [fontSize]="1"
                    (click)="joinCommunity()"> </app-action-button>
                } @else if (!showJoinCommunityButton && focused) {
                <app-action-button class="action-button" text="Leave" type="tertiary" [fontSize]="1"
                    (click)="leaveCommunity()"></app-action-button>
                }
                }
            </div>
        </div>
        <div id="content-container">

            @if (content) {
            @switch (content.type) {
            @case ("image") {
            <img id="content-background" [src]="payloadUrl">
            <img class="content" [src]="payloadUrl">
            }
            @case ("video") {
            <video id="content-background" [src]="payloadUrl" [autoplay]="autoPlay" playsinline loop preload="none"
                [muted]="true"></video>
            <app-video #video class="content" [sourceUrl]="payloadUrl" [autoPlay]="autoPlay" preload="none"></app-video>
            }
            @case ("GIF") {
            <img id="content-background" [src]="content.gifUrl">
            <ion-img class="content" [src]="content.gifUrl"></ion-img>
            }
            @case ("text") {
            <div class="text-content text-shadow" [innerHTML]="content.text | parseUrls">
            </div>
            }
            @default {
            <p>No content here</p>
            }
            }
            }
            @else {
            <ion-spinner name="crescent"></ion-spinner>
            }
        </div>

        @if (content && content.type) {
        <div id="action-buttons">
            <div class="gap-m">
                <app-overbucks [amount]="content.starsDonated" [iconSize]="iconSize" [fontSize]="fontSize"
                    [shadow]="true" [id]="content.id" [contentId]="content.contentId"></app-overbucks>
                <app-lifetimelabel [iconSize]="iconSize" [fontSize]="fontSize" [protectionEndDate]="content.protectionEndDate"></app-lifetimelabel>
                <app-share-button [authorUsername]="authorUserame" [description]="content.text" [url]="url" [iconSize]="iconSize"></app-share-button>
                <ion-button class="menu-button" fill="clear" (click)="presentPopover($event)">
                    <ion-icon name="ellipsis-vertical"></ion-icon>
                </ion-button>
            </div>
        </div>
        }

        <div id="caption-container">
            @if (authorUserame && focused) {
            <div id="username-container" class="fade">
                <ion-icon class="prefix-icon" name="person-circle-outline"></ion-icon>
                <p id="caption" class="text-shadow">{{authorUserame}}</p>
                @if (content && content.postDate) {
                    <div id="post-date">
                      {{ content.postDate | date: 'dd/MM/yyyy' }}
                    </div>
                }
            </div>
            @if (content && content.type !== "text") {
            <p id="description" class="fade">{{content.text}}</p>
            }
            }
        </div>
    </div>
</div>