import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'starsQuantity'
})
export class StarsQuantityPipe implements PipeTransform {

  /**
   * Transform the value to a formatted string with spaces every 3 characters
   * Example: 1234567890 -> 1 234 567 890
   * @param value 
   * @returns 
   */
  transform(value: string | number): string {
    let stars = value.toString();
    let formatted = '';
    for (let i = 1; i <= stars.length; i++) {
      let mod3 = i % 3 === 0;
      formatted = `${mod3 ? ` ${stars[stars.length - i]}` : stars[stars.length - i]}${formatted}`;
    }
    return formatted;
  }

}
