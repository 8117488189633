import { Component, Input, OnInit } from '@angular/core';
import { Share } from '@capacitor/share';
import { Clipboard } from '@capacitor/clipboard';

@Component({
  selector: 'app-share-button',
  templateUrl: './share-button.component.html',
  styleUrls: ['./share-button.component.scss'],
})
export class ShareButtonComponent  implements OnInit {

  @Input() iconSize: number | undefined;
  @Input() authorUsername?: string;
  @Input() description?: string;
  @Input({required: true}) url!: string;
  canShare = true;
  constructor() { }

  async ngOnInit() {
    this.canShare = (await Share.canShare()).value;
  }

  async shareContent() {
    if (!this.canShare || !this.authorUsername) {
      this.copyLinkInClipboard();
      return;
    }
    await Share.share({
      title: `Check out this post from ${this.authorUsername || 'a deleted user'} on Overlie before it expires !`,
      dialogTitle: `Check out this post from ${this.authorUsername || 'a deleted user'} on Overlie before it expires !`,
      text: this.authorUsername && this.description ? `${this.authorUsername} - ${this.description}` : undefined,
      url: this.url,
    });
  }

  async copyLinkInClipboard(){
    await Clipboard.write({
      string: this.url
    });
  }

}
