<div id="layout">
  <p>Support us !</p>Do you like Overlie? Be among the first to get involved in its development!
  <p id="message"></p>
  <div class="type-container">
    @for(item of (donationItems | async | productSort); track $index)
    {
      @if(item.price.custom_unit_amount)
      {
        <app-item
          [productId]="item.product.id"
          [name]="item.product.name" 
          [price]="'custom'" 
          [type]="item.product.metadata['type']"></app-item>
      }
      @else
      {
        <app-item
          [productId]="item.product.id"
          [name]="item.product.name" 
          [price]="item.price.unit_amount ?? 0" 
          [type]="item.product.metadata['type']"></app-item>
      }
    }
  </div>
  <!-- <p>Stars</p>
  <div class="type-container">
    @for(item of (starsItems | async | productSort); track $index)
    {
      <app-item
      [productId]="item.product?.id"
      [name]="item.product?.name" 
      [price]="item.price?.unit_amount ?? 0" 
      [type]="item.product?.metadata!['type']! ?? 'none'"></app-item>
    }
  </div>
  <p>Sponsored communities</p>
  <div class="type-container">
    @for(item of (communitiesItems | async | productSort); track $index)
    {
      <app-item
      [productId]="item.product?.id"
      [name]="item.product?.name" 
      [price]="item.price?.unit_amount ?? 0" 
      [type]="item.product?.metadata!['type']! ?? 'none'"></app-item>
    }
  </div> -->
</div>