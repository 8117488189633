import { Component, OnInit } from '@angular/core';
import { CommunityService } from 'app/src/app/data/community.service';
import { BehaviorSubject, combineLatest, first, firstValueFrom, map, merge, Observable, switchMap } from 'rxjs';
import { RankingInfos } from 'types/build';

@Component({
  selector: 'app-communities-ranking',
  templateUrl: './communities-ranking.component.html',
  styleUrls: ['./communities-ranking.component.scss'],
})
export class CommunitiesRankingComponent  implements OnInit {

  maxCount = 10;
  currentRange$: BehaviorSubject<[number, number]> = new BehaviorSubject([0, this.maxCount - 1]);
  ranking$: Observable<RankingInfos[]> = this.currentRange$.pipe(switchMap(async range =>
    {
      return await firstValueFrom(this.communityService.getCommunitiesRanking(range[0], range[1]));
    }));
  nbCommunities$: Observable<number> = this.communityService.communities.pipe(map(c => c.size))
  canGoNext$: Observable<boolean> = combineLatest([this.currentRange$, this.ranking$, this.nbCommunities$]).pipe(map(params =>{
    // First bound + ranking.length == nbCommunities, if its true
    return params[0][0] + params[1].length < params[2];
  }))

  constructor(private communityService: CommunityService) { }

  ngOnInit()
  {
  }

  previous()
  {
    this.currentRange$.next(this.currentRange$.value.map(x => x - this.maxCount) as [number, number])
  }

  next()
  {
    this.currentRange$.next(this.currentRange$.value.map(x => x + this.maxCount) as [number, number])
  }
}
