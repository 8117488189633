import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Subscription, filter, first } from 'rxjs';
import { ContentMetadata, ContentType, MediaType } from '@overlie/types';
import { ContentsService } from 'app/src/app/data/contents.service';
import { CommunityService } from 'app/src/app/data/community.service';
import { VideoComponent } from '../video/video.component';
import { Platform } from '@ionic/angular'
import { UserService } from 'app/src/app/data/user.service';

@Component({
  selector: 'app-media-content',
  templateUrl: './media-content.component.html',
  styleUrls: ['./media-content.component.scss']
})
export class MediaContentComponent  implements OnInit, OnChanges
{
  contentSubscription: Subscription | undefined;

  @ViewChild("layout") layout!: ElementRef;
  @ViewChild("video") video!: VideoComponent;

  @Input({ required: true }) content!: ContentMetadata | null;
  @Input() autoPlay?: boolean;

  iconSize: number = 35;
  fontSize: number = 20;
  communityName?: string;
  userNameAuthor?: string;

  showJoinCommunityButton: boolean = false;

  payloadUrl?: string;
  url: string = window.location.href;

  constructor(private contentService: ContentsService,
              private communityService: CommunityService,
              private userService: UserService,
              private platform: Platform) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.url = window.location.href;
    this.content = changes['content'].currentValue;
    if (this.content) {
      if (this.content.type && this.content.id && (this.content.type === ContentType.Image || this.content.type === ContentType.Video)) {
        this.payloadUrl = this.contentService.getContentPayloadURL(this.content.id);
      }
      if (this.content.userId) {
        this.userService.getUserProfile(this.content.userId).pipe(first()).subscribe(user => {
          this.userNameAuthor = user?.username;
        })
      }
      if (this.content.communityId) {
        this.communityService.getCommunityInfo(this.content.communityId).pipe(first()).subscribe(community => {
          this.communityName = community?.name;
        });
      }
      this.contentSubscription?.unsubscribe();
      this.contentSubscription = this.contentService.contentSponsorised.pipe(filter(obj => obj.id == this.content?.id)).subscribe(obj => {
        if (this.content?.starsDonated !== undefined)
        {
          this.content.starsDonated += obj.amount
        }
      });
      
      this.userService.getSelfProfile().pipe(first()).subscribe(profile => {
        if (profile.communities == undefined || profile.communities.length == 0) {
          this.showJoinCommunityButton = true;
          return;
        }
        
        profile.communities?.forEach((community) => {
          if (community == this.content?.communityId) {
            this.showJoinCommunityButton = false;
            return;
          }
        });
        if (this.content?.userId) {
          this.userService.getUserProfile(this.content.userId).pipe(first()).subscribe(user => {
            this.userNameAuthor = user?.username;
          })
        }
        if (this.content?.communityId) {
          this.communityService.getCommunityInfo(this.content.communityId).pipe(first()).subscribe(community => {
            this.communityName = community?.name;
          });
        }
      });
    }
  }

  JoinCommunity(): void {
    if (this.content?.communityId) {
      this.communityService.joinCommunity(this.content?.communityId);
      this.showJoinCommunityButton = false;
    }
  }

  LeaveCommunity(): void {
    if (this.content?.communityId) {
      this.communityService.leaveCommunity(this.content?.communityId);
      this.showJoinCommunityButton = true;
    }
  }

  getCaptionContainerStyle()
  {
    if (this.platform.is('desktop'))
    {
      return {
        gap: "1em",
      }
    } else
    {
      return {
        gridColumn: "1/4",
        flexDirection: "column",
        marginLeft: "2rem",
        gap: "0",
      }
    }
  }

  getTitleContainerStyle() {
    if (!this.platform.is('desktop'))
    {
      return {
        gridColumn: "1/4",
        alignItems: "flex-start",
        marginTop: "2rem",
        marginRight: "2rem",
      }
    }

    return
  }

  getTitleContainerElementStyle() {
    if (!this.platform.is('desktop'))
    {
      return {
        justifyContent: "flex-end",
      }
    }

    return
  }

  shareBtnStyle() {
    if (this.platform.is('desktop'))
    {
      return {
        display: "flex",
        justifyContent: "center",
      }
    }
    
    return
  }

  getMetadatasStyle()
  {
    if (this.platform.is('desktop'))
    {
      return {
        display: 'flex',
        alignItems: 'flex-start',
        alignSelf: 'flex-end',
        gridRow: '2 / 3',
        gridColumn: '3 / 4',
        paddingLeft: '1.5em',
      }
    }
    else
    {
      return {
        display: 'flex',
        alignSelf: 'flex-end',
        gridRow: "2 / 4",
        gridColumn: "3 / 4",
        marginBottom: '2em',
      }
    }
  }

  getContentStyle()
  {
    if (this.platform.is('desktop'))
    {
      return {
        width: 'auto',
        height: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
        zIndex: 1,
      }
    }
    else
    {
      return {
        position: 'absolute',
        width: '100%',
        height: 'auto',
      }
    }
  }

  getContentContainerStyle()
  {
    if (!this.platform.is("desktop"))
    {
      return {
        height: 'auto',
        gridRow: "1 / 4",
        gridColumn: "1 / 4",
        borderRadius: "0",
        zIndex: -1,
      }
    }
    
    return
  }

  getLayoutStyle()
  {
    if (this.platform.is("desktop"))
    {
      return {
        position: 'relative',
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }
    }
    else
    {
      return {
        position: 'relative',
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }
    }
  }

  getCaptionStyle()
  {
    if (this.platform.is('desktop'))
    {
        return {
          display: 'block',
          position: 'relative',
          height: '100vh',
          width: '100%',
          top: '10vh',
          left: '10%'
      }
    }
    else
    {
      return {
        position: 'absolute',
        bottom: '5%',
        marginLeft: '20px',
        marginRight: '20px'
      }
    }
  }
}