<div id="main-actions-container">
    <app-action-button id="overlie-add-button" class="interactable main-action"
        [ngStyle]="{ 'display' : canPut || canPost  ? 'block' : 'none' }" (click)="putTile()"
        [text]="canPost ? 'Post' : (tileAvailable$ | async) ? 'Overlie' : (timer$ | async | timer)"
        [disabled]="!canPost && (tileAvailable$ | async) === false" type="primary" shadow="true">
        <ion-icon name="add"></ion-icon>
    </app-action-button>
    <app-action-button id="overlie-create-button" class="interactable main-action"
        [ngStyle]="{ 'display' : canCreateCom ? 'block' : 'none' }" (click)="createCommunity()" text="Create community"
        type="secondary" shadow="true">
        <p>Create community</p>
    </app-action-button>
    <app-action-button id="overlie-view-button" class="interactable main-action"
        [ngStyle]="{ 'display' : canView ? 'block' : 'none' }" (click)="viewTile()" text="View" type="secondary"
        shadow="true">
        <ion-icon name="view"></ion-icon>
    </app-action-button>
</div>
<div id="header-actions">
    <app-action-button [ngStyle]="{ 'display': (waitingForSponsoredCommunity | async) ? 'block' : 'none'}"
        id="overlie-create-sponsored-button" class="interactable main-action" (click)="createSponsoredCommunity()"
        type="primary" text="Create your sponsored community now !">
    </app-action-button>
    <app-communities-ranking id="ranking" class="interactable"
        [ngStyle]="{ 'width': isMobile ? '50%' : '20%' }"></app-communities-ranking>
</div>