import { Injectable } from '@angular/core';
import { CommunityInfos, Poll, RankingInfos, StandardError, UserMinimalInfos } from '@overlie/types';
import { BehaviorSubject, Observable, Subject, catchError, filter, first, firstValueFrom, lastValueFrom, map, of, tap } from 'rxjs';
import { environment } from 'app/src/environments/environment';
import { OverlieHttpService } from './overliehttp.service';
import { CommunityCreatePOST } from 'types/src';
import { AlertService } from '../utils/alert.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})

export class CommunityService {

  private _myCommunities: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private _myBookmarks: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private _communities: BehaviorSubject<Map<string, CommunityInfos>> = new BehaviorSubject(new Map());
  private _pollsByCommunity: BehaviorSubject<Map<string, Poll[]>> = new BehaviorSubject(new Map());
  private _sponsoredCommunityCreated: Subject<void> = new Subject<void>()

  public get myCommunities() {
    return this._myCommunities;
  }

  public get myCommunities$(): Observable<string[]> {
    return this._myCommunities.asObservable();
  }

  public get myBookmarks() {
    return this._myBookmarks;
  }

  public get myBookmarks$(): Observable<string[]> {
    return this._myBookmarks.asObservable();
  }

  public get communities() {
    return this._communities;
  }

  public get pollsByCommunity() {
    return this._pollsByCommunity;
  }

  public get sponsoredCommunityCreated(){
    return this._sponsoredCommunityCreated;
  }

  constructor(private http: OverlieHttpService,
    private alert: AlertService,
    private userService: UserService
  )
  { 
    this.userService.userProfile.pipe(map(profile => profile.communities)).subscribe(this._myCommunities);
    this.userService.userProfile.pipe(map(profile => profile.bookmarks)).subscribe(this._myBookmarks);
  }

  public initCommunities(communities: CommunityInfos[]) {
    let map = new Map<string, CommunityInfos>();
    for (let c of communities) {
      map.set(c.id, c);
    }
    this._communities.next(map);
  }

  public setUserCommunities(communities: string[], bookmarks: string[]) {
    let pollsByCommunity = this._pollsByCommunity.value
    this._myCommunities.value.forEach(community => pollsByCommunity.set(community, []))
  }

  public getCommunityName(id: string): string | undefined {
    return this.communities.value.get(id)?.name;
  }

  public getCommunityColor(id: string): string | undefined {
    return this.communities.value.get(id)?.color;
  }

  public fetchCommunity(id: string): Observable<CommunityInfos | undefined> {
    return this.http.get<CommunityInfos>(`${environment.apiUrl}/infos/communities/${id}`).pipe(
      catchError(() => {
        return of(undefined);
      })
    );
  }

  public addNewCommunity(community: CommunityInfos, created: boolean = false) {
    this._communities.next(this._communities.value.set(community.id, community));
    if (community.sponsored)
    {
      this._sponsoredCommunityCreated.next()
    }
  }

  public updateCommunity(community: CommunityInfos) {
    this._communities.next(this._communities.value.set(community.id, community));
  }

  public joinCommunity(communityId: string) {
    this.http.post<CommunityInfos>(`${environment.apiUrl}/infos/join-community`, { communityId: communityId })
      .pipe(catchError((e) => {
        return of(new StandardError(e.error))
      })).subscribe(async v => {
        if (v instanceof StandardError) {
          this.alert.error("Couldn't join community", v.errorMsg)
        }
        else if (v) {
          this.userService.updateUserCommunities(this.userService.userProfile.value.communities.concat([communityId]));
          this.alert.success("Welcome", `You have joined the community ${v.name}`);

          await firstValueFrom(this._communities.pipe(filter(c => c.has(communityId))));
          this._communities.value.get(communityId)!.nbUsers++;
        }
      })
  }

  public leaveCommunity(communityId: string) {
    this.http.post<CommunityInfos>(`${environment.apiUrl}/infos/quit-community`, { communityId: communityId })
      .pipe(catchError((e) => {
        return of(new StandardError(e.error))
      })).subscribe(async v => {
        if (v instanceof StandardError) {
          this.alert.error("Couldn't leave community", v.errorMsg)
        } else if (v) {
          this.userService.updateUserCommunities(this.userService.userProfile.value.communities.filter(id => id != communityId));
          this.alert.success("Goodbye", `You have left the community ${v.name}`)

          await firstValueFrom(this._communities.pipe(filter(c => c.has(communityId))));
          this._communities.value.get(communityId)!.nbUsers--;
        }
      })
  }
  
  public addBookmark(communityId: string) {
    this.http.post<boolean>(`${environment.apiUrl}/infos/add-bookmark`, { communityId: communityId, userId: this.userService.userProfile.value.id })
      .pipe(filter(v => v as boolean), catchError((e) => {
        return of(new StandardError(e.error))
      })).subscribe(async v => {
        if (v instanceof StandardError) {
          this.alert.error("Couldn't add community", v.errorMsg)
        }
        else if (v) {
          this.userService.updateUserBookmarks(this.userService.userProfile.value.bookmarks.concat([communityId]));
          
          await firstValueFrom(this._communities.pipe(filter(c => c.has(communityId))));
          this._communities.value.get(communityId)!.nbBookmarks++;
        }
      })
  }

  public remBookmark(communityId: string) {
    this.http.post<boolean>(`${environment.apiUrl}/infos/rem-bookmark`, { communityId: communityId, userId: this.userService.userProfile.value.id })
      .pipe(filter(v => v as boolean), catchError((e) => {
        return of(new StandardError(e.error))
      })).subscribe(async v => {
        if (v) {
          this.userService.updateUserBookmarks(this.userService.userProfile.value.bookmarks.filter(id => id != communityId));

          await firstValueFrom(this._communities.pipe(filter(c => c.has(communityId))));
          this._communities.value.get(communityId)!.nbBookmarks--;
        }
      })
  }

  public deleteCommunity(communityId: string) {
    this._communities.next(new Map([...this._communities.value].filter(([key, value]) => key !== communityId)));
    this._myCommunities.next(this._myCommunities.value.filter(comId => communityId != comId));
  }

  public getCommunityInfo(communityId: string): Observable<CommunityInfos> {
    return this.http.get<CommunityInfos>(`${environment.apiUrl}/infos/communities/${communityId}`);
  }

  public createNewCommunity(infos: CommunityCreatePOST): Observable<string | null> {
    return this.http.post<string>(`${environment.apiUrl}/infos/create-community`, infos);
  }

  public getCommunitiesByTag(tag: string): Observable<CommunityInfos[]> {
    return this.http.get<CommunityInfos[]>(`${environment.apiUrl}/infos/communities/tag/${tag}`);
  }

  public getCommunitiesRanking(min: number, max: number)
  {
    return this.http.get<RankingInfos[]>(`${environment.apiUrl}/infos/get-communities-ranking/${min}:${max}`);
  }

  public getUsers(communityId: string) {
    return this.http.get<UserMinimalInfos[]>(`${environment.apiUrl}/infos/communities/${communityId}/users`);
  }

  public getBannedUsers(communityId: string) {
    return this.http.get<UserMinimalInfos[]>(`${environment.apiUrl}/infos/communities/${communityId}/bans`);
  }

  public getCommunityPolls(communityId: string) {
    return this.http.get<Poll[]>(`${environment.apiUrl}/infos/${communityId}/get-polls`).pipe(map(p => {
      let polls: Poll[] = p.map(poll => Poll.fromJSON(poll));
      if (polls.length == 0) return [];
      let pollsByCommunity = this._pollsByCommunity.value;
      pollsByCommunity.set(communityId, polls);
      this._pollsByCommunity.next(pollsByCommunity)

      return pollsByCommunity.get(communityId)!;
    }))
  }

  public createPoll(communityId: string, color: string, title: string, choices: string[]) {
    return this.http.post<Poll>(`${environment.apiUrl}/infos/add-poll`, { communityId: communityId, color: color, title: title, choices: choices })
      .pipe(first(), catchError((e) => {
        return of(new StandardError(e.error))
      }))
      .subscribe(value => {
        if (value instanceof StandardError) {
          this.alert.error("Couldn't create poll", value.errorMsg)
          return;
        }

        if (!value) {
          this.alert.error("Unknown error", "")
          return;
        }

        let pollsByCommunity = this._pollsByCommunity.value;
        pollsByCommunity.get(value.communityId)?.push(value)
        this._pollsByCommunity.next(pollsByCommunity)

        this.alert.success("Poll created !", "Your poll was succesfully created.")
      })
  }

  public deletePoll(communityId: string, pollId: string) {
    this.http.post<boolean>(`${environment.apiUrl}/infos/delete-poll`, { pollId: pollId, communityId: communityId })
      .pipe(first(), catchError((e) => {
        return of(new StandardError(e.error))
      }))
      .subscribe(value => {
        if (value instanceof StandardError) {
          this.alert.error("Couldn't delete poll", value.errorMsg)
          return;
        }

        if (!value) {
          this.alert.error("Unknown error", "")
          return;
        }

        let pollsByCommunity = this._pollsByCommunity.value;
        let poll = pollsByCommunity.get(communityId)?.find(p => p.id === pollId);
        if (poll) {
          pollsByCommunity.set(communityId, pollsByCommunity.get(communityId)?.filter(p => p.id !== pollId) ?? [])
          this._pollsByCommunity.next(pollsByCommunity)
        }

        this.alert.success("Poll deleted !", "Your poll was succesfully deleted.")
      })
  }

  public isModerator(community: CommunityInfos, userId: string = this.userService.userProfile.value.id): boolean {
    return community.moderators.includes(`0x${userId}`);
  }

  public isAdmin(community: CommunityInfos, userId: string = this.userService.userProfile.value.id): boolean {
    return community.admins.includes(`0x${userId}`);
  }

  public isCreator(community: CommunityInfos, userId: string = this.userService.userProfile.value.id): boolean {
    return community.creatorId === userId;
  }

  public promoteAdmin(communityId: string, userId: string): Observable<CommunityInfos | null> {
    return this.http.post<CommunityInfos>(`${environment.apiUrl}/infos/communities/${communityId}/promote-administrator`, { userId: userId })
      .pipe(filter(v => v !== null), tap(v => {
        if (v) {
          this.updateCommunity(v);
        }
      }));
  }

  public demoteAdmin(communityId: string, userId: string): Observable<CommunityInfos | null> {
    return this.http.post<CommunityInfos>(`${environment.apiUrl}/infos/communities/${communityId}/demote-administrator`, { userId: userId })
      .pipe(filter(v => v !== null), tap(v => {
        if (v) {
          this.updateCommunity(v);
        }
      }));
  }

  public promoteModerator(communityId: string, userId: string): Observable<CommunityInfos | null> {
    return this.http.post<CommunityInfos>(`${environment.apiUrl}/infos/communities/${communityId}/promote-moderator`, { userId: userId })
      .pipe(filter(v => v !== null), tap(v => {
        if (v) {
          this.updateCommunity(v);
        }
      }));
  }

  public demoteModerator(communityId: string, userId: string): Observable<CommunityInfos | null> {
    return this.http.post<CommunityInfos>(`${environment.apiUrl}/infos/communities/${communityId}/demote-moderator`, { userId: userId })
      .pipe(filter(v => v !== (null)), tap(v => {
        if (v) {
          this.updateCommunity(v);
        }
      }));
  }

  public banUser(communityId: string, userId: string): Observable<CommunityInfos | null> {
    return this.http.post<CommunityInfos>(`${environment.apiUrl}/infos/communities/${communityId}/ban`, { userId: userId })
      .pipe(filter(v => v !== (null)), tap(v => {
        if (v) {
          this.updateCommunity(v);
        }
      }));
  }

  public unbanUser(communityId: string, userId: string): Observable<CommunityInfos | null> {
    return this.http.post<CommunityInfos>(`${environment.apiUrl}/infos/communities/${communityId}/unban`, { userId: userId })
      .pipe(filter(v => v !== (null)), tap(v => {
        if (v) {
          this.updateCommunity(v);
        }
      }));
  }

  public editCommunity(communityId: string, edits: Partial<CommunityInfos>): Observable<CommunityInfos | null> {
    return this.http.post<CommunityInfos>(`${environment.apiUrl}/infos/communities/${communityId}/edit`, edits)
      .pipe(filter(v => v !== (null)), tap(v => {
        if (v) {
          this.updateCommunity(v);
        }
      }));
  }

  reportCommunity(communityId: string, reason: string) {
    this.http.post(`${environment.apiUrl}/infos/communities/report/${communityId}`, { reason: reason })
      .pipe(first()).subscribe((v: any) => {
        if (v.errorMsg)
        {
          this.alert.error("Oups...", v.errorMsg);
          return
        }

        this.alert.success("Report sent", "The community has been reported.")
      });
  }
}
