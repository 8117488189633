import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CommunityInfos, UserMinimalInfos } from '@overlie/types';
import { catchError, first, forkJoin, Observable, tap } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from 'app/src/app/data/user.service';
import { AlertService } from 'app/src/app/utils/alert.service';
import { CommunityService } from 'app/src/app/data/community.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent  implements OnInit {


  constructor(
    private router: Router,
    private userService: UserService,
    private communityService: CommunityService,
    private alert: AlertService) { }

  @Input() user: UserMinimalInfos | null = null;
  @Input() canEdit: boolean = false;
  @ViewChild('name') nameInput!: ElementRef<HTMLElement>;

  editNameMode: boolean = false;

  userCommunities?: Observable<CommunityInfos[]>
  communityRoles: Record<string, 'Member' | 'Moderator' | 'Administrator' | 'Creator'> = {};

  ngOnInit() {
    if (this.user) {
      this.userCommunities = forkJoin(this.user.communities.map(communityId => this.communityService.getCommunityInfo(communityId).pipe(first(), tap(c => {
        this.communityRoles[c.id] = this.communityService.isCreator(c, this.user!.id) ? 'Creator' :
          this.communityService.isAdmin(c, this.user!.id) ? 'Administrator' :
            this.communityService.isModerator(c, this.user!.id) ? 'Moderator' : 'Member';
      }))));
    }
  }

  
  enterEditNameMode() {
    if (!this.canEdit) return;
    this.editNameMode = true;
    setTimeout(() => this.nameInput.nativeElement.focus(), 0);
  }

  cancelEditName() {
    this.editNameMode = false;
    this.nameInput.nativeElement.textContent = this.user?.username || null;
  }

  async editName() {
    this.editNameMode = false;
    if (this.nameInput.nativeElement.textContent == this.user?.username ||
      this.nameInput.nativeElement.textContent == null ||
      this.nameInput.nativeElement.textContent == "" ||
      typeof this.nameInput.nativeElement.textContent == "number") {
      return;
    }
    if (this.user) {
      this.userService.editUserProfile({ username: this.nameInput.nativeElement.textContent }).pipe(first(), catchError(e => {
        this.alert.error("Oups...", e.error);
        this.nameInput.nativeElement.textContent = this.user?.username || null;
        return [];
      })).subscribe((u) => {
        if (!u) return;
        this.user = u;
        this.alert.success("Success", "Username updated");
      });
    }
  }

  seeCommunity(communityId: string) {
    this.router.navigate(['/explore/community', communityId]);
  }
}
