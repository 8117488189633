<ion-accordion-group>
  <ion-accordion>
    <ion-item slot="header" color="light">
      <ion-icon slot="start" id="ranking-icon" name="trophy"></ion-icon>
      <h5 id="ranking-title">Ranking</h5>
    </ion-item>
    <div id="ranking-layout" slot="content">
      <div id="header-layout">
        <p id="rank-header">Rank</p>
        <p id="name-header">Community</p>
        <p id="score-header">Tiles</p>
      </div>
      <div id="ranking-items">
        @for(ranking of ranking$ | async; track $index)
        {
          <app-communities-ranking-item [rankingInfos]="ranking" [rank]="currentRange$.value[0] + $index + 1"></app-communities-ranking-item>
        } @empty {
          <p id="empty">Nothing to display</p>
        }
      </div>
      <div id="nav">
        <span id="previous" class="nav" [ngStyle]="{ 'visibility': currentRange$.value[0] === 0 ? 'hidden': 'visible' }" (click)="previous()">
          <ion-icon name="chevron-back-outline"></ion-icon>
        </span>
        <span id="next" class="nav" [ngStyle]="{ 'visibility':  (canGoNext$ | async) === true ? 'visible': 'hidden' }" (click)="next()">
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </span>
      </div>
    </div>
  </ion-accordion>
</ion-accordion-group>