import { Component, Input, OnInit } from '@angular/core';
import { InteractionsService } from 'app/src/app/data/interactions.service';
import { MapService } from 'app/src/app/data/map.service';
import { WorldPoint } from 'types/build';

@Component({
  selector: 'app-go-button',
  templateUrl: './go-button.component.html',
  styleUrls: ['./go-button.component.scss'],
})
export class GoButtonComponent  implements OnInit {

  @Input() coordinates: String | undefined

  constructor(private mapService: MapService, 
              private interactionService: InteractionsService) { }

  ngOnInit() {}

  go()
  {
    let coords = this.coordinates?.split(',').map(x => +x)
    this.mapService.seeContent(coords as WorldPoint)
    this.interactionService.sendSignal(this.interactionService.goButtonClicked, true)
  }
}
