import { SocketIoConfig } from "ngx-socket-io";

const socketConf : SocketIoConfig = {
  url: 'https://dev.overlie.net',
  options: {
    path: '/api/v1/ws',
    withCredentials: true,
  }
};

const socketConfMobile : SocketIoConfig = {
  url: 'https://dev.overlie.net',
  options: {
    path: '/api/v1/ws',
    withCredentials: true,
    transports: ['websocket']
  }
};

const httpOptions = {
  withCredentials: true
};

export const environment = {
  version: require('../../package.json').version + '-staging',
  production: true,
  apiUrl: 'https://dev.overlie.net/api/v1',
  appUrl: 'https://dev.overlie.net',
  wsConfig: socketConf,
  wsConfigMobile: socketConfMobile,
  httpOptions: httpOptions,
  maxJoin: 3,
  maxTags: 5,
  starValueInMS: 6 * 60 * 1000,
  defaultLifetimeInMS: 24 * 3600000,
  tileNotifDelay: 3600000,
  tileBoostNbTiles: 30,
  tileBoostDelay: 60000,
  pollVoteDelay: 24 * 60 * 60 * 1000,
  oauthAppId: {
    google: {
      web: "1092942480161-tc9j37etsm42b1smka66auf7kuqpvvmt.apps.googleusercontent.com",
      android: "1092942480161-0q1sdqr25j78ejhfd8evnlfkhu5mecim.apps.googleusercontent.com",
      ios: "1092942480161-c9j8o7655mgjlc5asqrefkhq2kuaj73e.apps.googleusercontent.com"
    }
  }
};