<div class="layout">
  @if (data) {
    <h3>{{ data.additionalData.tutorial }}/3</h3>
    
    @switch (data.additionalData.tutorial) {
      @case (1) {
        <img class="illustation-image" src="/assets/onboarding/onboarding-map.png" alt="">
        <div class="layout-description">
          <p> <b>Explorez</b> la carte et <b>découvrez</b> de nouvelles communautés </p>
        </div>
      }
      @case (2) {
        <img class="illustation-image" src="/assets/onboarding/onboarding-view.png" alt="">
        <div class="layout-description">
          <p> <b>Selectionnez</b> une case appartenant a une communauté, puis cliquez sur <b>View</b> </p>
        </div>
      }
      @case (3) {
        <img class="illustation-image" src="/assets/onboarding/onboarding-tuto.png" alt="">
        <div class="layout-description">
          <p> Visualisez le contenu dans le coin en haut a gauche de la communauté <b>"Bienvenue sur Overlie !"</b> pour en savoir plus </p>
        </div>
      }
    }
  }
</div>
