<button id="button" [class]="type" [disabled]="disabled || loading">
    @if (loading) {
      <ion-spinner name="crescent"></ion-spinner>
    }
    @else {
      @if (icon) {
        <ion-icon id="icon" [class]="'icon-'+type" [name]="icon"></ion-icon>
      }
      @if (type !== "icon") {
        <div id="text" [class]="'text-'+type">
          {{text}}
        </div>
      }
    }
</button>
