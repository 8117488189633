import { Component, OnInit, ViewChild } from '@angular/core';
import { PaymentsService } from '../../data/payments.service';
import { combineLatest, map, Observable } from 'rxjs';
import { ProductInfos } from 'types/src/Store';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss'],
})
export class StoreComponent  implements OnInit {

  allItems?: Observable<ProductInfos[]>;
  //starsItems?: Observable<ProductInfos[]>;
  //communitiesItems?: Observable<ProductInfos[]>;
  donationItems?: Observable<ProductInfos[]>;
  //subscriptionItems?: Observable<ProductInfos[]>;

  @ViewChild("paymentForm") paymentForm?: any;

  constructor(private payments: PaymentsService) { }

  ngOnInit()
  {
    this.allItems = combineLatest([this.payments.getProducts(), this.payments.getPrices()])
      .pipe(map((results) => {
        let allProducts: ProductInfos[] = results[0].map(product => {
          let price = results[1].find(price => product.default_price == price.id)
          if (price)
          {
            return new ProductInfos(product, price);
          }
          return null;
        }).filter(pi => pi != null) as ProductInfos[];

        return allProducts;
      }));

    //this.starsItems = this.allItems?.pipe(map((allProducts) => allProducts.filter(it => it.product?.metadata['type'] == 'stars')))
//    this.communitiesItems = this.allItems?.pipe(map((allProducts) => allProducts.filter(it => it.product?.metadata['type'] == 'community')))
    this.donationItems = this.allItems?.pipe(map((allProducts) => allProducts.filter(it => it.product?.metadata['type'] == 'donation')))
//    this.subscriptionItems = this.allItems?.pipe(map((allProducts) => allProducts.filter(it => it.product?.metadata['type'] == 'sub')))
  }
}
