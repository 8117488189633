import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import gsap from 'gsap';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StarsAnimationService {

  addStarToProfile$: Subject<void> = new Subject<void>();
  constructor(private platform: Platform) { }

  /**
   * Animate the gain of a specific number of stars
   * @param amount number of stars to animate
   * @param initialPosition initial position of the stars (relative to the top left corner)
   */
  public async animateGainStars(amount: number, initialPosition: { x: number, y: number }): Promise<void> {
    return new Promise<void>((resolve) => {
      let defaultFinalX = -10;
      let defaultFinalY = 50;
      if (this.platform.is('mobile')) {
        const menuFab = document.getElementById('menu-fab')!;
        defaultFinalX = menuFab.getBoundingClientRect().left + menuFab.clientWidth / 2 + 10;
        defaultFinalY = menuFab.getBoundingClientRect().top + menuFab.clientHeight / 2 - 10; 
      }
      const fragment = document.createDocumentFragment();
      const starsImage = document.getElementById('stars-count-img')!;
      const finalX = starsImage.getBoundingClientRect().left || defaultFinalX;
      const finalY = starsImage.getBoundingClientRect().top || defaultFinalY;
      const initialRight = window.innerWidth - initialPosition.x;
      const initialTop = initialPosition.y;
      const relativeFinalX = initialRight + starsImage.clientWidth - window.innerWidth + finalX;
      const relativeFinalY = finalY - initialTop;
  
      const amountText = document.createElement('span');
      amountText.textContent = `+${amount}`;
      amountText.classList.add('animated-star-amount');
      amountText.style.top = `${initialTop - 20}px`;
      amountText.style.right = `${initialRight}px`;
      fragment.appendChild(amountText);
  
      gsap.to(amountText, {
        duration: 3,
        y: -30,
        opacity: 0,
        ease: 'power3.out',
        onComplete: () => {
          amountText.remove();
        }
      });
      let counter = 1;
      for (let i = 0; i < amount; i++) {
        const star = document.createElement('img');
        star.src = 'assets/icon/star.svg';
        star.classList.add('animated-star');
        star.style.top = `${initialTop}px`;
        star.style.right = `${initialRight}px`;
  
        fragment.appendChild(star);
  
        gsap.to(star, {
          motionPath:{
            path:[ 
              { x: (finalX - window.innerWidth)/2, y: gsap.utils.random(0, window.innerWidth/6, 1) },
              { x: relativeFinalX, y: relativeFinalY }
            ],
            curviness: 4
          },
          duration: gsap.utils.random(1.5, 2.5, 0.1),
          delay: gsap.utils.random(0, 0.5, 0.1),
          rotateX: gsap.utils.random(1, 3, 1) * 360,
          height: starsImage.clientHeight || 20,
          width: starsImage.clientWidth || 20,
          ease: 'power2.inOut',
          onComplete: () => {
            star.remove();
            this.addStarToProfile$.next();
            counter++;
            if (counter === amount) {
              resolve();
            }
          },
        });
  
      }
  
      document.body.appendChild(fragment);
    })

  }
}
