import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonModal, IonTextarea, Platform } from '@ionic/angular';
import { Option, SelectorComponent } from '../../components/buttons/selector/selector.component';
import { BehaviorSubject, Subscription, catchError, combineLatest, first, skip } from 'rxjs';
import { SponsorComponent } from '../../components/contents/sponsor/sponsor.component';
import { ContentsService } from '../../data/contents.service';
import { ContentBasePOST, IContentPOST, Media, MediaType, CommunityInfos, WorldPoint, AcceptedMimeTypes, GIFObject } from '@overlie/types';
import { AlertService } from '../../utils/alert.service';
import { GifModalComponent } from '../gif-modal/gif-modal.component';
import { FileService } from '../../utils/file.service';

@Component({
  selector: 'app-post-modal',
  templateUrl: './post-modal.component.html',
  styleUrls: ['./post-modal.component.scss']
})
export class PostModalComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(private contentService: ContentsService,
    private platform: Platform,
    private fileService: FileService,
    private alert: AlertService) { }

  @Input() community?: CommunityInfos;
  @Input() coordinates?: WorldPoint;

  selectorOptions: Option[] = [
    { label: 'Media', value: 'media', selected: true },
    { label: 'Text', value: 'text' },
    { label: 'GIF', value: 'gif' }
  ]

  sponsorValue?: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  media?: Media;
  gifObject?: BehaviorSubject<GIFObject> = new BehaviorSubject<GIFObject>(null)
  modalBreakpoints = [0, 1];
  postButtonLoading = false;
  @ViewChild('modal') modal?: IonModal;
  @ViewChild('layout') layout?: ElementRef<HTMLElement>;
  @ViewChild('selector') selector?: SelectorComponent;
  @ViewChild('sponsorModal') sponsorModal?: SponsorComponent;
  @ViewChild('gifModal') gifModal?: GifModalComponent;
  @ViewChild('description') description?: IonTextarea;
  @ViewChild('removeButton') removeButton?: any;

  subscription?: Subscription;
  selectorSubscription?: Subscription;

  ngOnInit() { }

  ngAfterViewInit() {
    this.modal?.didDismiss.subscribe(() => {
      this.selectorSubscription?.unsubscribe()
    })
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  openSponsorModal() {
    this.sponsorValue?.pipe(first()).subscribe(v => this.sponsorModal?.Open(true, true, "testContent", v));
    this.sponsorValue = this.sponsorModal?.investedValueConfirmed
  }

  async display() {
    await this.modal?.present();
    this.selectorSubscription = this.selector?.selected.pipe(skip(1)).subscribe(option => {
      if (option.value == "gif") {
        this.pickGif()
      }
    })
    this.gifObject = this.gifModal?.selectedGifObject
  }

  removeMedia() {
    this.media = undefined;
    this.gifObject?.next(null)
  }

  async uploadMedia() {
    const acceptedFiles = Object.keys(AcceptedMimeTypes).filter((key) => isNaN(Number(key)));
    let media = await this.fileService.uploadMedia(acceptedFiles);
    if (media) {
      this.media = media;
    }
  }

  async pickGif() {
    this.gifModal?.open();
  }

  post() {
    this.postButtonLoading = true;

    if (!this.selector || !this.sponsorValue) return

    this.subscription = combineLatest([this.selector.selected, this.sponsorValue]).pipe(first()).subscribe(([selected, sponsorValue]) => {
      if (!this.community || !this.coordinates) return;
      let baseContent: ContentBasePOST = {
        text: this.description?.value || '',
        communityId: this.community.id,
        coordinates: this.coordinates,
        sponsorisationAmount: sponsorValue
      };
      let content: IContentPOST;
      switch (selected.value) {
        case 'media':
          if (!this.media) {
            this.postButtonLoading = false;
            return this.alert.warning('Oups..', 'Please select a media to post');
          }
          content = { ...baseContent, contentType: this.media.mediaType === MediaType.Image ? "image" : "video", media: this.media.blob };
          break;
        case 'text':
          if (this.description?.value == '') {
            this.postButtonLoading = false;
            return this.alert.warning('Oups..', 'Please write something to post');
          }
          content = { ...baseContent, contentType: 'text' };
          break;
        case 'gif':
          if (!this.gifObject?.getValue() ) {
            this.postButtonLoading = false;
            return this.alert.warning('Oups..', 'Please select a media to post');
          }
          content = { ...baseContent, contentType: 'GIF', gifUrl: this.gifObject?.getValue()?.mediaFormats['gif'].url ?? "" };
          break;
        default:
          content = { ...baseContent, contentType: 'text' };
      }
      this.contentService.postContent(content).pipe(catchError((e) => {
        this.postButtonLoading = false;
        console.error(e);
        this.alert.error('Oups..', e.error);
        return [];
      })).subscribe(() => {
        this.alert.success('Success', 'Your content has been posted');
        this.postButtonLoading = false;
        this.media = undefined;
        this.selector?.select(this.selectorOptions[0])
        this.gifObject?.next(null);
        this.sponsorValue?.next(0)
        this.subscription?.unsubscribe();
        this.modal?.dismiss();
      });
    });
  }

  toggleRemoveButton() {
    if (!this.removeButton) return;
    this.removeButton.el.style.opacity = this.removeButton?.el.style.opacity == '1' ? '0' : '1';
  }
}
