<ion-accordion-group [multiple]="true" [value]="idsCommunity">
  @for (contentsObj of contentsByCommunity; track $index)
  {
    <ion-accordion value={{contentsObj.community.id}}>
      @if (contentsObj.contents.length > 0)
      {
        <ion-item class="accordion-header" slot="header">
          <div class="line-color" [ngStyle]="{ 'background-color': contentsObj.community.color }"></div>
          <ion-icon class="community-icon" name="people-outline"></ion-icon>
          <ion-label><h5>{{ contentsObj.community.name }} ({{contentsObj.contents.length}})</h5></ion-label>
        </ion-item>

        <div class="posts-container" slot="content">
          @for (content of contentsObj.contents; track content.id)
          {
            <div class="post-container">
              @switch (content.type)
              {
                @case("video")
                {
                  <app-video class="content"
                    preload="auto"
                    [autoPlay]="false"
                    [sourceUrl]="api_url + '/contentpayload?coordinates=' + content.id">
                  </app-video>
                }

                @case("image")
                {
                  <app-image class="content" [sourceUrl]="api_url + '/contentpayload?coordinates=' + content.id"/>
                }

                @case ("GIF")
                {
                  <app-image class="content" [sourceUrl]="content.gifUrl"/>
                }
              }

              <div class="description">
                <p> {{ content.text }} </p>
              </div>

              <div class="metadatas">
                <app-overbucks [amount]="content.starsDonated" 
                  [iconSize]="iconSize"
                  [fontSize]="fontSize"
                  [shadow]="true" 
                  [id]="content.id" 
                  [contentId]="content.contentId" 
                  [disabled]="true">
                </app-overbucks>
                
                <app-lifetimelabel [iconSize]="iconSize" 
                  [fontSize]="fontSize" 
                  [protectionEndDate]="content.protectionEndDate">
                </app-lifetimelabel>
              </div>

              <div class="boutons">
                <app-go-button class="view-btn"
                  [coordinates]="content.id">
                </app-go-button>
                
                <ion-button class="more-btn" fill="clear" (click)="presentPopover($event, content.id)">
                  <ion-icon name="ellipsis-vertical"></ion-icon>
                </ion-button>
              </div>
            </div>
          }
        </div>
      }
    </ion-accordion>
  }
</ion-accordion-group>

<app-sponsor #sponsorModal></app-sponsor>