import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'itemName'
})
export class ItemNamePipe implements PipeTransform {

  transform(value: string | undefined, ...args: (string | undefined)[]): unknown {
    if (!value || !args) return "Error"

    switch(args[0])
    {
      case "stars":
        return value.toLowerCase().replace(" stars", "")
      case "community":
        return value.toLowerCase().replace(" sponsored community", "")
      default:
        return "Donate";
    }
  }

}
