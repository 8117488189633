import { Pipe, PipeTransform } from '@angular/core';
import Stripe from 'stripe';
import { ProductInfos } from 'types/src/Store';

@Pipe({
  name: 'productSort'
})
export class ProductSortPipe implements PipeTransform {

  transform(value: ProductInfos[] | null, ...args: unknown[]): ProductInfos[] {
    if (!value){
      return [];
    }

    return value.sort((p1, p2) => (p1.price?.unit_amount ?? 0) - (p2.price?.unit_amount ?? 0))
  }

}
