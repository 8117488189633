<div id="profile-layout">
  <div id="profile-header">
    <div id="avatar">
      <!-- For the moment only community have custom avatar -->
      @if (community) {
      <app-avatar class="avatar" [image]="community.image" [type]="'community'"></app-avatar>
      <div id="profile-actions">
        @if (isMember$ | async) {
        <app-action-button tooltip="Leave community" icon="people-circle" type="icon" class="profile-action-button"
          (click)="leaveCommunity()"></app-action-button>
        } @else {
        <app-action-button tooltip="Join community" icon="people-circle-outline" type="icon"
          class="profile-action-button" (click)="joinCommunity()"></app-action-button>
        }
        @if (isBookmarked$ | async) {
        <app-action-button tooltip="Remove bookmark" icon="bookmark" type="icon" class="profile-action-button"
          (click)="unbookmarkCommunity()"></app-action-button>
        } @else {
        <app-action-button tooltip="Add bookmark" icon="bookmark-outline" type="icon" class="profile-action-button"
          (click)="bookmarkCommunity()"></app-action-button>
        }
        <app-action-button tooltip="Invite to community" icon="share-social-outline" type="icon"
          class="profile-action-button" (click)="inviteToCommunity()"></app-action-button>
      </div>
      }
      @if (canEdit) {
      <ion-button class="edit-image" (click)="editAvatar()">
        <ion-icon name="create-outline"></ion-icon>
      </ion-button>
      }

    </div>
    <div id="profile-name">
      <h1 #name [ngClass]="{ 'editable': canEdit, 'edit-text': editNameMode }"
        [attr.contenteditable]="canEdit && editNameMode" (click)="enterEditNameMode()">{{ community?.name }}</h1>
      @if (canEdit && editNameMode) {
      <div class="confirm-cancel">
        <ion-button class="action-button" fill="clear" (click)="cancelEditName()">
          <ion-icon name="close-outline"></ion-icon>
        </ion-button>
        <ion-button class="action-button" fill="clear" (click)="editName()">
          <ion-icon name="checkmark-outline"></ion-icon>
        </ion-button>
      </div>
      }
    </div>
    @if (community) {
    <div id="stats">
      <div id="members" class="stat">
        <img src="/assets/icon/community.svg" alt="">
        <span>{{community.nbUsers | stat}}</span>
      </div>
      <div id="bookmarks" class="stat">
        <ion-icon [name]="'bookmark-outline'"></ion-icon>
        <span>{{ community.nbBookmarks | stat}}</span>
      </div>
      <div id="tiles" class="stat">
        <div id="community-color" [ngStyle]="{ 'background': community.color }"></div>
        <span>{{ community.tileNumber | stat}}</span>
      </div>
    </div>
    }
  </div>
  @if (community) {
  <div id="profile-description">
    <p #description [ngClass]="{ 'editable': canEdit, 'edit-text': editDescriptionMode }"
      [attr.contenteditable]="canEdit && editDescriptionMode" (click)="enterEditDescriptionMode()">{{
      community.description }}</p>
    @if (canEdit && editDescriptionMode) {
    <div class="confirm-cancel">
      <ion-button class="action-button" fill="clear" (click)="cancelEditDescription()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
      <ion-button class="action-button" fill="clear" (click)="editDescription()">
        <ion-icon name="checkmark-outline"></ion-icon>
      </ion-button>
    </div>
    }
  </div>
  }
  @if (community) {
  <div id="tags" [ngClass]="{ 'editable': canEdit, 'edit-tags': editTagsMode }" (click)="enterEditTagsMode()">
    @for (tag of community.tags; track $index) {
    <app-tag [name]="tag" [canDelete]="editTagsMode" [selected]="(selectedTag | async)?.tag === tag"
      (deletedTag)="deleteTag(tag)" (click)="showRelatedCommunities(tag, $event)"></app-tag>
    }
    @if (canEdit && editTagsMode) {
    <div #tagSelection id="tag-selection">
      <ion-input #tagInput class="input" (keyup.enter)="addTag()" (keyup.space)="addTag()"
        (ionInput)="onTagInput($event)" [disabled]="tagsLimit" maxlength="15"
        placeholder="Add tags to the community (max 5)" type="text" [counter]="true"></ion-input>
      <button id="confirm-tag" (click)="addTag()" [disabled]="tagsLimit">
        <ion-icon color="primary" name="Add"></ion-icon>
      </button>
    </div>
    }
  </div>
  @if (canEdit && editTagsMode) {
  <div class="confirm-cancel">
    <ion-button class="action-button" fill="clear" (click)="cancelEditTags()">
      <ion-icon name="close-outline"></ion-icon>
    </ion-button>
    <ion-button class="action-button" fill="clear" (click)="editTags()">
      <ion-icon name="checkmark-outline"></ion-icon>
    </ion-button>
  </div>
  }
  }
</div>