import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'app/src/app/data/user.service';
import { environment } from 'app/src/environments/environment';
import { CommunityService } from '../../../data/community.service';
import { ContentsService } from 'app/src/app/data/contents.service';
import { IonModal } from '@ionic/angular/common';
import { PersonalContentMetadata, CommunityInfos } from '@overlie/types';
import { first, Subscription } from 'rxjs';

@Component({
  selector: 'app-user-posts-list',
  templateUrl: './user-posts-list.component.html',
  styleUrls: ['./user-posts-list.component.scss'],
})
export class UserPostsListComponent  implements OnInit, AfterViewInit, OnDestroy {
  username: string | undefined
  userId: string | undefined
  contentsForUser: PersonalContentMetadata[] | undefined
  communitiesForUser: CommunityInfos[] | undefined
  contentsByCommunity: {community: CommunityInfos, contents: PersonalContentMetadata[], visible: boolean}[] | undefined

  communitySubscription: Subscription | undefined;
  contentSponsoSubscription: Subscription | undefined;

  api_url: string = environment.apiUrl;
  appUrl = environment.appUrl;
  @ViewChild('listLayout') listLayout: ElementRef | undefined
  @ViewChild('sponsorModal') sponsorModal: IonModal | undefined

  constructor(private userService: UserService,
              private communityService: CommunityService,
              private contentsService: ContentsService) { }

  ngOnInit()
  {
    this.userService.getSelfProfile().pipe(first()).subscribe(profile =>
    {
      this.username = profile.username;
      this.userId = profile.id
      this.contentsService.getContentsForUser(this.userId as string).subscribe(contentsForUser =>
      {
        this.contentsForUser = contentsForUser
        this.communitySubscription = this.communityService.communities.subscribe(communities =>
          {
            this.communitiesForUser = Array.from(communities.values())
            const initialValue: any = this.communitiesForUser!.map(communityInfo => ({community: communityInfo, contents: [], visible: true}))
            this.contentsByCommunity = this.contentsForUser!.map(content =>
            {
              let matchingCommunity = this.communitiesForUser?.find(community => community.id == content.communityId)
              return {community: matchingCommunity, content: content}
            }).reduce((accumulator, currentValue) =>
             {
              let result = accumulator.find((obj: { community: CommunityInfos | undefined; }) =>
              {
                if (obj.community && currentValue.community)
                {
                  return obj.community.name == currentValue.community.name
                }

                return false
              })
              
              if (result)
              {
                result.contents.push(currentValue.content)
              }

              return accumulator
            }, initialValue)
          })
        })
      })

      this.contentSponsoSubscription = this.contentsService.contentSponsorised.subscribe(sponsoInfos =>
      {
        if (this.contentsByCommunity)
        {
          let matchingContent = this.contentsByCommunity.flatMap(obj => obj.contents).find(content => content.id == sponsoInfos.id);
          if (matchingContent)
          {
            (matchingContent.starsInvested as number) += sponsoInfos.amount;
            (matchingContent.nbSponsorisations as number)++;
          }
        }
      })
    }

  ngAfterViewInit(): void {
    this.listLayout?.nativeElement.addEventListener('touchstart', (event: any) => {
      event.stopPropagation()
    })
  }

  HideShow(communityName: String)
  {
    let object = this.contentsByCommunity!.find(obj => obj.community.name == communityName)!
    object.visible = !object.visible
  }

  ngOnDestroy(): void {
    this.communitySubscription?.unsubscribe();
    this.contentSponsoSubscription?.unsubscribe();
  }
}
