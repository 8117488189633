import { CapacitorConfig } from '@capacitor/cli';

export const config: CapacitorConfig = {
  appId: 'net.overlie.twa',
  appName: 'overlie',
  server: {
    androidScheme: 'https',
    iosScheme: 'https',
    hostname: "testhost"
  },
  loggingBehavior: 'production',
  plugins: {
    CapacitorCookies: {
      enabled: true,
    },
    CapacitorHttp: {
      enabled: true
    }
  },
  android: {
       buildOptions: {
          keystorePath: 'c:\Users\arnau\OneDrive\Bureau\PixelWar\AndroidRelease\signing.keystore',
          keystoreAlias: 'overlie-key',
       }
    }
  };

export default config;
