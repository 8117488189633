import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FitBoundsOptions, IControl, Map } from 'maplibre-gl';
import * as MaplibreGrid from 'maplibre-grid';
import { Subscription, filter, first, take, tap } from 'rxjs';
import * as turf from '@turf/turf';
import { CommunityService } from '../../data/community.service';
import { UserService } from '../../data/user.service';
import { MapData, MapService } from '../../data/map.service';
import { Badge, CommunityNamesFeatureCollection, ContentMetadatasFeatureCollection, FillFeature, FillFeatureCollection, HeightFeature, HeightFeatureCollection, SeaRouteFeature, SeaRouteFeatureCollection, SeaRouteObject, SponsorFeatureCollection, TileGeometry, WorldPoint } from '@overlie/types';
import { TileSelectAnimation } from '../../utils/TileSelectAnimation';
import { MapUtils } from '../../utils/MapUtils';
import { Colors } from '../../utils/Colors';
import { CommunityInfos, AuthenticatedUserToServerMessage, ServerToClientMessage, UserToServerMessage } from '@overlie/types';
import { InteractionsService } from '../../data/interactions.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PostModalComponent } from '../post-modal/post-modal.component';
import { CreateCommunityModalComponent } from '../create-community-modal/create-community-modal.component';
import { AlertService } from '../../utils/alert.service';
import { environment } from 'app/src/environments/environment';
import { Platform } from '@ionic/angular';
import { OverlieSocketService } from '../../data/overlie-socket.service';
import { LocalNotificationsService } from '../../data/local-notifications.service';
import { StorageService } from '../../data/storage.service';
import { PushNotificationsService } from '../../data/push-notifications.service';

let state = { lng: 2.347884, lat: 48.854913, zoom: 12 };

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss'],
})
export class MapViewComponent implements OnInit, AfterViewInit, OnDestroy {

  map: Map | undefined;
  @ViewChild('map') mapContainer?: ElementRef<HTMLElement>;
  @ViewChild('postModal') postModal?: PostModalComponent;
  @ViewChild('createCommunityModal') createCommunityModal?: CreateCommunityModalComponent
  focusedCommunityColor: string | undefined;

  constructor(
    private _: PushNotificationsService,
    private communityService: CommunityService,
    private userService: UserService,
    private mapService: MapService,
    private interactions: InteractionsService,
    private socket: OverlieSocketService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private alert: AlertService,
    private platform: Platform,
    private notifications: LocalNotificationsService,
    private localStorage: StorageService) { }

  selectedTileCommunity?: CommunityInfos;
  selectedTile?: TileGeometry;
  private _tileSelectAnim: TileSelectAnimation | undefined;
  private _mapFilledData: FillFeatureCollection = { type: "FeatureCollection", features: [] };
  private _mapHeightData: HeightFeatureCollection = { type: "FeatureCollection", features: [] };
  private _sponsoredData: SponsorFeatureCollection = { type: "FeatureCollection", features: [] };
  private _seaRoutes: SeaRouteFeatureCollection = { type: "FeatureCollection", features: [] };
  private _communityNamesData: CommunityNamesFeatureCollection = { type: "FeatureCollection", features: [] };
  private _contentMetadatas: ContentMetadatasFeatureCollection = { type: "FeatureCollection", features: [] };
  private _borders?: WorldPoint[][][];

  private _layersAdded: boolean = false;
  private _focusingClosedTile: boolean = true;
  private _focusingInProgress: boolean = false;
  private _selectedTagLink: boolean = false;
  private _isMobile: boolean = false;
  private _defaultAnimationDuration: number = 3000;

  wsSubscription?: Subscription;
  mapDataSubscription?: Subscription;
  forceResyncSub?: Subscription;
  addTileButtonClickedSubscription?: Subscription;
  createCommunityButtonClickedSubscription?: Subscription;
  createSponsoredCommunityButtonClickedSubscription?: Subscription;
  viewTileButtonClickedSubscription?: Subscription;
  selectedTagLinkSubscription?: Subscription;
  routerSubscription?: Subscription;
  selectedTileSubscription?: Subscription;
  focusedCoordinatesSubscription?: Subscription;
  focusedAreaSubscription?: Subscription;

  async ngOnInit() {
    //TODO get map data only the first time, and handle map data updates in the map service, even if the map view component is destroyed
    this.mapService.getMapData().pipe(first()).subscribe();

    // this.renderer.removeClass(this.transitionEffectLayer?.nativeElement, "is-visible");
    let saveState = await this.localStorage.getItem('map-state');
    state = saveState ? JSON.parse(saveState) : state;
  }

  async ngAfterViewInit(): Promise<void> {

    const initialState = await this.setInitialState();

    let gridConfig = {
      gridWidth: 0.1,
      gridHeight: 0.1,
      units: 'kilometers',
      paint: {
        'line-opacity': 0
      },
      minZoom: 12,
      maxZoom: 22
    };

    let response = await fetch("/assets/map-styles/Map_sobre.json")
    let json = await response.json()
    let jsonString = JSON.stringify(json);

    this._isMobile = this.platform.is("mobile");

    let hostname: string;
    if (this._isMobile && !this.platform.is("mobileweb")){
      hostname = this.platform.is("ios") ? "capacitor://localhost" : 'https://localhost';
    }
    else
    {
      hostname = environment.appUrl;
    }
    let validJson = jsonString.replace(/<HOST>/g, hostname)

    this.map = new Map({
      container: this.mapContainer!.nativeElement,
      style: JSON.parse(validJson),
      center: [initialState.lng, initialState.lat],
      zoom: initialState.zoom,
      attributionControl: false
    });

    let grid = new MaplibreGrid.Grid(gridConfig) as IControl;

    this.wsSubscription = this.socket.fromEvent<string>('message').subscribe(data => {
      this._handleMessages(data);
    });

    this._tileSelectAnim = new TileSelectAnimation(this.map);
    this.map.on('load', () => {
      this.map!.addControl(grid);

      this.mapDataSubscription = this.mapService.mapData?.pipe(filter(data => data !== undefined), take(1)).subscribe(data => this.initMap(data!));
      this.forceResyncSub = this.mapService.forceMapSync$?.subscribe(_ => {
        if (this.mapService.mapData.value) {
          this.initMap(this.mapService.mapData.value)
        }
      });

      this.selectedTileSubscription = this.mapService.selectedTile?.subscribe(tile => this.selectedTile = tile);
      this.userService.userProfile.pipe(first()).subscribe((profile) => {
        if (!profile.gotDailyReward) this.userService.handleDailyReward();
      });
      this.focusedCoordinatesSubscription = this.mapService.focusedCoordinates?.pipe(filter(coordinates => !!coordinates)).subscribe(coordinates => {
        this.focusOnWorldArea([coordinates!, [coordinates![0] + MapUtils.TILES_OFFSET_LONG, coordinates![1] + MapUtils.TILES_OFFSET_LAT]], { duration: this._defaultAnimationDuration, essential: true }, true);

        // this.renderer.addClass(this.transitionEffectLayer?.nativeElement, "is-visible");
        this.mapService.focusedCoordinates?.next(undefined);
      });
      this.focusedAreaSubscription = this.mapService.focusedArea?.pipe(filter(area => !!area)).subscribe(area => {
        this.focusOnWorldArea(area!, { duration: this._defaultAnimationDuration, essential: true }, false);
      });

      this.addTileButtonClickedSubscription = this.interactions.addTileButtonClicked$.pipe(filter(v => v), filter(() => this.selectedTile !== undefined)).subscribe(() => {
        if (this.mapService.isTileCanBePosted(this.selectedTile![0])) {
          this.postModal?.display();
        } else {
          let communityId = this.getAdjacentCommunity()
          if (communityId == "") return;
          this.send({ type: 'tileRequest', communityId: communityId, position: this.selectedTile![0] });
          this.notifications.scheduleTileNotification("Tile available !", "Go put a tile now !", environment.tileNotifDelay, false);
        }
      });

      this.createCommunityButtonClickedSubscription = this.interactions.createCommunityButtonClicked$.pipe(filter(v => v), filter(() => this.selectedTile !== undefined)).subscribe(() => {
        if (this.mapService.isTileEmpty(this.selectedTile![0])) {
          this.createCommunityModal?.display()
        }
      })

      this.createSponsoredCommunityButtonClickedSubscription = this.interactions.createSponsoredCommunityButtonClicked$.pipe(filter(v => v), tap(() => {
        if (this.selectedTile === undefined) {
          this.alert.warning("Select a tile first", "Please select a tile to create a sponsored community");
          return;
        }
      }), filter(() => this.selectedTile !== undefined)).subscribe(() => {
        if (this.mapService.isTileEmpty(this.selectedTile![0])) {
          this.createCommunityModal?.display(true)
        }
      })

      this.viewTileButtonClickedSubscription = this.interactions.viewTile$.pipe(filter(v => v), filter(() => this.selectedTile !== undefined)).subscribe(() => {
        let coords = this.selectedTile?.[0].join(",")

        // this.renderer.removeClass(this.transitionEffectLayer?.nativeElement, "is-visible");
        this.router.navigate(['/content'], { queryParams: { id: coords }, queryParamsHandling: "merge", replaceUrl: true });
      });
    })

    this.map?.on('moveend', () => {
      if (this._focusingInProgress) {
        this.mapService.endFocused.next(true)
        this.mapService.endFocused.next(false)
        this._focusingInProgress = false;
      }
    });

    // this.renderer.removeClass(this.transitionEffectLayer?.nativeElement, "is-visible");
  }
  async setInitialState() {
    let saveState = await this.localStorage.getItem('map-state'); // Ce await ne marche pas
    return saveState ? JSON.parse(saveState) : state;
  }

  async ngOnDestroy() {
    if (this.map) {
      state = {
        ...this.map.getCenter(),
        zoom: Math.min(this.map.getZoom(), MapUtils.MAX_SAVED_ZOOM)
      }

      await this.mapService.setMapState(this.map.getCenter().toArray(), Math.min(this.map.getZoom(), MapUtils.MAX_SAVED_ZOOM));
      this.map!.remove()
      this._tileSelectAnim?.cancel();
    }

    this.clearSubscriptions();
  }

  private clearSubscriptions() {
    this.wsSubscription?.unsubscribe();
    this.mapDataSubscription?.unsubscribe();
    this.forceResyncSub?.unsubscribe();
    this.addTileButtonClickedSubscription?.unsubscribe();
    this.createCommunityButtonClickedSubscription?.unsubscribe();
    this.createSponsoredCommunityButtonClickedSubscription?.unsubscribe();
    this.viewTileButtonClickedSubscription?.unsubscribe();
    this.selectedTagLinkSubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
    this.mapService.selectedTile.next(undefined);
  }

  public get getInteractions() {
    return this.interactions;
  }

  public send(message: AuthenticatedUserToServerMessage.Message | UserToServerMessage.Message) {
    this.socket.emit('message', JSON.stringify(message));
  }

  // TODO: move handling of messages outside of map-view, it doesn't make sense anymore
  private _handleMessages(data: string) {
    try {
      const message = JSON.parse(data) as ServerToClientMessage.Message;
      switch (message.type) {
        case 'ping':
          break;
        case 'tileAdd':
          this.mapService.addTileToMap(
            {
              position: message.position,
              communityId: message.communityId,
              postDate: message.postDate,
              protectionEndDate: message.protectionEndDate,
              starsDonated: message.starsDonated,
              starsInvested: message.starsInvested
            });
          this.addTile(message);
          break;
        case 'tileAddBatch':
          this.mapService.addTilesToMap(message.positions.map(p => {
            return {
              position: p,
              communityId: message.communityId,
              postDate: message.postDate,
              protectionEndDate: message.protectionEndDate,
              starsDonated: message.starsDonated,
              starsInvested: message.starsInvested
            }
          }));
          this.addTiles(message);
          break;
        case 'tileAddAcknowledge':
          this.userService.updateNextTileDate(message.nextTileDate);
          this.userService.updateTileBoostRemainingTiles();
          break;
        case 'newCommunity':
          this.communityService.addNewCommunity(message.data.community);
          break;
        case 'deletedCommunity':
          this.communityService.deleteCommunity(message.communityId);
          this.mapService.clearCommunityData(message.communityId);
          this.clearCommunityFeatures(message.communityId);
          break;
        case 'filledTilesData':
          this._mapFilledData = message.data;
          break;
        case 'badgeGranted':
          this.handleBadgeGranted(message.data)
          break;
        case 'newClientConnected':
          this.socket.disconnect(true);
          break;
        case 'borders':
          this.updateBorders(message.data);
          break;
        case 'starsGranted':
          this.handleStarsGranted(message.data)
          break;

        default:
          throw new Error();
      }
    } catch (error) {
      console.error(error);
    }
  }

  initMap(data: MapData) {
    if (!data) {
      return;
    }

    this.routerSubscription?.unsubscribe();
    this.selectedTagLinkSubscription?.unsubscribe();

    this.communityService.initCommunities(Array.from(data.communities.values()));
    this.parseMapData(Array.from(data.tiles.values()));
    this.updateBorders(data.borders);

    this.routerSubscription = this.activatedRoute.queryParams.pipe(first()).subscribe(params => {
      if (params['tag'] && params['origin']) {
        this.mapService.selectedTagLink.next({ originCommunityId: params['origin'], tag: params['tag'] });
        this.mapService.displayCommunityPopup(
          params['origin'],
          MapUtils.substractCenterOffset(this._communityNamesData.features.find(f => f.id === params["origin"])!.geometry.coordinates),
          this.map!,
          true);
      }
    });
    this.selectedTagLinkSubscription = this.mapService.selectedTagLink.subscribe((tagLink) => {
      this._selectedTagLink = tagLink !== undefined;
      this.mapService.clearTagLinks(this.map!);
      let communityNamesSource = this.map!.getSource('communities-names');
      let filledTilesSource = this.map!.getSource('filledTiles');
      if (tagLink == undefined) {
        //@ts-ignore
        communityNamesSource?.setData(this._communityNamesData);
        //@ts-ignore
        filledTilesSource?.setData(this._mapFilledData);

        this.map!.setPaintProperty('height', 'fill-extrusion-opacity', 1);
        return;
      }
      this.communityService.getCommunitiesByTag(tagLink.tag).subscribe(communities => {
        let mapData = this.mapService.mapData?.value;
        if (!mapData) return;

        if (communities.length == 1) {
          this.focusOnCommunity(communities[0].id, { duration: 1000, padding: this._isMobile ? 30 : 400, essential: true });
        } else {
          let bounds = MapUtils.getCommunitiesBounds(Array.from(mapData.tiles.values()), communities);
          this.focusOnWorldArea(bounds, { duration: 1000, padding: this._isMobile ? 50 : 200, essential: true }, false);
        }
        let otherCommunities = communities.filter(c => c.id !== tagLink?.originCommunityId);
        let originPoint = MapUtils.substractCenterOffset(this._communityNamesData.features.find(f => f.id === tagLink.originCommunityId)!.geometry.coordinates);
        for (let community of otherCommunities) {
          let point: WorldPoint = MapUtils.substractCenterOffset(this._communityNamesData.features.find(f => f.id === community.id)!.geometry.coordinates);
          this.mapService.traceTagLink(originPoint, point, this.map!);
        }
        let source = this.map!.getSource('tag-links');
        if (!source) return;
        this.mapService.animateLines(source);
        let namesFeatures = this._communityNamesData.features.filter(f => otherCommunities.map(c => c.id).concat(tagLink.originCommunityId).includes(f.id));
        let filledTilesFeatures = this._mapFilledData.features.filter(f => otherCommunities.map(c => c.id).concat(tagLink.originCommunityId).includes(f.properties.communityId));

        //@ts-ignore
        communityNamesSource.setData({ type: "FeatureCollection", features: namesFeatures });
        //@ts-ignore
        filledTilesSource.setData({ type: "FeatureCollection", features: filledTilesFeatures });
        //@ts-ignore
        bordersSource.setData({ type: "FeatureCollection", features: bordersFeatures });
        this.map!.setPaintProperty('height', 'fill-extrusion-opacity', 0.1);
      });
      return;
    });
  }

  public clearCommunityFeatures(communityId: string) {
    if (!this.map) return;
    let bordersSource = this.map.getSource('borders');
    let communityNamesSource = this.map.getSource('communities-names');
    let filledTilesSource = this.map.getSource('filledTiles');
    let heightSource = this.map.getSource('mapData');
    let sponsoredSource = this.map.getSource('sponsored');

    this._communityNamesData!.features = this._communityNamesData!.features.filter((c: { properties: { communityId: string; }; }) => c.properties.communityId !== communityId)
    this._mapFilledData!.features = this._mapFilledData!.features.filter((c: { properties: { communityId: string; }; }) => c.properties.communityId !== communityId)
    this._mapHeightData!.features = this._mapHeightData!.features.filter((c: { properties: { communityId: string; }; }) => c.properties.communityId !== communityId)
    this._sponsoredData!.features = this._sponsoredData!.features.filter((c: { properties: { communityId: string; }; }) => c.properties.communityId !== communityId)

    //@ts-ignore setData is not in the typings
    communityNamesSource.setData(this._communityNamesData)
    //@ts-ignore setData is not in the typings
    filledTilesSource.setData(this._mapFilledData)
    //@ts-ignore setData is not in the typings
    heightSource.setData(this._mapHeightData)
    //@ts-ignore setData is not in the typings
    sponsoredSource.setData(this._sponsoredData)
  }

  public focusOnWorldArea(bounds: [WorldPoint, WorldPoint], fitBoundsOptions: FitBoundsOptions = { duration: this._defaultAnimationDuration, padding: 250 }, enterViewMode: boolean) {
    this.map?.fitBounds(bounds, fitBoundsOptions);
    if (enterViewMode) this._focusingInProgress = true;
  }

  private focusOnCommunity(communityId: string, fitBoundsOptions?: FitBoundsOptions, enterViewMode?: boolean) {
    let communityFeatures = this._mapFilledData?.features.filter(f => f.properties.communityId === communityId);
    // @ts-ignore
    let allCoords = communityFeatures!.map(feature => feature.geometry.coordinates).reduce((coords1, coords2) => coords1.concat(coords2));
    let allLatsSorted = allCoords.map(coord => coord[1]).sort((a, b) => a - b);
    let allLongsSorted = allCoords.map(coord => coord[0]).sort((a, b) => a - b);
    this.focusOnWorldArea([[allLongsSorted[0]!, allLatsSorted[0]!], [allLongsSorted[allLongsSorted.length - 1]!, allLatsSorted[allLatsSorted.length - 1]!]], fitBoundsOptions, false);
  }

  public set3DView() {
    if (!this.map) {
      return;
    }
    this.map.setLayoutProperty('filledTiles', 'visibility', 'none')
    this.map.setLayoutProperty('height', 'visibility', 'visible')
    this.hideHeightLabels()
  }

  public hideHeightLabels() {
    if (!this.map) {
      return;
    }
    this.map.setLayoutProperty('height-labels', 'visibility', 'none')
  }

  public showHeightLabels() {
    if (!this.map) {
      return;
    }
    this.map.setLayoutProperty('height-labels', 'visibility', 'visible')
  }

  public async addTile(message: ServerToClientMessage.TileAddMessage): Promise<void> {
    if (!this.map || !this._mapHeightData || !this._mapFilledData) {
      return;
    }
    let heightFeature = this._mapHeightData.features.find((f: { id: any[]; }) => f.id[0] === message.position[0] && f.id[1] === message.position[1]);
    let fillFeature = this._mapFilledData.features.find((f: { id: any[]; }) => f.id[0] === message.position[0] && f.id[1] === message.position[1]);

    let filledTilesSource = this.map.getSource('filledTiles');
    let heightSource = this.map.getSource('mapData');
    let sponsoredSource = this.map.getSource('sponsored');
    let communityNamesSource = this.map.getSource('communities-names');
    let contentMetadataSource = this.map.getSource('content-meta');

    let communityId = message.communityId;
    let communitiesToUpdate: string[] = [];

    if (fillFeature === undefined) {
      let community: CommunityInfos | undefined = this.communityService.communities.value.get(communityId);
      if (community) {
        this._mapFilledData.features.push(MapUtils.buildNewFillFeature(message, community));
        communitiesToUpdate.push(communityId);
      }
    }
    else {
      if (communityId === 'none') {
        communitiesToUpdate.push(heightFeature!.properties.communityId);
        let indexH = this._mapHeightData!.features.indexOf(heightFeature!);
        let indexF = this._mapFilledData!.features.indexOf(fillFeature!);
        this._mapFilledData!.features.splice(indexF, 1);
        this._mapHeightData!.features.splice(indexH, 1);
      }
      else {
        let community: CommunityInfos = this.communityService.communities.value.get(communityId)!;
        fillFeature.properties.color = community.color;
        if (fillFeature.properties.communityId !== community.id) {
          communitiesToUpdate.push(fillFeature.properties.communityId);
        }
        fillFeature.properties.communityId = community.id;
      }
    }

    let community: CommunityInfos = this.communityService.communities.value.get(communityId)!;

    if (heightFeature === undefined && community) {
      this._mapHeightData!.features.push(MapUtils.buildNewHeightFeature(message, community));
    }
    else if (heightFeature && communityId !== 'none') {
      heightFeature.properties.height = message.postDate == -1 ? 0 : MapUtils.calculateTileHeight(message.protectionEndDate);
      heightFeature.properties.color = community.color;
      heightFeature.properties.communityId = community.id;
      if (message.starsInvested > 0) {
        let sponsorFeature = this._sponsoredData.features.find(f => f.id[0] == message.position[0] && f.id[1] == message?.position[1])
        if (sponsorFeature == undefined) {
          this._sponsoredData.features.push(MapUtils.buildNewSponsorFeature(heightFeature));
        }
        else {
          sponsorFeature.properties.height = heightFeature.properties.height + 0.2
        }
      }
    }

    let contentMetaFeature = this._contentMetadatas.features.find((f: { id: any[]; }) => f.id[0] === message.position[0] && f.id[1] === message.position[1]);
    if (message.postDate > -1) {
      if (!contentMetaFeature) {
        this._contentMetadatas.features.push(MapUtils.buildNewContentMetadataFeature(message));
      }
    }
    else {
      let indexC = this._contentMetadatas.features.indexOf(contentMetaFeature!);
      if (indexC != -1) {
        this._contentMetadatas.features.splice(indexC, 1);
      }
    }

    //@ts-ignore
    filledTilesSource.setData(this._mapFilledData)
    //@ts-ignore
    heightSource.setData(this._mapHeightData)
    //@ts-ignore
    sponsoredSource.setData(this._sponsoredData);
    //@ts-ignore
    communityNamesSource.setData(this._communityNamesData)
    //@ts-ignore
    contentMetadataSource.setData(this._contentMetadatas)

    if (this.selectedTile) {
      this.selectedTileCommunity = this.mapService.getTileCommunity(this.selectedTile[0]);
    }
  };

  private addTiles(message: ServerToClientMessage.TileAddBatchMessage) {
    if (!this.map || !this._mapHeightData || !this._mapFilledData) {
      return;
    }

    let heightFeatures = this._mapHeightData.features.filter((f: { id: any[]; }) => message.positions.find(p => f.id[0] == p[0] && f.id[1] == p[1]) != null);
    let fillFeatures = this._mapFilledData.features.filter((f: { id: any[]; }) => message.positions.find(p => f.id[0] == p[0] && f.id[1] == p[1]) != null);

    let filledTilesSource = this.map.getSource('filledTiles');
    let heightSource = this.map.getSource('mapData');
    let communityNamesSource = this.map.getSource('communities-names');

    let communityId = message.communityId;
    let communitiesToUpdate: string[] = [];

    if (communityId == 'none') {
      communitiesToUpdate.push(heightFeatures[0].properties.communityId);

      let i = 0;
      for (let heightFeature of heightFeatures) {
        let indexH = this._mapHeightData!.features.indexOf(heightFeature);
        let indexF = this._mapFilledData!.features.indexOf(fillFeatures[i]);

        this._mapFilledData!.features.splice(indexF, 1);
        this._mapHeightData!.features.splice(indexH, 1);

        i++;
      }
    }
    else {
      let community: CommunityInfos | undefined = this.communityService.communities.value.get(communityId);
      if (community) {
        for (let p of message.positions) {
          this._mapFilledData.features.push(MapUtils.buildNewFillFeature(
            {
              position: p,
              communityId: communityId,
              postDate: 0,
              protectionEndDate: 0,
              starsDonated: 0,
              starsInvested: 0
            }, community));

          this._mapHeightData.features.push(MapUtils.buildNewHeightFeature(
            {
              position: p,
              communityId: communityId,
              postDate: 0,
              protectionEndDate: 0,
              starsDonated: 0,
              starsInvested: 0
            }, community))
        }
        communitiesToUpdate.push(communityId);
      }
    }

    //@ts-ignore
    filledTilesSource.setData(this._mapFilledData)
    //@ts-ignore
    heightSource.setData(this._mapHeightData)
    //@ts-ignore
    communityNamesSource.setData(this._communityNamesData)
  }

  public parseMapData(data: { position: WorldPoint, postDate: number, protectionEndDate: number, starsDonated: number, starsInvested: number, communityId: string }[]) {
    this._mapHeightData!.features = []
    this._mapFilledData!.features = []
    this._sponsoredData!.features = []
    for (let i = 0; i < data.length; i++) {
      let f: HeightFeature = this._initHeightFeature(data[i]!.position, data[i]!.communityId, data[i]!.postDate, data[i]!.starsDonated, data[i]!.starsInvested, data[i]!.protectionEndDate)
      if (f.properties.color !== 'none') {
        this._mapHeightData!.features.push(f)
      }

      let community: CommunityInfos | undefined = this.communityService.communities.value.get(data[i]!.communityId);
      if (community) {
        let ff: FillFeature = MapUtils.buildNewFillFeature(data[i]!, community);
        if (ff.properties.color !== 'none') {
          this._mapFilledData!.features.push(ff)
        }
      }

      if (f.properties.sponsored) {
        this._sponsoredData.features.push(MapUtils.buildNewSponsorFeature(f));
      }

      if (data[i].postDate > -1) {
        this._contentMetadatas.features.push(MapUtils.buildNewContentMetadataFeature(data[i]))
      }
    }

    let heightSource = this.map!.getSource('mapData');
    if (heightSource) {
      let filledTilesSource = this.map!.getSource('filledTiles');
      // @ts-ignore
      filledTilesSource.setData(this._mapFilledData)
      // @ts-ignore
      heightSource.setData(this._mapHeightData)

      let sponsoredTilesSource = this.map!.getSource('sponsored');
      // @ts-ignore
      sponsoredTilesSource.setData(this._sponsoredData)
    }

    this.setupMapDataLayers();
  }

  private _initHeightFeature(position: WorldPoint, communityId: string, postDate: number, starsDonated: number, starsInvested: number, protectionEndDate: number): HeightFeature {
    let community: CommunityInfos | undefined = this.communityService.communities.value.get(communityId);
    let color = 'none'
    if (community) {
      color = community.color;
    }

    return {
      type: "Feature",
      properties: {
        height: postDate == -1 ? 0 : MapUtils.calculateTileHeight(protectionEndDate),
        base_height: 0,
        color: color,
        communityId: communityId,
        sponsored: starsInvested > 0
      },
      geometry: {
        coordinates: [[
          position,
          [position[0], position[1] + MapUtils.TILES_OFFSET_LAT],
          [position[0] + MapUtils.TILES_OFFSET_LONG, position[1] + MapUtils.TILES_OFFSET_LAT],
          [position[0] + MapUtils.TILES_OFFSET_LONG, position[1]],
          position
        ]],
        type: "Polygon"
      },
      id: position
    }
  }


  public async setupMapDataLayers(): Promise<void> {
    this.fillBorderData();
    this.map!.off(MaplibreGrid.GRID_CLICK_EVENT, this.onGridClick.bind(this));
    this.map!.on(MaplibreGrid.GRID_CLICK_EVENT, this.onGridClick.bind(this));
  }

  public async onGridClick(event: any) {
    if (!this._tileSelectAnim) {
      return;
    }
    if (this.map!.getLayer('selected')) {
      this.map!.removeLayer('selected');
    }
    if (this.map!.getSource('selected')) {
      this.map!.removeSource('selected');
    }

    this.selectedTile = [
      [event.bbox[0], event.bbox[1]],
      [event.bbox[0], event.bbox[3]],
      [event.bbox[2], event.bbox[3]],
      [event.bbox[2], event.bbox[1]],
      [event.bbox[0], event.bbox[1]]
    ]

    this.mapService.selectNewTile([event.bbox[0], event.bbox[1]])
    this._focusingClosedTile = this.map!.queryRenderedFeatures(this.map!.project([(event.bbox[0] + event.bbox[2]) / 2, (event.bbox[1] + event.bbox[3]) / 2]), { layers: ['mask'] }).length > 0;
    this.selectedTileCommunity = this.mapService.getTileCommunity([event.bbox[0], event.bbox[1]]);
    let lineColor: Colors.SELECT_LINE_COLOR;

    lineColor = this._focusingClosedTile ? Colors.SELECT_LINE_COLOR_NEGATIVE :
      (this.mapService.isTileCanBePosted([event.bbox[0], event.bbox[1]]) ? Colors.SELECT_LINE_COLOR_POSITIVE : Colors.SELECT_LINE_COLOR_NEUTRAL);

    this.map!.addSource('selected', {
      'type': 'geojson',
      'data': {
        'type': 'Feature',
        'properties': {},
        'geometry': {
          'type': 'LineString',
          'coordinates': this.selectedTile
        }
      }
    });
    this.map!.addLayer({
      'id': 'selected',
      'type': 'line',
      'source': 'selected',
      'layout': {
        'line-join': 'round',
        'line-cap': 'round'
      },
      'paint': {
        'line-color': lineColor,
        'line-width': this._tileSelectAnim.lineWidth
      }
    })
    this._tileSelectAnim.cancel();
    this._tileSelectAnim.animate();
  };

  private getAdjacentCommunity(): string {
    let selection = this.selectedTile!.map((p: any) => p)
    selection.pop()
    let bottomLeftCorner = MapUtils.makeCoordLegal(selection[0]!)
    let topRightCorner = selection[2]!

    let communityFeatures = this._mapFilledData!.features.filter((feat: { properties: { communityId: string; }; }) => {
      return this.communityService.myCommunities?.value.includes(feat.properties.communityId)
    })
    let adjcentTile = communityFeatures.find((f: FillFeature) => {
      let bLeftCorner = f.geometry.coordinates[0]
      return bLeftCorner[0] === bottomLeftCorner[0] && bLeftCorner[1] === bottomLeftCorner[1]
        || (bLeftCorner[0] === bottomLeftCorner[0]) && (Math.abs(bLeftCorner[1] - bottomLeftCorner[1]) === MapUtils.TILES_OFFSET_LAT)
        || (bLeftCorner[1] === bottomLeftCorner[1]) && (Math.abs(bLeftCorner[0] - bottomLeftCorner[0]) === MapUtils.TILES_OFFSET_LONG)
    })

    if (!(adjcentTile?.properties?.communityId)) return "";

    return adjcentTile.properties.communityId;
  }

  public fillBorderData(): void {
    this._communityNamesData!.features = []
    for (let [communityId, community] of this.communityService.communities.value.entries()) {
      let communityFeatures: FillFeature[] = this._mapFilledData!.features.filter((feature: { properties: { communityId: string; }; }) => feature.properties.communityId == communityId)!
      let cleanFeaturesCoords: WorldPoint[][] = communityFeatures.map(feature => {
        let cleanedCoords: WorldPoint[] = feature.geometry.coordinates.map((c: WorldPoint) => c)
        cleanedCoords.pop()
        return cleanedCoords
      })
      let allCommunityCoordinates = cleanFeaturesCoords.flat()
      if (allCommunityCoordinates.length < 1) continue;

      let allLatsSorted = allCommunityCoordinates.map(coord => coord[1]).sort((a, b) => a - b)!;
      let allLongsSorted = allCommunityCoordinates.map(coord => coord[0]).sort((a, b) => a - b)!;
      let territoryMeanPoint: WorldPoint = [(allLongsSorted[allLongsSorted.length - 1]! + allLongsSorted[0]!) / 2, (allLatsSorted[allLatsSorted.length - 1]! + allLatsSorted[0]!) / 2];

      if (!community.color) {
        console.log(`fillBorderData: Community ${communityId} has no color`);
        continue;
      }
      this._communityNamesData!.features.push({
        id: communityId,
        type: "Feature",
        properties: {
          communityId: communityId,
          communityName: community.name,
          color: community.color,
          haloColor: Colors.isColorDark(community.color) ? 'white' : 'black',
          tileNumber: community.tileNumber
        },
        geometry: {
          type: "Point",
          coordinates: territoryMeanPoint
        }
      })
    }
  }

  public async initSeaRoutes(data: SeaRouteObject[]) {
    let alreadyRegistedredCoords: [number, number][] = []
    for (let seaRouteObject of data) {
      if (alreadyRegistedredCoords.find(p => (p[0] === seaRouteObject.tile1[0] && p[1] === seaRouteObject.tile1[1])
        || (p[0] === seaRouteObject.tile2[0] && p[1] === seaRouteObject.tile2[1]))) continue;

      let color: string;
      if (seaRouteObject.communityId1 !== seaRouteObject.communityId2
        || seaRouteObject.communityId1 === 'none') {
        color = 'white';
      }
      else {
        let community = this.communityService.communities.value.get(seaRouteObject.communityId1);
        color = community?.color || 'white';
      }

      this._seaRoutes!.features.push({
        type: "Feature",
        id: seaRouteObject.tile1.toString(),
        properties:
        {
          communityId1: seaRouteObject.communityId1,
          communityId2: seaRouteObject.communityId2,
          color: color!,
        },
        geometry: {
          type: 'LineString',
          coordinates: [
            [seaRouteObject.tile1[0] + MapUtils.APPROXIMATE_CENTER_OFFSET, seaRouteObject.tile1[1] + MapUtils.APPROXIMATE_CENTER_OFFSET],
            [seaRouteObject.tile2[0] + MapUtils.APPROXIMATE_CENTER_OFFSET, seaRouteObject.tile2[1] + MapUtils.APPROXIMATE_CENTER_OFFSET],
          ]
        }
      })

      alreadyRegistedredCoords.push(seaRouteObject.tile1)
    }
  }

  public async addBorderLayer() {
    if (!this._borders) {
      console.log('AddBorderLayer: borders are undefined');
      return;
    }
    if (this.map!.getLayer('mask')) {
      this.map!.removeLayer('mask')
      this.map!.removeSource('mask')
    }

    let maskFeatures: turf.Feature<turf.MultiPolygon | turf.Polygon>[] = []
    let baseFeature: turf.Feature<turf.MultiPolygon | turf.Polygon> | null = { type: "Feature", geometry: { type: "Polygon", coordinates: [[[-180, -90], [-180, 90], [180, 90], [180, -90], [-180, -90]]] }, properties: {} }
    for (let polygon of this._borders) {
      if (baseFeature) {
        let res = turf.difference(baseFeature, { type: "Feature", geometry: { type: "Polygon", coordinates: polygon }, properties: {} })
        if (res) {
          baseFeature = res
        }
      }
    }

    maskFeatures.push(baseFeature);

    this.map!.addLayer({
      'id': 'mask',
      'type': 'fill',
      'source': {
        'type': "geojson",
        'data': {
          type: 'FeatureCollection',
          // @ts-ignore
          features: maskFeatures
        }
      },
      'layout': {},
      'paint': {
        'fill-opacity': 0.5,
        'fill-color': 'black'
      }
    })
  }


  public async updateBorders(borders: WorldPoint[][][]) {
    this._borders = borders
    this.addBorderLayer()
    if (!this._layersAdded) {
      this.addMapLayers()
    }
    else {

    }
    if (this.map!.getLayer('mask')) {
      this.map!.moveLayer('mask', 'filledTiles');
    }
  }

  public async addMapLayers() {
    this.map!.addSource('filledTiles', {
      'type': 'geojson',
      //@ts-ignore
      'data': this._mapFilledData
    });
    this.map!.addLayer({
      'id': 'filledTiles',
      'type': 'fill',
      'source': 'filledTiles',
      'layout': {},
      'paint': {
        'fill-color': ['get', 'color'],
        'fill-opacity': 0.5
      }
    })

    this.map!.addSource('mapData', {
      'type': 'geojson',
      //@ts-ignore
      'data': this._mapHeightData
    });

    this.map!.addLayer({
      'id': 'height',
      'type': 'fill-extrusion',
      'source': 'mapData',
      'layout': {},
      'paint': {
        'fill-extrusion-color': ['get', 'color'],
        'fill-extrusion-height': ['get', 'height'],
        'fill-extrusion-base': ['get', 'base_height'],
        'fill-extrusion-opacity': 1,
      }
    });

    this.map!.addSource('sponsored', {
      'type': 'geojson',
      //@ts-ignore
      'data': this._sponsoredData
    });

    this.map!.addLayer({
      'id': 'sponsored',
      'type': 'fill-extrusion',
      'source': 'sponsored',
      'layout': {},
      'paint': {
        'fill-extrusion-color': '#FFD700',
        'fill-extrusion-height': ['+', ['get', 'height'], 1],
        'fill-extrusion-base': ['get', 'height'],
        'fill-extrusion-opacity': 1,
      }
    });

    this.map!.addLayer({
      'id': 'height-labels',
      'type': 'symbol',
      'source': 'mapData',
      'paint': {
        'text-color': 'black',
        'text-halo-color': 'white',
        'text-halo-width': 2
      },
      'layout': {
        'visibility': 'none',
        'text-field': ["number-format", ['get', 'height'], {}]
      }
    })

    this.map!.addSource('communities-names', {
      'type': 'geojson',
      'data': this._communityNamesData
    })
    this.map!.addLayer({
      'id': 'communities-names',
      'type': 'symbol',
      'source': 'communities-names',
      'paint': {
        'text-color': ['get', 'color'],
        'text-halo-color': ['get', 'haloColor'],
        'text-halo-width': 1.5
      },
      'layout': {
        'text-size': 21,
        'visibility': 'visible',
        'text-field': ['get', 'communityName'],
        'symbol-sort-key': ["number", ["-", 0, ['get', 'tileNumber']]]
      }
    })

    this.map!.addSource("content-meta", {
      'type': 'geojson',
      //@ts-ignore
      'data': this._contentMetadatas
    });
    this.map!.addLayer({
      'id': 'content-meta',
      'type': 'symbol',
      'source': 'content-meta',
      'minzoom': 12,
      'layout': {
        'icon-allow-overlap': true,
        'icon-size': ['interpolate', ["exponential", 2], ['zoom'], 12, 0.01, 22, 5],
        'icon-image': "ContentIcon:content_icon",
      }
    })


    this.map!.on('click', (e) => {
      this.mapService.clearCommunityPopups();
      if (this._selectedTagLink) {
        this.mapService.clearTagLinks(this.map!);
        this.mapService.selectedTagLink.next(undefined);
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: { tag: null, origin: null },
          queryParamsHandling: 'merge'
        });
      }
    });

    this.map!.on('click', 'communities-names', (e) => {
      let communityId = e.features![0]!.properties!['communityId'];
      this.mapService.displayCommunityPopup(communityId, e.lngLat, this.map!, true);
      this.focusedCommunityColor = this.communityService.communities.value.get(communityId)?.color || "#000";
    });

    this._layersAdded = true;
  }

  private handleBadgeGranted(badge: Badge) {
    this.alert.announceBadge(badge);
  }

  private handleStarsGranted(amount: number) {
    let profile = this.userService.userProfile.value;
    profile.stars += amount;
    this.userService.userProfile.next(profile);
  }
}