import { Component, Input, OnInit } from '@angular/core';
import { Clipboard } from '@capacitor/clipboard';
import { ModalController } from '@ionic/angular';
import * as QRCode from 'qrcode';

@Component({
  selector: 'app-community-invitation-modal',
  templateUrl: './community-invitation-modal.component.html',
  styleUrls: ['./community-invitation-modal.component.scss'],
})
export class CommunityInvitationModalComponent  implements OnInit {

  @Input({required: true}) url?: string;
  qrCode?: string;

  copiedUrl = false;

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
    this.generateQRCode();
  }

  async generateQRCode() {
    if (!this.url) return;
    this.qrCode = await QRCode.toDataURL(this.url).catch((err) => {
      console.error(err);
    }) ?? undefined;
  }

  copyUrlTextToClipboard() {
    if (!this.url) return;
    Clipboard.write({ string: this.url });
    this.copiedUrl = true;
    setTimeout(() => this.copiedUrl = false, 3000);
  }

  copyQRCodeToClipboard() {
    if (!this.qrCode) return;
    Clipboard.write({ image: this.qrCode });
  }

  dismiss() {
    return this.modalCtrl.dismiss(null, 'confirm');
  }
}
