import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SponsorComponent } from '../sponsor/sponsor.component';
import { ContentsService } from 'app/src/app/data/contents.service';
import { UserService } from 'app/src/app/data/user.service';

@Component({
  selector: 'app-overbucks',
  templateUrl: './overbucks.component.html',
  styleUrls: ['./overbucks.component.scss'],
})
export class OverbucksComponent  implements OnInit {

  @Input() amount: number | undefined;
  @Input() iconSize: number | undefined;
  @Input() fontSize: number | undefined;
  @Input() id: string | undefined;
  @Input() contentId: string | undefined;

  @ViewChild('sponsorModal') sponsorModal: SponsorComponent | undefined;

  uniqueId: string | undefined;

  constructor(private ContentsService: ContentsService,
    private userService: UserService
  ) { }

  ngOnInit() {}

  simpleLike()
  {
    if (this.id && this.userService.userProfile.value.stars >= 1)
    {
      this.ContentsService.sponsorContent(this.id as string, 1, false)
    }
  }
}
