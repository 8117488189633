import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { AlertService } from './alert.service';
import { ActionSheet, ActionSheetButtonStyle } from '@capacitor/action-sheet';
import { Platform } from '@ionic/angular';
import { AcceptedMimeTypes, Media, MediaType } from 'types/build';
import { FilePicker } from '@capawesome/capacitor-file-picker';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private platform: Platform, private alert: AlertService) { }

  /**
   * Upload a media from the device. On desktop, it will directly open the file picker. On mobile, it will open an action sheet to choose between gallery, file system or camera.
   * @param acceptedMimeTypes The accepted mime types for the media. For example: 'image/*', 'video/*', 'image/png', 'image/jpeg'
   * @returns 
   */
  public async uploadMedia(acceptedMimeTypes: string[]): Promise<Media | void> {
    try {
      if (this.platform.is('desktop')) {
        return await this.pickMedia(acceptedMimeTypes);
      }
      const result = await ActionSheet.showActions({
        title: 'Upload media',
        message: 'Select an option to perform',
        options: [
          {
            title: 'Select from gallery',
          },
          {
            title: 'Select from file system',
          },
          {
            title: 'Take a photo',
          },
          {
            title: 'Cancel',
            style: ActionSheetButtonStyle.Destructive,
          },
        ],
      });
      let media;
      switch (result.index) {
        case 0:
          media = await this.pickFromGallery(acceptedMimeTypes);
          break;
        case 1:
          media = await this.pickMedia(acceptedMimeTypes);
          break;
        case 2:
          media = await this.takePhoto();
          break;
      }

      return media;
    } catch (error) {
      console.warn(error);
    }

  }
  
  private async pickMedia(acceptedMimeTypes: string[]): Promise<Media | void> {
    try {
      if (this.platform.is('desktop')) {
        return new Promise((resolve, reject) => {
          const input = document.createElement('input');
          input.type = 'file';
          input.accept = acceptedMimeTypes.join(',');
          input.onchange = () => {
            if (!input.files || !input.files[0]) {
              reject(new Error('No file selected'));
              return;
            }
            if ((acceptedMimeTypes.includes('image/*') && input.files[0].type.startsWith('image/')) 
              || (acceptedMimeTypes.includes('video/*') && input.files[0].type.startsWith('video/')) 
              || acceptedMimeTypes.includes(input.files[0].type)) {
              resolve(this.generateMediaObject(input.files[0]));
            } else {
              this.alert.warning('Oups..', 'Invalid file type');
              reject(new Error('Invalid file type'));
            }
          };
          input.click();
        });
      } else {
        const result = await FilePicker.pickFiles({
          // limit: 1,
          types: acceptedMimeTypes,
          readData: true
        });
        let blob;
        if (result.files[0].data) {
          blob = this.dataURItoBlob(result.files[0].data, result.files[0].mimeType)
          if (!blob) return;
        }
        else return;

        if (acceptedMimeTypes.includes(result.files[0].mimeType)) {
          return this.generateMediaObject(blob);
        } else {
          this.alert.warning('Oups..', 'Invalid file type');
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }

  private async pickFromGallery(acceptedMimeTypes: string[]): Promise<Media | void> {
    try {
      const result = await FilePicker.pickMedia({
        limit: 1,
        readData: true
      });
      let blob;
      if (result.files[0].data) {
        blob = this.dataURItoBlob(result.files[0].data, result.files[0].mimeType)
        if (!blob) return;
      }
      else return;

      if (acceptedMimeTypes.includes(result.files[0].mimeType)) {
        return this.generateMediaObject(blob);
      } else {
        this.alert.warning('Oups..', 'Invalid file type');
      }

    } catch (error) {
      console.warn(error);
    }
  }

  private async takePhoto(): Promise<Media | void> {
    try {
      const image = await Camera.getPhoto({
        //saveToGallery: true,
        source: CameraSource.Camera,
        quality: 90,
        allowEditing: true,
        webUseInput: false,
        resultType: CameraResultType.Base64
      });

      let blob = this.base64toBlob(image.base64String, `image/${image.format}`);
      return {
        src: URL.createObjectURL(blob),
        blob: blob,
        type: `image/${image.format}`,
        mediaType: MediaType.Image
      };
    } catch (error) {
      console.log(error);
    }
  }

  dataURItoBlob(dataURI: any, contentType: string = '') {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: contentType });
    return blob;
  }

  private generateMediaObject(blob: Blob): Media {
    return {
      src: URL.createObjectURL(blob),
      blob: blob,
      type: blob.type,
      mediaType: blob.type.startsWith('image') ? MediaType.Image : MediaType.Video
    };
  }

  base64toBlob(b64Data: any, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  blobToBase64(blob: Blob): Promise<string | undefined> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }
}
