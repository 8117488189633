<div id="actions-container">
    <app-community-popup></app-community-popup>

    <div id="main-actions-container">
        <app-action-button id="overlie-add-button" class="interactable main-action"
            [ngStyle]="{ 'display' : canPut || canPost  ? 'block' : 'none' }" 
            (click)="putTile()"
            [text]="canPost ? 'Post' : (tileAvailable$ | async) || ((tileBoostRemainingTiles$ | async)! > 0) ? 'Overlie' : (timer$ | async | timer)"
            [disabled]="!canPost && (tileAvailable$ | async) === false && ((tileBoostRemainingTiles$ | async)! === 0)"
            type="primary" 
            shadow="true">
            <ion-icon name="add"></ion-icon>
        </app-action-button>

        <app-action-button id="overlie-create-button" class="interactable main-action"
            [ngStyle]="{ 'display' : canCreateCom ? 'block' : 'none' }" (click)="createCommunity()" text="Create community"
            type="secondary" shadow="true">
            <p>Create community</p>
        </app-action-button>

        <app-action-button id="overlie-view-button" class="interactable main-action"
            [ngStyle]="{ 'display' : canView ? 'block' : 'none' }" (click)="viewTile()" text="View" type="secondary"
            shadow="true">
            <ion-icon name="view"></ion-icon>
        </app-action-button>
    </div>
</div>

<div id="header-actions" class="fade">
    <app-action-button [ngStyle]="{ 'display': (waitingForSponsoredCommunity | async) ? 'block' : 'none'}"
        id="overlie-create-sponsored-button" class="interactable main-action" (click)="createSponsoredCommunity()"
        type="primary" text="Create your sponsored community now !">
    </app-action-button>
    <ion-fab-button fill="clear" class="interactable" (click)="openLeaderboard()">
        <ion-icon name="trophy" color="primary"></ion-icon>
      </ion-fab-button>
    <div id="tile-boost">
        <ion-fab-button fill="clear" class="interactable" (click)="activateTileBoost()"
            [attr.disabled]="(tileBoostRemainingTiles$ | async)! === 0 && (tileBoostTimer$ | async)! > 0">
            <ion-icon name="flame" [ngStyle]="{
                'color': ((tileBoostAvailable$ | async) && (tileBoostRemainingTiles$ | async)! === 0) ? 'var(--overlie-flame-color)' : 'var(--ion-color-primary)'
            }"></ion-icon>
            @if ((tileBoostAvailable$ | async) && (tileBoostRemainingTiles$ | async)! === 0) {
                <div id="aura"></div>
            }
        </ion-fab-button>
        @if ((tileBoostRemainingTiles$ | async)! > 0) {
        <div class="badge red">{{ (tileBoostRemainingTiles$ | async) }}</div>
        }
        @else if ((tileBoostTimer$ | async)! > 0) {
        <div class="badge">{{ (tileBoostTimer$ | async | timer:'hms') }}</div>
        }
    </div>
</div>

<ion-menu #leaderboardMenu contentId="main-content" swipeGesture="false" side="end" type="overlay">
    <app-communities-ranking [menuRef]="leaderboardMenu" id="leaderboard" class="interactable"></app-communities-ranking>
</ion-menu>