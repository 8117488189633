import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(value: number | undefined, ...args: unknown[]): string {
    if (!value) return '';
    if (value >= 86400000) {
      return Math.floor(value / 86400000) + 'd';
    }
    if (value >= 3600000) {
      return Math.floor(value / 3600000) + 'h';
    }
    if (value >= 60000) {
      return Math.floor(value / 60000) + 'm';
    }
    return value + 's';
  }

}
