<div id="layout">
  <div id="header">
    @if (type === 'stars')
    {
      <img src="/assets/icon/star.svg" alt="">
    }
    @else if (type === 'donation')
    {
      <ion-icon id="donation" name="heart"></ion-icon>
    }
    @else
    {
      <svg width="2em" height="2em" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3" y="3" width="42" height="42" rx="9" stroke="#DCB253" stroke-width="5"/>
      </svg>
    }
    <p>{{ name | itemName:type }}</p>
  </div>
  <form [action]="apiURL + '/payment/create-checkout-session'" method="POST">
    <input type="hidden" name="product" [value]="productId" />
    <ion-button [ngClass]="'buy ' + type" type="submit" id="checkout-button">
      <p>{{ price | price }}</p>
    </ion-button>
  </form>
</div>

