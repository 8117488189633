import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ContentsService } from 'app/src/app/data/contents.service';
import { ContentMetadata, ContentType } from '@overlie/types';
import { first } from 'rxjs';
import { Router } from '@angular/router';
import { gsap } from 'gsap';
import { Platform } from '@ionic/angular';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-content-thumbnail',
  templateUrl: './content-thumbnail.component.html',
  styleUrls: ['./content-thumbnail.component.scss'],
})
export class ContentThumbnailComponent {

  scale: number = 1;
  translate: string = "";
  rotation: number = 0;
  content?: ContentMetadata;
  id?: string;
  thumbnailUrl: string = ""
  transform: string = "";
  display: string = "none";
  mapContainer: HTMLElement | null = null;
  textAnim: gsap.core.Tween | null = null;
  isVisible: boolean = false;

  @ViewChild('video') video?: ElementRef<HTMLVideoElement>;
  @ViewChild('text') text?: ElementRef<HTMLVideoElement>;

  contentRetrieved: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private contentService: ContentsService,
    private router: Router,
    private platform: Platform
  ) { }

  setScale(value: number) {
    this.scale = value;
    this.transform = `translate(${this.translate}) scale(${this.scale})`
    this.setTransform();
  }

  setTranslate(x: number, y: number) {
    const totalWidth = window.innerWidth;
    const totalHeight = window.innerHeight;

    if (x < -168 || y < -168 || x > totalWidth || y > totalHeight) {
      this.display = "none";
      this.handleNotFocused();
    } else {
      this.display = "block";
    }
    this.translate = `${x}px, ${y}px`;
    this.setTransform();
  }

  setRotation(value: number) {
    this.rotation = value;
    this.setTransform();
  }

  private setTransform() {
    this.transform = `translate(${this.translate}) rotate(${this.rotation}deg)  scale(${this.scale})`
  }

  setContentId(value: string) {
    this.id = value;
    this.contentService.getContent(value).pipe(first()).subscribe(v => {
      this.content = v;
      this.contentRetrieved?.emit();
      if (this.content.type == ContentType.Video || this.content.type == ContentType.Image) {
        this.thumbnailUrl = this.contentService.getContentThumbnailPayloadURL(this.content.id!)
      }
    })
  }

  private dispatchMouseEvent(event: Event, type: string) {
    event.stopPropagation();
    event.preventDefault();
    this.mapContainer?.dispatchEvent(new MouseEvent(type, event));
    return false;
  }

  private dispatchWheelEvent(event: WheelEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.mapContainer?.dispatchEvent(new WheelEvent(event.type, event));
    return false;
  }

  onWheel(event: WheelEvent) {
    return this.dispatchWheelEvent(event);
  }

  onMouseDown(event: Event) {
    return this.dispatchMouseEvent(event, event.type);
  }

  onMouseUp(event: Event) {
    return this.dispatchMouseEvent(event, event.type);
  }

  onMouseMove(event: Event) {
    return this.dispatchMouseEvent(event, event.type);
  }

  onTouchStart(event: TouchEvent) {

  }

  onTouchMove(event: TouchEvent) {

  }

  onTouchEnd(event: TouchEvent) {

  }

  onMouseEnter() {
    this.handleFocused();
  }

  onMouseLeave() {
    this.handleNotFocused();
  }

  getContentPayloadURL(): string {
    if (!this.content) return '';
    return this.contentService.getContentPayloadURL(this.content.id!);
  }

  async handleFocused() {
    if (this.content?.type === ContentType.Video && this.video) {
      this.video.nativeElement.muted = true;
      await this.video.nativeElement.play();
      this.video.nativeElement.style.display = "block";
    }
    else if (this.content?.type == ContentType.Text && this.text && !this.textAnim) {
      const textElement = this.text.nativeElement;
      textElement.style.textOverflow = 'clip';
      textElement.style.overflow = 'visible';

      const textWidth = textElement.scrollWidth * this.scale;
      const containerWidth = textElement.parentElement?.scrollWidth || 0 ;
      const duration = textWidth / 50;
      this.textAnim = gsap.fromTo(
        textElement,
        {
          x: containerWidth  ,
        },
        {
          x: -textWidth,
          duration: duration,
          repeat: -1,
          ease: 'linear',
        }
      );
    }
  }

  handleNotFocused() {
    if (this.content?.type === ContentType.Video && this.video) {
      this.video.nativeElement.pause();
      this.video.nativeElement.currentTime = 0;
      this.video.nativeElement.style.display = "none";
    }
    else if (this.content?.type == ContentType.Text && this.text) {
      if (this.textAnim) {
        this.textAnim.kill();
        this.textAnim = null;
        this.text.nativeElement.style.textOverflow = 'ellipsis';
        this.text.nativeElement.style.overflow = 'hidden';
        this.text.nativeElement.style.transform = 'translateX(0)';
      }
    }
  }
}
