import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { InteractionsService } from 'app/src/app/data/interactions.service';
import { MapService } from 'app/src/app/data/map.service';
import { TileData, WorldPoint } from '@overlie/types';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CommunityService } from 'app/src/app/data/community.service';
import { MapUtils } from 'app/src/app/utils/MapUtils';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-community-list-entry',
  templateUrl: './community-list-entry.component.html',
  styleUrls: ['./community-list-entry.component.scss'],
})
export class CommunityListEntryComponent  implements OnInit, AfterViewInit {

  @Input() communityId?: string;
  @Input() communityName?: string;
  @Input() communityImage?: string;
  @Input() nbNewContent?: number
  @Input() isBookmark?: boolean

  communityLeft?: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  private tiles!: TileData[];
  private selectedTile?: WorldPoint;

  constructor(private mapService: MapService,
    private interactions: InteractionsService,
    private communityService: CommunityService,
    private platform: Platform,
    private router: Router,) { }

  ngOnInit()
  {
  }

  ngAfterViewInit() {
      this.mapService.mapData!.subscribe((md) => 
      {
        if (md) {
          this.tiles = Array.from(md.tiles.values()).filter(tile => tile.communityId == this.communityId);
        }
      })
  }

  SeeContent(e: MouseEvent)
  {
    e.stopPropagation();
    let i = Math.floor(Math.random() * this.tiles.length);
    this.selectedTile = this.tiles[i].position;
    this.mapService.seeContent(this.selectedTile)
    this.interactions.sendSignal(this.interactions.goButtonClicked, true)
  }

  SeeCommunity()
  {
    this.router.navigate([`/explore/community/${this.communityId}`], {replaceUrl: this.platform.is('mobile')});
  }

  Leave(e: MouseEvent)
  {
    e.stopPropagation();
    if (!this.communityId) return;

    if (this.isBookmark)
    {
      this.communityService.remBookmark(this.communityId)
    }
    else
    {
      this.communityService.leaveCommunity(this.communityId);
    }
  }
}
